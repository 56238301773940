import menuItems from "../constants/_nav";
import {isAdmin, isSuperUser} from "./PermissionManager";
import {resources} from "../services/ability";

let setMenu = (user, venue, ability) => {
	let items = [menuItems.dashboard];

	if(process.env.REACT_APP_ENVIRONMENT==='capodichino') items.push(menuItems.capodichino_maps)

	if (venue) {
		ability.can('read', venue.id + "", resources.MAPS) && items.push(menuItems.maps);

		if (ability.can('read', venue.id + "", resources.MAPS) || ability.can('read', venue.id + "", resources.GATEWAYS)) {
			items.push(menuItems.content); //controlla mappe gateways e assets
		}

		if (ability.can('read', venue.id + "", resources.GATEWAYS)) items.push(menuItems.monitoring); //controlla contact tracing e asset tracing

		if (isAdmin(user)) {
			items.push(menuItems.users);
		}
		if(venue.id===1541) {
			items.push(menuItems.industrial);
			items.push(menuItems.offices);
			items.push(menuItems.healthcare);
			items.push(menuItems.museums);
		}
	}
	if (isSuperUser(user)) {
		items.push(menuItems.settings);
		items.push(menuItems.admin);
	}
	return items;
};

export {setMenu};
