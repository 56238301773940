import React, {useState} from 'react';
import {getErrorMessage, post} from "../../services/Client";
import {makeStyles} from "@mui/styles";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Avatar from "@mui/material/Avatar";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {LockOutlined} from "@mui/icons-material";
import TextInput from "../forms/TextInput";
import Grid from "@mui/material/Grid";

function Copyright() {
	return (
        <Typography variant="body2" color="textSecondary" align="center">
			{'Copyright © '}
			<Link color="inherit" href="https://nextome.net/" underline="hover">
				Nextome
			</Link>{' '}
			{new Date().getFullYear()}
			{'.'}
		</Typography>
    );
}

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.primary.main,
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
}));

export default function ChangePasswordPage({onSuccess, email}) {
	const [password, setPassword] = useState("");
	const [oldPassword, setOldPassword] = useState("");
	const [checkPassword, setCheckPassword] = useState("");
	const [error, setError] = useState({});
	const [loading, setLoading] = useState(false);
	const classes = useStyles();

	function submit() {
		let oldPassErr, passErr, checkErr;
		if (!oldPassword) oldPassErr = "Please insert the old password";
		if (!password) passErr = "Please insert a new password";
		if (!checkPassword) checkErr = "Please repeat password";

		if (oldPassErr || passErr || checkErr) {
			setError({
				oldPassword: oldPassErr,
				password: passErr,
				checkPassword: checkErr
			});
			return;
		}

		if (password !== checkPassword) {
			setError({
				checkPassword: "Passwords mismatch"
			});
			return;
		}
		setLoading(true);
		post(`developers/password/challenge`, {
			body: { email, oldPassword, newPassword:password}
		})
			.then(onSuccess)
			.catch(e => setError({server: getErrorMessage(e)}))
			.finally(() => setLoading(false));
	}

	const onKeyPress = (ev) => {
		if (ev.key === 'Enter') {
			submit()
			ev.preventDefault();
		}
	}

	return (
		<Container component="main" maxWidth="xs">
			<CssBaseline/>
			<div className={classes.paper}>
				<Avatar className={classes.avatar}>
					<LockOutlined/>
				</Avatar>
				<Typography component="h2" variant="h5">
					Inserisci una nuova password. Tu sarai l'unico a conoscere quella nuova
				</Typography>
				<Grid container spacing={4} style={{paddingTop: "2rem"}}>
					<Grid item xs={12}>
						<TextInput
							label="Old Password"
							required color="dark"
							value={oldPassword}
							type="password"
							showPasswordIcon
							onTextChange={value => {
								setOldPassword(value);
								setError({});
							}}
							error={!!error.oldPassword}
							onKeyPress={onKeyPress}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextInput
							label="Password"
							required color="dark"
							value={password}
							type="password"
							showPasswordIcon
							onTextChange={value => {
								setPassword(value);
								setError({});
							}}
							error={!!error.password}
							onKeyPress={onKeyPress}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextInput
							label="Repeat Password"
							required color="dark"
							value={checkPassword}
							type="password"
							showPasswordIcon
							onTextChange={value => {
								setCheckPassword(value);
								setError({});
							}}
							error={!!error.checkPassword}
							onKeyPress={onKeyPress}
						/>
					</Grid>
				</Grid>
				<Button
					fullWidth
					variant="contained"
					color="primary"
					className={classes.submit}
					onClick={submit}
					disabled={loading}
				>
					Imposta ed Entra
				</Button>
			</div>
			<Box mt={4}>
				<div>{error.server}</div>
			</Box>
			<Box mt={8}>
				<Copyright/>
			</Box>
		</Container>
	);
}
