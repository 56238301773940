import React from "react";
import HoverableButton from "../layout/HoverableButton";
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
	addButton: {
		color: theme.palette.primary.dark,
		marginRight: "2rem"
	},
	addText: {
		fontWeight: 700,
	}
}));

export default function AddNewButton({onClick}) {
	const classes = useStyles();
	return <HoverableButton
		item={{
			icon: require("../../images/icons/pop-up-aggiungi.svg").default,
			iconHover: require("../../images/icons/pop-up-aggiungi-over.svg").default,
			name: "ADD NEW"
		}}
		onClick={onClick}
		classes={{root: classes.addButton, text: classes.addText}}
	/>;
}
