import BaseModal from "./BaseModal";
import React, {useEffect, useRef, useState} from "react";
import IndoorMap from "../mapview/IndoorMap";
import Grid from "@mui/material/Grid";
import {deleteElem, download, getErrorMessage, LINKS, MAPS, NODES, post} from "../../services/Client";
import {useQueryClient} from "react-query";
import {useSnackbar} from "notistack";
import clsx from "clsx";
import {makeStyles} from "@mui/styles";
import PathPopup from "../Popup/PathPopup";

const useStyles = makeStyles({
	root: {
		display: "flex",
		flexWrap: "wrap",
		justifyContent: "center",
		color: "#0a698a",
		fontWeight: "700",
		padding: "1rem",
		"& div": {
			paddingLeft: "1rem",
			paddingRight: "1rem",
			"&:hover": {
				color: "#5bc5f2",
				cursor: "pointer"
			}
		},
	},
	selected: {
		color: "#5bc5f2"
	},
});

export default function PathsModal({open, onClose, originNode, maps, paths}) {

	const classes = useStyles();
	const stage = useRef();
	let [selectedMap, setSelectedMap] = useState();
	let [mapImage, setMapImage] = useState();
	let [destination, setDestination] = useState();
	let [popup, setPopup] = useState({});
	const {enqueueSnackbar} = useSnackbar();
	let queryClient = useQueryClient();

	if (maps) maps = maps.filter(m => m.floor !== -1 && originNode && m.id !== originNode.map);
	if (maps.length > 0 && (!selectedMap || !maps.find(m => m.id === selectedMap))) setSelectedMap(maps[0].id);

	function saveLink() {
		setPopup({});

		let filteredPaths = Object.assign({}, paths);
		filteredPaths.nodes = paths.nodes && filteredPaths.nodes.filter(n => n.map === selectedMap);


		if (destination && !paths.links.find(l => (l.origin === originNode.id && filteredPaths.nodes.find(n => n.id === l.destination)) || (filteredPaths.nodes.find(n => n.id === l.origin) && l.destination === originNode.id))) {
			let newLink = {
				origin: originNode.id,
				destination: destination.id,
				bidirectional: true
			};
			enqueueSnackbar("Saving...", {variant: "info"});
			post(LINKS, {body: newLink})
				.then(() => {
					enqueueSnackbar("Saved", {variant: "success"});
					onClose();
				})
				.catch(e => enqueueSnackbar(getErrorMessage(e), {variant: "error"}))
				.finally(() => queryClient.invalidateQueries("paths"));
		} else {
			if (!destination) enqueueSnackbar("Please, select a node to link!", {variant: "error"});
			else enqueueSnackbar("To create a link, first delete the one already present!", {variant: "error"});
		}
	}

	function nodeClick(node) {
		let link = paths.links.find(l => (l.origin === originNode.id && l.destination === node.id) || (l.origin === node.id && l.destination === originNode.id));

		if (link) {
			setDestination(null);
			if (popup.id && popup.id === node.id) {
				setPopup({});
				return;
			}
			let newPopup = <PathPopup
				title="Link"
				elem={link}
				popupPosition={"left"}
				onDelete={() => {
					deleteElem(LINKS, {elem: link.id})
						.then(() => {
							enqueueSnackbar("Deleted!", {variant: "success"});
							setDestination(node);
							setPopup({});
						})
						.catch(e => enqueueSnackbar(getErrorMessage(e), {variant: "error"}))
						.finally(() => queryClient.invalidateQueries("paths"));
				}}/>;
			setPopup({container: newPopup, id: node.id});
		} else {
			setPopup({});
			if (destination) setDestination(null);
			else setDestination(node);
		}
	}

	useEffect(() => {
		setMapImage("downloading");
		selectedMap && download(`${MAPS}/${selectedMap}/image`)
			.then(response => setMapImage(URL.createObjectURL(response.data)))
			.catch(e => console.log(e));
	}, [selectedMap]);

	return (
		<BaseModal open={open} onClose={() => {
			setPopup({});
			onClose();
		}} onSave={saveLink}>
			<div style={{marginBottom: "0.3rem"}}>
				<div className={classes.root}>|
					{
						maps.map(({id, name}) =>
							<>
								<div onClick={() => {
									setSelectedMap(id);
									setDestination(null);
									setPopup({});
								}} className={clsx({[classes.selected]: selectedMap === id})}>{name}</div>
								|</>
						)
					}
				</div>
			</div>
			<Grid item xs={12} overflow="hidden">
				{popup.container}
				<IndoorMap map={selectedMap}
				           refElem={stage}
				           mapImage={mapImage}
				           containerWidth={550}
				           scale={1}
				           paths={paths}
				           onBackgroundClick={() => {
					           setPopup({});
					           setDestination(null);
				           }}
				           onLinkClick={() => {
					           setPopup({});
					           setDestination(null);
				           }}
				           onNodeClick={nodeClick}
				           originNodeForLinkBetweenFloors={originNode && originNode.id}
				/>
			</Grid>
		</BaseModal>
	);
}
