import React,{useContext,useState} from "react";
import {useSelector} from "react-redux";
import EditableLabel from "../../components/EditableLabel";
import {useSnackbar} from "notistack";
import Can,{AbilityContext} from "../../services/Can";
import NTMXGrid,{timestampFormatter} from "../../components/NTMXGrid";
import Paper from "@mui/material/Paper";
import {useGetAssets} from "../../services/ContentManager";
import {ASSETS,deleteElem,getErrorMessage,put} from "../../services/Client";
import {useQueryClient} from "react-query";
import AssetBeaconModal from "../../components/modals/AssetBeaconModal";
import {Grid} from "@mui/material";
import AddNewButton from "../../components/buttons/AddNewButton";
import {resources} from "../../services/ability";
import ConfirmIconButton from "../../components/buttons/ConfirmIconButton";

export const ASSET_BEACON_TYPES = [
	{
		value: 1,
		label: "iBeacon"
	},
	{
		value: 2,
		label: "Angle of Arrival"
	},
]

export default function AssetBeacons() {

	let venue = useSelector(store => store.session.venue);
	let [isCreatingAsset, setIsCreatingAsset] = useState(false);
	const {enqueueSnackbar} = useSnackbar();
	const ability = useContext(AbilityContext);
	let { assets } = useGetAssets();
	let queryClient = useQueryClient();

	function saveEdit(name, newValue, bid) {
		enqueueSnackbar("Saving...", {variant: "info"});
		put(ASSETS,{body: {[name]: newValue}, elem: bid})
			.then(() => enqueueSnackbar("Saved", {variant: "success"}))
			.catch(e => enqueueSnackbar(getErrorMessage(e), {variant: "error"}))
			.finally(() => queryClient.invalidateQueries(['assets', {venue: venue.id}]))
	}

	function deleteAsset(bid) {
		enqueueSnackbar("Deleting...", {variant: "info"});
		deleteElem(ASSETS,{elem: bid})
			.then(() => enqueueSnackbar("Deleted", {variant: "success"}))
			.catch(e => enqueueSnackbar(getErrorMessage(e), {variant: "error"}))
			.finally(() => queryClient.invalidateQueries(['assets', {venue: venue.id}]))
	}

	return (
		<Paper>
			<NTMXGrid
				columns={[
					{
						headerName: "Label", field: 'label', flex: 1,
						renderCell: params =>
							ability.can("write", venue.id + "","assets") ?
								<EditableLabel
									label={params.value}
									onChange={newValue => saveEdit("label", newValue, params.row.id)}
								/>
								: params.value
					},
					{headerName: "UUID", field: 'uuid', flex: 1},
					{headerName: "Major", field: 'major', flex: 1},
					{headerName: "Minor", field: 'minor', flex: 1},
					{
						headerName: "Type", field: "type", flex: 1,
						renderCell: ({value}) => {
							if(!value || value === 0) return "Unknown";
							return ASSET_BEACON_TYPES.find(a => a.value === value).label
						}

					},
					{
						headerName: "Time", field: 'checkin', flex: 1,
						...timestampFormatter
					},
					{
						field: 'Actions',
						type: 'actions',
						width: 80,
						getActions: (params) => [
							<ConfirmIconButton
								item={{
									icon : require("../../images/icons/pop-up-delete.svg").default,
									iconHover : require("../../images/icons/pop-up-delete-over.svg").default
								}}
								title="Delete?"
								text="Are you sure you want to delete this Asset?"
								onConfirm={() => deleteAsset(params.id)}
							/>
						],
					}
				]}
				rows={assets}
				rightButton={
					<Grid container justifyContent={"flex-end"}>
						<Can I="write" a={venue.id + ""} field={resources.ASSET}>
							<AddNewButton onClick={() => setIsCreatingAsset(true)}/>
						</Can>
					</Grid>
				}
			/>
			<AssetBeaconModal open={!!isCreatingAsset} onClose={() => setIsCreatingAsset(false)}/>
		</Paper>
	);

}
