import { FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import FormControl from "@mui/material/FormControl";
import React from "react";

const useStyles = makeStyles(theme => ({
    radioLabel: {
        color: theme.palette.primary.light,
        justifyContent: "space-around",
        '&.Mui-focused': {
            color: theme.palette.primary.light,
        }
    }
}));

export default function RadioButtons({value, label, onChange, options, row}){

    const classes = useStyles();

    return <FormControl component="fieldset">

            <FormLabel component="legend"  className={classes.radioLabel}>
                {label}
            </FormLabel>
            <RadioGroup value={value} row={row} className={classes.radioLabel}
                        onChange={(event) => onChange(event.target.value)}>

                {options.map(option => <FormControlLabel value={option.value}
                                      control={<Radio sx={{color: "primary.light", '&.Mui-checked': {color: "secondary.main"}}}/>}
                                      label={option.label}/>
                )}

        </RadioGroup>
    </FormControl>
}