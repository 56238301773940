import React,{useEffect,useState} from "react";
import BaseModal from "./BaseModal";
import Grid from "@mui/material/Grid";
import TextInput from "../forms/TextInput";
import LanguageInput from "../forms/LanguageInput";
import {useSnackbar} from "notistack";
import {getErrorMessage,POI,post,put} from "../../services/Client";
import {useQueryClient} from "react-query";

export default function PoiModal({open, onClose, defaultValue}){
    let [poi, setPoi] = useState(defaultValue ? Object.assign({}, defaultValue, {descriptions: []}) : {});
    let [language, setLanguage] = useState('en');
    let [consideringDescription, setConsideringDescription] = useState({})
    let [errors, setErrors] = useState({});
    const {enqueueSnackbar} = useSnackbar();
    let queryClient = useQueryClient();

    useEffect(() => {
        setPoi(defaultValue ? Object.assign({}, defaultValue, !defaultValue.descriptions && {descriptions: []}) : {});
        setConsideringDescription(defaultValue && defaultValue.descriptions ? defaultValue.descriptions.find(d => d.name !== "") : {});
        setLanguage(defaultValue && defaultValue.descriptions ? defaultValue.descriptions.find(d => d.name !== "").language : 'en');
    }, [defaultValue]);

    function onChange(name, value) {
        let newConsideringDescription = Object.assign({}, consideringDescription, {[name]: value});
        setConsideringDescription(newConsideringDescription);
        setErrors({});
    }

    function submit() {
        setErrors({});
        updatePoi();
        if(!poi.descriptions[0]) {
            setErrors({"name" : true});
            return;
        }
        if(poi.id){
            put(POI, {body: poi, elem: poi.id})
                .then(() => {
                    enqueueSnackbar("Saved",{variant : "success"});
                    onClose();
                })
                .catch(e => enqueueSnackbar(getErrorMessage(e), {variant: "error"}))
                .finally(() => queryClient.invalidateQueries("poi"));
        }else{
            post(POI, {body: poi})
                .then(() => {
                    enqueueSnackbar("Saved",{variant : "success"});
                    onClose();
                })
                .catch(e => enqueueSnackbar(getErrorMessage(e), {variant: "error"}))
                .finally(() => queryClient.invalidateQueries("poi"));
        }
    }

    function updatePoi (){
        let index = poi.descriptions.findIndex(elem => elem.language === language);
        let newPoi = Object.assign({}, poi);
        if(index !== -1) {
            poi.descriptions[index] = {
                ...(consideringDescription.id && {id : consideringDescription.id}),
                name: consideringDescription.name,
                ...(consideringDescription.description && consideringDescription.description !== "" && {description : consideringDescription.description}),
                language
            }
        }else{
            if(consideringDescription.name !== undefined)                   //caso in cui un utente cambia lingua senza inserire nessun input
            poi.descriptions.push({
                name: consideringDescription.name,
                ...(consideringDescription.description && consideringDescription.description !== "" && {description : consideringDescription.description}),
                language
            });
        }
        setPoi(newPoi);
    }

    function selectLanguage(lan){
        updatePoi();
        setLanguage(lan);
        let newDescriptionIndex = poi.descriptions.findIndex(elem => elem.language === lan)
        setConsideringDescription(newDescriptionIndex === -1 ? {} : poi.descriptions[newDescriptionIndex]);
    }

    let filledLanguages = [];
    if(consideringDescription.name && consideringDescription.name !== '') filledLanguages.push(language);
    poi.descriptions.forEach((item) => item.name !== "" && filledLanguages.push(item.language));

    return (
        <BaseModal open={open} onClose={onClose} onSave={submit}>
            <Grid container spacing={4} style={{padding: "0.5rem 1rem", marginBottom: "1.5rem"}}>
                <LanguageInput selectedLanguage={language} setLanguage={selectLanguage} filledLanguages={filledLanguages}/>
                <Grid item xs={12}>

                    <TextInput label="Name" value={consideringDescription.name || ''}
                               onTextChange={value => onChange("name", value)}
                               noHelperText ={true} required
                               error={errors["name"]}/>

                </Grid>
                <Grid item xs={12}>

                    <TextInput label="Description" value={consideringDescription.description || ''}
                               onTextChange={value => onChange("description", value)}
                               noHelperText ={true} multiline/>

                </Grid>
            </Grid>
        </BaseModal>
    );
}