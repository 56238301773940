import React, {useState} from "react";
import {useSelector} from "react-redux";
import FilterAndSearchBar from "../components/layout/FilterAndSearchBar";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import {getUserResources, roles} from "../services/PermissionManager";
import makeStyles from '@mui/styles/makeStyles';
import {UserPill} from "./UserManagement/UserPill";
import {useGetUsers} from "../services/ContentManager";
import {useContext} from "react";
import {UserContext} from "./App";
import CreateUserModal from "../components/modals/CreateUserModal";

const useStyles = makeStyles((theme) => ({
	container: {
		marginTop: "2rem",
		padding: "2rem",
		backgroundColor: theme.palette.primary.light,
		width: "auto"
	}
}));

export function UserManagement() {

	let venue = useSelector(store => store.session.venue);
	let user = useContext(UserContext).data;
	let {users} = useGetUsers(venue);
	let [selected, setSelected] = useState(null);
	let [search, setSearch] = useState();
	let [addModal, setAddModal] = useState(false);
	let classes = useStyles();

	let data;
	if (selected) {
		data = users.filter(u => u.role === selected);
	} else {
		data = users.filter(u => u.role !== roles.SUPER);
	}

	if (search) {
		data = data.filter(u => (u.firstName && u.firstName.toLocaleLowerCase().includes(search)) ||
			(u.lastName && u.lastName.toLocaleLowerCase().includes(search)) ||
			(u.email && u.email.toLocaleLowerCase().includes(search)));
	}

	//Se superutente posso assegnare tutte le risorse, se Admin posso assegnare tutte le risorse che mi sono state assegnate, con l'eccezione di quelle riservate agli admin
	let resourcesList = getUserResources(user, venue);

	return <Paper style={{padding: "2rem"}}>
		<FilterAndSearchBar
			selected={selected}
			filters={[
				{
					id: roles.USER,
					name: "user",
				},
				{
					id: roles.ADMIN,
					name: "Admin",
				}
			]}
			onFilter={setSelected}
			onSearch={setSearch}
			allButton
			addElement={() => setAddModal(true)}
		/>
		<Grid container xs={12} className={classes.container}>
			{data.map(user => <Grid item sm={6} key={user.id}>
				<UserPill user={user} venue={venue} resources={resourcesList}/>
			</Grid>)}
		</Grid>
		<CreateUserModal open={addModal} onClose={() => setAddModal(false)}/>
	</Paper>;
}
