import {Grid} from "@mui/material";
import RenderFields from "./RenderFields";
import {useState} from "react";

export default function Historicization({values, setValues, onError}) {

    let [error, setError] = useState({});

    const checkError = (prop, value) => {
        if(error && error[ prop ]) {
            delete error[ prop ]
            if(Object.keys(error).length === 0) onError(false)
        }
        if(prop === "assetRetentionDays" && !value) {
            onError(true);
            setError({...error, assetRetentionDays : "Mandatory or incomplete field"})
        }
        if(prop === "peopleRetentionDays" && !value) {
            onError(true);
            setError({...error, peopleRetentionDays : "Mandatory or incomplete field"})
        }
    }

    const onChange = (name, value) => {
        let newValues = {...values}
        newValues[name] = value
        if(value < 0 && (name === "assetRetentionDays" || name === "peopleRetentionDays")) newValues[name] = 0
        setValues(newValues);
        checkError(name, value)
    }

    const fields = [
        {
            type: "boolean",
            label: "Asset History Enabled",
            value: values.assetHistoryEnabled,
            onChange: (newValue) => onChange("assetHistoryEnabled", newValue),
            xs: 6
        },
        {
            type: "boolean",
            label: "Asset Analytics Enabled",
            value: values.assetAnalyticsEnabled,
            onChange: (newValue) => onChange("assetAnalyticsEnabled", newValue),
            xs: 6
        },
        {
            type: "number",
            label: "Asset Retention Days",
            value: values.assetRetentionDays,
            onChange: (newValue) => onChange("assetRetentionDays", newValue),
            min: 0, required: true,
            error: error.assetRetentionDays,
            xs: 12
        },
        {
            type: "boolean",
            label: "People History Enabled",
            value: values.peopleHistoryEnabled,
            onChange: (newValue) => onChange("peopleHistoryEnabled", newValue),
            xs: 6
        },
        {
            type: "boolean",
            label: "People Analytics Enabled",
            value: values.peopleAnalyticsEnabled,
            onChange: (newValue) => onChange("peopleAnalyticsEnabled", newValue),
            xs: 6
        },
        {
            type: "number",
            label: "People Retention Days",
            value: values.peopleRetentionDays,
            onChange: (newValue) => onChange("peopleRetentionDays", newValue),
            min: 0, required: true,
            error: error.peopleRetentionDays,
            xs: 12
        }
    ]

    return <Grid container spacing={4} style={{margin: 0, width: "100%", marginBottom: "0.5rem"}}>
        <RenderFields fields={fields}/>
    </Grid>
}