import {
	SAVE, SET_USERS
} from "./actions";

import {API_ERROR, API_START, API_END} from "../middleware/actions";

export const LOGOUT = "LOGOUT";

const defaultState = {
	list: [],
	error: null,
	isGetting: false,
	isSaving: false
};

export default function (state = defaultState, action) {
	switch (action.type) {
		case SET_USERS: {
			return {
				...state,
				list: action.data,
				error: null
			};
		}
		case SAVE: {
			let users = state.list.slice();
			let i = users.findIndex(u => u.id = action.data.id);
			if (i > -1) users[i] = action.data;
			else users.unshift(action.data);
			return {
				...state,
				list: users,
				error: null
			};
		}
		case API_START: {
			return {
				...state,
				isGetting: action.data === SET_USERS,
				isSaving: action.data === SAVE,
			};
		}
		case API_END: {
			return {
				...state,
				isGetting: action.data === SET_USERS ? false : state.isGetting,
				isSaving: action.data === SAVE ? false : state.isSaving,
			};
		}
		case API_ERROR: {
			return {
				...state,
				error: action.data
			};
		}
		case LOGOUT: {
			return defaultState;
		}
		default:
			return state;
	}
}
