import {apiAction} from "../middleware/actions";
import {download, MAPS} from "../../services/Client";

export const SET_VENUE = "SET_VENUE";
export const SET_MAPS = "SET_MAPS";
export const SET_IMAGES = "SET_IMAGES";
export const SET_GATEWAYS = "SET_GATEWAYS";

export function setVenue(venue) {
	if (!venue) {
		return {
			type: SET_VENUE
		};
	} else return dispatch => {
		dispatch({
			type: SET_VENUE,
			data: venue
		});
	};
}

export function getMaps(venue) {
	return apiAction({
		url: MAPS,
		data: {venue},
		onSuccess: setMaps,
		label: SET_MAPS
	});
}

export function downloadMap(id) {
	return dispatch => {
		dispatch(downloadMapCreator(id, "downloading"));
		download(`${MAPS}/${id}/image`)
			.then(response => dispatch(downloadMapCreator(id, URL.createObjectURL(response.data))))
			.catch(e => console.log(e));
	};
}

function setMaps(maps) {
	return {
		type: SET_MAPS,
		data: maps
	};
}

function setGateways(gateways) {
	return {
		type: SET_GATEWAYS,
		data: gateways
	};
}

function downloadMapCreator(map, src) {
	return {
		type: SET_IMAGES,
		data: {map, src}
	};
}
