import React, {useState} from "react";
import makeStyles from '@mui/styles/makeStyles';
import {
    GridToolbarExportContainer,
    GridCsvExportMenuItem,
    GridToolbarFilterButton,
    GridToolbarColumnsButton,
    DataGridPro,itIT,
} from "@mui/x-data-grid-pro";
import {Typography, Toolbar, Grid} from "@mui/material";
import clsx from "clsx";
import dayjs from "dayjs";

const useStyles = makeStyles((theme) => ({
    root: {
        height: '75vh',
        width: '100%',
        padding: "2rem"
    },
    table: {
        border: 0,
        '& .MuiDataGrid-columnHeaders, & .MuiDataGrid-columnHeader': {
            backgroundColor: theme.palette.primary.main,
            color: "#FFF",
            textTransform: "uppercase",
        },
        '& .MuiIconButton-root': {
            color: theme.palette.secondary.main
        },
        '& .Mui-checked' : {
            color: theme.palette.text.primary
        }
    },
    title: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        textAlign: "center"
    },
    toolbarButton: {
        padding: "0.375rem",
        fontSize: "0.75rem"
    }
}))

const format = (row, field, timezone) => {
    if (!row) return
    let value, template;
    if (field.includes("_")) {
        let [name, type] = field.split("_")
        value = row[name]
        if (type.toLocaleLowerCase() === 'date') template = "DD/MM/YYYY"
        else template = "HH:mm:ss"
    } else {
        value = row[field]
        template = "DD/MM/YYYY HH:mm:ss"
    }

    if (!value) return ""
    else if(!timezone) return dayjs(new Date(value)).format(template)
    else return dayjs.tz(new Date(value), timezone).format(template)
}

export const timestampFormatter = {
    valueGetter: ({row, field}) => format(row, field, "Europe/Rome"),
    sortComparator: (v1, v2) => sortComparator(v1, v2)
}

export const sortComparator =  (v1, v2) => {
    if (v1.includes(" ") && v2.includes(" ")) {
        let [t1, d1] = v1.split(" ")
        let [t2, d2] = v2.split(" ")
        let comparedDate = compareDate(d1, d2)
        if (comparedDate === 0) return t1.localeCompare(t2)
        else return comparedDate
    } else if (v1.includes("/")) {
        return compareDate(v1, v2);
    } else {
        return v1.localeCompare(v2)
    }

}

const compareDate = (v1, v2) => v1.split("/").reverse().join("").localeCompare(v2.split("/").reverse().join(""))

function CustomToolbar({title, centerButton, rightButton, customExportComponent}) {

    const classes = useStyles();

    return (
        <Toolbar>
            <Grid container justifyContent="center" alignItems="center">
                <Grid item xs={4}>
                    <GridToolbarColumnsButton className={classes.toolbarButton}/>
                    <GridToolbarFilterButton className={classes.toolbarButton}/>
                    <GridToolbarExportContainer className={classes.toolbarButton}>
                        <GridCsvExportMenuItem options={{delimiter:";", fileName:title}} />
                        {customExportComponent}
                    </GridToolbarExportContainer>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="h6" className={classes.title}>
                        {title}
                    </Typography>
                    {centerButton}
                </Grid>
                <Grid item xs={4}>
                    {rightButton}
                </Grid>
            </Grid>
        </Toolbar>
    );
}

//https://github.com/mui-org/material-ui-x/blob/18cd8e182b74a2cfa5889c7b9fba8d7d5016441d/packages/grid/_modules_/grid/models/api/gridColumnApi.ts
export default function NTMXGrid({columns, rows, title, options={}, centerButton, rightButton, customExportComponent,
                                     getRowId, density = "standard", apiRef, checkboxSelection, onSelectionModelChange,
                                     initialState, loading, useItalian, selectionModel, isRowSelectable, disableToolbar,
                                     autoHeight, classes = {}, defaultPageSize = 25, rowsPerPageOptions,
                                     onRowClick}){

    const innerClasses = useStyles();
    let [pageSize, setPageSize] = useState(defaultPageSize);

    return (
        <Grid className={clsx(innerClasses.root, classes.root)}>
            <DataGridPro
                onRowClick={onRowClick}
                apiRef={apiRef}
                disableColumnMenu
                pagination
                checkboxSelection={checkboxSelection}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={rowsPerPageOptions || [25, 50, 100]}
                className={clsx(innerClasses.table, classes.table)}
                getRowId={getRowId}
                columns={columns}
                density={density} //comfortable, standard, compact
                rows={rows}
                loading={loading}
                onSelectionModelChange={onSelectionModelChange}
                selectionModel={selectionModel}
                isRowSelectable={isRowSelectable}
                components={{
                    Toolbar: () => !disableToolbar &&
                        <CustomToolbar title={title} centerButton={centerButton}
                                       rightButton={rightButton} customExportComponent={customExportComponent}/>
                }}
                autoHeight={autoHeight}
                initialState={initialState}
                localeText={useItalian && itIT.components.MuiDataGrid.defaultProps.localeText}
                {...options}
            />
        </Grid>
    );
}
