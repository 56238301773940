import BaseModal from "./BaseModal";
import Grid from "@mui/material/Grid";
import TextInput from "../forms/TextInput";
import React,{useEffect,useState} from "react";

export default function EditRoomModal({values, onClose, onSubmit}) {

    let [name, setName] = useState("");
    let [error, setError] = useState(null);

    useEffect(() => {
        setError(null)
        if(values.name === null) setName("")
        else setName(values.name)
    }, [values]);

    function close() {
        setName("");
        setError(null);
        onClose();
    }

    function submit() {
        if(!name) {
            setError("Mandatory field")
            return;
        }
        onSubmit({...values, name});
    }

    return (
        <BaseModal open={Object.keys(values).length !== 0} onClose={close} onSave={submit}>
            <Grid container spacing={4} style={{padding: "0.5rem 1rem", marginBottom: "0.5rem", minWidth: "20vw"}}>
                <Grid item xs={12}>

                    <TextInput label="Room name" value={name} required
                               onTextChange={(value) => {
                                   setName(value);
                                   setError(null);
                               }} error={error}/>

                </Grid>
            </Grid>
        </BaseModal>
    );
}