import React,{useEffect,useState} from "react";

import FilterAndSearchBar from "../../components/layout/FilterAndSearchBar";
import MapView from "../../components/mapview/MapView";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import {TRACKING_TYPES,useGetMaps,useGetPositions,useGetRooms} from "../../services/ContentManager";
import Paper from "@mui/material/Paper";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Sound from "react-sound"
import {useSelector} from "react-redux";
import NTMXGrid,{timestampFormatter} from "../../components/NTMXGrid";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    tableRoot : {
        padding: 0,
        paddingTop: "1rem",
        height: "50vh"
    }
}));

export default function Tracking({type}) {
    let {maps = []} = useGetMaps()
    const classes = useStyles();
    let venue = useSelector(store => store.session.venue)
    let [selected, setSelected] = useState()
    let [interval, setInterval] = useState((5000))
    let [alarm, setAlarm] = useState(venue.id===1508)
    let [tollerance, setTollerance] = useState(10)
    let [popup, setPopup] = useState({})
    let [positions, setPositions] = useState([])
    let [selectedRows, setSelectedRows] = useState(null)

    let filters = []
    if (maps) filters = maps.filter(m => m.floor !== -1);
    if (filters.length > 0 && !selected) setSelected(filters[0].id);
    let {assets} = useGetPositions(selected, type, interval, tollerance);
    let {rooms} = useGetRooms(selected);

    let filteredPositions = positions.filter(p => selectedRows?.includes(p.id));

    let decodedRooms = rooms?.map(r => {
        return {
            id: r.id,
            coordinates: r.vertices.split(",").map(s => {
                return s.replace('(','').replace(')','')
            }),
            active: filteredPositions.some(a => a.room === r.id)
        }
    })

    useEffect(() => {
        setPositions([])
        setSelectedRows(null)
    }, [type, selected])

    useEffect(() => {
        if(assets.length > 0) {
            setPositions(assets.map(a => {
                return {
                    ...a,
                    x: a.x,
                    y: a.y,
                    id: type === TRACKING_TYPES.ASSETS ? a.id : a.time,
                    beacon: type === TRACKING_TYPES.ASSETS ? a.minor + (a.label ? ` - ${a.label}`: "") : a.device,
                    time: type === TRACKING_TYPES.ASSETS ? a.timestamp : a.time
                };
            }))
            if(selectedRows === null)
                setSelectedRows(assets.map(a => type === TRACKING_TYPES.ASSETS ? a.id : a.time))
        } else {
            if(selectedRows) setSelectedRows(null)
            if(positions.length > 0) setPositions([])
        }
    }, [assets])

    return (
        <div style={{marginTop: "1.5rem"}}>
            {
                filters.length > 1 &&
                <FilterAndSearchBar
                    selected={selected}
                    filters={filters}
                    onFilter={setSelected}
                />
            }
            {selected && <MapView map={selected} popup={popup.container}
                                  rooms={decodedRooms} positions={filteredPositions}
                                  onBackgroundClick={() => setPopup({})}>
                <Grid>
                    <Paper style={{padding: "1.5rem"}}>
                        <Grid container direction={"column"}>
                            <Sound
                                url={"https://freesound.org/data/previews/381/381382_4805473-lq.mp3"}
                                loop={true}
                                playStatus={alarm && positions.length > 0 ? Sound.status.PLAYING : Sound.status.STOPPED}
                            />
                            {venue.id===1508 && <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={alarm}
                                        onChange={event => setAlarm(event.target.checked)}
                                        name="checkedB"
                                        color="primary"
                                    />
                                }
                                label="Enable sound on alarm"
                            />}
                            <FormControl variant="standard">
                                <InputLabel id="interval-select-helper-label">Interval</InputLabel>
                                <Select
                                    variant="standard"
                                    labelId="interval-select-helper-label"
                                    id="interval-select-helper"
                                    value={interval}
                                    onChange={event => setInterval(event.target.value)}>
                                    <MenuItem value={1000}>1 second</MenuItem>
                                    <MenuItem value={2000}>2 seconds</MenuItem>
                                    <MenuItem value={5000}>5 seconds</MenuItem>
                                    <MenuItem value={10000}>10 seconds</MenuItem>
                                </Select>
                                <FormHelperText>When the data will be requested</FormHelperText>
                            </FormControl>
                            <FormControl variant="standard">
                                <InputLabel id="demo-simple-select-helper-label">Tollerance</InputLabel>
                                <Select
                                    variant="standard"
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={tollerance}
                                    onChange={event => setTollerance(event.target.value)}>
                                    <MenuItem value={1}>1 minute</MenuItem>
                                    <MenuItem value={2}>2 minutes</MenuItem>
                                    <MenuItem value={5}>5 minutes</MenuItem>
                                    <MenuItem value={10}>10 minutes</MenuItem>
                                    <MenuItem value={20}>20 minutes</MenuItem>
                                    <MenuItem value={30}>30 minutes</MenuItem>
                                </Select>
                                <FormHelperText>How long the data must be shown</FormHelperText>
                            </FormControl>
                        </Grid>

                        <NTMXGrid
                            columns={[
                                {
                                    headerName: "Time", field: 'time', flex: 2, ...timestampFormatter
                                },
                                TRACKING_TYPES.POSITIONS === type ?
                                    {headerName: "ID", field: 'id', flex: 1}
                                    : {headerName: "Minor", field: 'minor', flex: 1},
                                {
                                    headerName: "Label", field: 'label', flex: 1,
                                },
                            ]}
                            rows={positions || []}
                            defaultPageSize={5}
                            rowsPerPageOptions={[5]}
                            disableToolbar
                            classes={{root: classes.tableRoot}}
                            checkboxSelection
                            onSelectionModelChange={(newSelection) => {
                                setSelectedRows(newSelection);
                            }}
                            selectionModel={selectedRows || []}
                        />
                    </Paper>


                </Grid>
            </MapView>}
        </div>
    );
}
