import {styled} from "@mui/styles";
import HoverableButton from "../../components/layout/HoverableButton";
import React,{useEffect,useState} from "react";
import makeStyles from "@mui/styles/makeStyles";
import {Grid} from "@mui/material";
import {useGetSettings} from "../../services/ContentManager";
import SettingPill from "./SettingPill";
import Generals from "./Generals";
import {useSnackbar} from "notistack";
import {useQueryClient} from "react-query";
import {useSelector} from "react-redux";
import {getErrorMessage,post,SETTINGS,VENUES} from "../../services/Client";
import Historicization from "./Historicization";

const useStyles = makeStyles((theme) => ({
    addButton: {
        color: theme.palette.primary.dark,
        marginRight: "2rem"
    },
    addText: {
        fontWeight: 700,
    }
}));

const PillsGridContainer = styled(Grid)(({ theme }) => ({
    padding : "2rem",
    backgroundColor : theme.palette.primary.light,
    width : "auto"
}));

export default function Settings () {

    const classes = useStyles();
    const {settings} = useGetSettings();
    let [values, setValues] = useState({});
    let [originalSettings, setOriginalSettings] = useState({});
    let [errors, setErrors] = useState({});
    let venue = useSelector(store => store.session.venue);
    const {enqueueSnackbar} = useSnackbar();
    let queryClient = useQueryClient();

    useEffect(() => {
        if(settings){
            let newValues = {}
            settings.map(setting => {
                newValues[setting.name] = setting.value
            })
            setValues(newValues)
            setOriginalSettings(newValues)
        }
    }, [settings])

    const pills = [
        {
            title: "Generals",
            component: <Generals values={values} setValues={setValues}/>
        },
        {
            title: "Historicization",
            component: <Historicization values={values} setValues={setValues}
                                        onError={(value) => setErrors({...errors, historicizationErrors : value})}/>
        },
        {
            title: "People Tracking",
            component: <div/>
        },
        {
            title: "Asset Tracking",
            component: <div/>
        },
    ]

    const saveNewSettings = () => {

        let editedValues = [];
        Object.entries(values).forEach((v) => {
            //v[0]: name, v[1]: value
            if(String(originalSettings[v[0]]).valueOf() !== String(v[1]).valueOf())
                editedValues.push({...settings.find(s => s.name === v[0]), value: v[1]})
        })

        enqueueSnackbar("Saving...", {variant: "info"});
        post(`${VENUES}/${venue.id}/${SETTINGS}`, {body: editedValues})
            .then(() => enqueueSnackbar("Saved", {variant: "success"}))
            .catch(e => enqueueSnackbar(getErrorMessage(e), {variant: "error"}))
            .finally(() => queryClient.invalidateQueries([SETTINGS, {venue: venue.id}]));

    }

    const checkErrors = () => !!(errors.historicizationErrors)

    return <Grid container justifyContent="space-between">

        <Grid container justifyContent={"space-between"}>
            <Grid>
                <HoverableButton
                    item={{
                        icon: require("../../images/icons/pop-up-chiudi.svg").default,
                        iconHover: require("../../images/icons/pop-up-chiudi-over.svg").default,
                        name: "Cancel"
                    }}
                    classes={{root:classes.addButton, text:classes.addText}}
                    disabled={JSON.stringify(originalSettings) === JSON.stringify(values)}
                    onClick={() => setValues({...originalSettings}) /*attenzione, non è un deep clone*/}
                />
            </Grid>
            <Grid>
                <HoverableButton
                    item={{
                        icon: require("../../images/icons/pop-up-salva.svg").default,
                        iconHover: require("../../images/icons/pop-up-salva-over.svg").default,
                        name: "Save"
                    }}
                    classes={{root:classes.addButton, text:classes.addText}}
                    disabled={JSON.stringify(originalSettings) === JSON.stringify(values) || checkErrors()}
                    onClick={saveNewSettings}
                />
            </Grid>
        </Grid>

        <PillsGridContainer container xs={12}>
            {pills.map((pill, index) =>
                <SettingPill pill={pill} index={index}/>
            )}
        </PillsGridContainer>
    </Grid>
}