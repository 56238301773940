import React, {useEffect, useState} from 'react'
import {GoogleMap} from '@react-google-maps/api'

const options = {
    disableDefaultUI: true,
    zoomControl: true,
    mapTypeControl: true,
    scaleControl: true,
    mapTypeControlOptions: {
        mapTypeIds: ['roadmap', 'satellite', 'hybrid']
    }
};

const defaultMapContainerStyle = {
    height: "50vh",
    width: "80vw"
};

const defaultCenter = {
    lat: 41.9028,
    lng: 12.4964,
};

// const defaultCenter = {lat: 41.04431498347576, lng: 16.873920662645865}

export const libraries = ["geometry", "drawing", "visualization", "places"];

export default function GoogleMapContainer({zoom, center, onCLick, children, onZoomChanged, onCenterChanged, maxZoom, mapContainerStyle = defaultMapContainerStyle, mapTypeId="roadmap"}) {

    let [mapCenter, setMapCenter] = useState(center)
    let [map, setMap] = useState(null)

    useEffect(() => {
        setMapCenter(center);
    }, [center])

    return <GoogleMap
        id="map"
        onLoad={map => setMap(map)}
        mapContainerStyle={mapContainerStyle }
        center={mapCenter || defaultCenter}
        options={{maxZoom, mapTypeId, ...options}}
        zoom={zoom || 17}
        onZoomChanged={() => map && onZoomChanged && onZoomChanged(map.getZoom())}
        onCenterChanged={() => map && onCenterChanged && onCenterChanged(map.getCenter())}
        onClick={onCLick}
    >
        {children}
    </GoogleMap>
}
