import React, {useState} from "react";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Icon from "@mui/material/Icon";
import Typography from "@mui/material/Typography";
import AccordionDetails from "@mui/material/AccordionDetails";
import Accordion from "@mui/material/Accordion";
import makeStyles from '@mui/styles/makeStyles';
import {TextItem} from "../../components/TextItem";
import Grid from "@mui/material/Grid";
import {getRoleName,isAdmin,isSuperUser} from "../../services/PermissionManager";
import {AuthorizationItem} from "./AuthorizationItem";
import HoverableIconButton from "../../components/hoverables/HoverableIconButton";
import CreateUserModal from "../../components/modals/CreateUserModal"
import {useContext} from "react";
import {UserContext} from "../App";

const useStyles = makeStyles((theme) => ({
	root: {
		margin: theme.spacing(1),
	},
	header: {
		fontWeight: "bold"
	},
	image: {
		marginRight: theme.spacing(2),
		'& img': {
			height: "100%"
		}
	},
	expanded: {
		backgroundColor: theme.palette.primary.dark
	},
	textItem: {
		color: "white",
		borderColor: theme.palette.primary.light
	}
}));

const smallIcon = require("../../images/icons/top_bar-profilo-30.svg").default;
const largeIcon = require("../../images/icons/top_bar-profilo-50.svg").default;

export function UserPill({user, venue, resources}) {

	let classes = useStyles();
	let [expanded, setExpanded] = useState(false);
	let [editModal, setEditModal] = useState(false);
	let userManaging = useContext(UserContext).data;

	let title;
	if (expanded) {
		title = "";
	} else if (user.firstName || user.lastName) {
		title = `${user.firstName || ""} ${user.lastName || ""}`;
	} else {
		title = user.email;
	}

	return (
		<Accordion
			TransitionProps={{unmountOnExit: true}}
			classes={{root: classes.root, expanded: classes.expanded}}
			square
			onChange={(e, expanded) => setExpanded(expanded)}
		>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon color={expanded ? "secondary" : "primary"}/>}
				aria-controls="panel1a-content"
				id={user.id}
			>
				<Icon className={classes.image}>
					<img alt={"useer"} src={expanded ? largeIcon : smallIcon}/>
				</Icon>
				<Typography align={"center"} color={"textPrimary"} className={classes.header}>{title}</Typography>
			</AccordionSummary>
			<AccordionDetails>
				<Grid container alignItems={"flex-end"} className={classes.container}>
					<Grid item xs={6}>
						<TextItem className={classes.textItem} label="First name">
							{user.firstName}
						</TextItem>
					</Grid>
					<Grid item xs={6}>
						<TextItem className={classes.textItem} label="Last name">
							{user.lastName}
						</TextItem>
					</Grid>
					<Grid item xs={6}>
						<TextItem className={classes.textItem} label="Email">
							{user.email}
						</TextItem>
					</Grid>
					<Grid item xs={6}>
						<TextItem className={classes.textItem} label="Role">
							{getRoleName(user.role)}
						</TextItem>
					</Grid>
					{Object.values(resources).map(r => <AuthorizationItem
						key={r}
						value={r}
						authorization={user.authorizations ? user.authorizations.find(a => a.venue === venue.id && a.resource === r) : undefined}/>)}
					{(isSuperUser(userManaging) || !isAdmin(user)) && <Grid item xs={12} style={{textAlign : "right"}}>
						<HoverableIconButton
							src={require("../../images/icons/pop-up-edit-over.svg").default}
							hoverSrc={require("../../images/icons/pop-up-edit.svg").default}
							onClick={() => setEditModal(true)}
						/>
						<HoverableIconButton
							src={require("../../images/icons/pop-up-delete-over.svg").default}
							hoverSrc={require("../../images/icons/pop-up-delete.svg").default}
							onClick={() => null/*todo perchè è null?*/}
						/>
					</Grid>}
				</Grid>
			</AccordionDetails>
			<CreateUserModal defaultValue={user} open={editModal} onClose={() => setEditModal(false)}/>
		</Accordion>
	);
}
