import React, {useEffect, useState} from "react";
import BaseModal from "./BaseModal";
import TextInput from "../forms/TextInput";
import FileInput from "../forms/FileInput";
import Grid from "@mui/material/Grid";
import {Alert} from "@mui/lab";

export default function MapModal({open, onClose, onSubmit, defaultValue}) {

	let [map, setMap] = useState(defaultValue || {});
	let [newPngDimensions, setNewPngDimensions] = useState({});
	let [errors, setErrors] = useState({});

	useEffect(() => setMap(defaultValue || {}), [defaultValue]);

	useEffect(() => {
		if(map.image?.length > 0){
			map.image.forEach(i => {
				let img = document.createElement('img');
				let blob = URL.createObjectURL(i);
				img.src = blob;
				img.onload = function() {
					setNewPngDimensions({width: img.width, height: img.height})
				}
			})
		} else setNewPngDimensions({})
	}, [map.image]);

	function onChange(name, value) {
		setMap(map => {
			map[name] = value;
			return map;
		});
		setErrors({});
	}

	function submit() {
		let errors = {};
		if (!map.id && (!map.image || map.image.length === 0)) errors.image = "Missing map file";
		if (!map.id && (!map.tiles || map.tiles.length === 0)) errors.tiles = "Missing tiles file";
		if (!map.id && (!map.name || map.name === "")) errors.name = "Missing map name";
		if((!map.image || map.image.length === 0) && (map.tiles && map.tiles.length > 0)) errors.image = "Missing map file";
		if((map.image && map.image.length > 0) && (!map.tiles || map.tiles.length === 0)) errors.tiles = "Missing tiles file";

		if (Object.keys(errors).length !== 0) {
			setErrors(errors);
			return;
		}

		if (map.image) {
			let newMap = JSON.parse(JSON.stringify(map));
			let reader = new FileReader();
			let mapFile = map.image[0];
			reader.readAsDataURL(mapFile);

			reader.onloadend = () => {
				newMap.image = reader.result;
				let mapTile = map.tiles[0];
				reader.readAsDataURL(mapTile);

				reader.onloadend = () => {
					newMap.tiles = reader.result;
					onSubmit(newMap);
				};
			};
		} else {
			onSubmit(map);
		}
	}

	const close = () => {
		setMap({})
		setErrors({})
		setNewPngDimensions({})
		onClose()
	}

	return (

		<BaseModal open={open} onClose={close} onSave={submit}>
			<FileInput
				files={map.image}
				label={'Drag here the image of the floor or <span class="filepond--label-action">Choose</span>'}
				acceptedTypes={["image/png", "image/jpeg"]}
				onChange={maps => onChange("image", maps)}
				errorMessage={errors.image}
			/>
			{
				defaultValue && defaultValue.width && defaultValue.height &&
				newPngDimensions && newPngDimensions.width && newPngDimensions.height &&
				(defaultValue.width !== newPngDimensions.width || defaultValue.height !== newPngDimensions.height) &&
				<Grid sx={{marginBottom: "1rem"}}>
					<Alert variant="filled" severity="warning">
						Warning! Mismatched image sizes<br/>
						Old dimensions w: {defaultValue.width} h: {defaultValue.height}<br/>
						New dimensions w: {newPngDimensions.width} h: {newPngDimensions.height}
					</Alert>
				</Grid>
			}
			<FileInput
				files={map.tiles}
				label={'Drag here the zip file of the floor\'s tiles or <span class="filepond--label-action">Choose</span>'}
				acceptedTypes={["application/zip", "application/x-zip", "application/x-zip-compressed"]}
				onChange={tiles => onChange("tiles", tiles)}
				errorMessage={errors.tiles}
			/>
			<Grid container spacing={3} style={{padding: "0.5rem 1rem"}}>
				<Grid item xs={12}>
					<TextInput
						label={"Map name"}
						onTextChange={value => onChange("name", value)}
						value={map.name}
						required={!!defaultValue}
						error={errors.name}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextInput
						type={"number"}
						label={"Map floor"}
						step={1}
						min={0}
						max={99}
						onTextChange={value => onChange("floor", +value)}
						value={map.floor}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextInput
						type={"number"}
						label={"North"}
						step={1}
						min={0}
						max={364}
						onTextChange={value => onChange("north", +value)}
						value={map.north}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextInput
						type={"number"}
						label={"Scale"}
						step={1}
						min={0}
						max={99}
						onTextChange={value => onChange("scale", +value)}
						value={map.scale}
					/>
				</Grid>
			</Grid>
		</BaseModal>
	);
}
