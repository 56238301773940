import React, {useEffect, useState} from "react";
import BaseModal from "./BaseModal";
import TextInput from "../forms/TextInput";

export default function VenueModal({open, onClose, onSubmit}) {

	let [venue, setVenue] = useState({});
	let [errors, setErrors] = useState({});

	function onChange(name, value) {
		let newVenue = Object.assign(venue, {[name]: value});
		setVenue(newVenue);
		setErrors({});
	}

	function submit() {
		if (!venue.name || venue.name === "") {
			let newErrors = {name: "Missing name"};
			setErrors(newErrors);
			return;
		}
		onSubmit(venue);
	}

	return (
		<BaseModal open={open} onClose={onClose} onSave={submit}>

			<TextInput required label={"Name"} value={venue.name} onTextChange={value => onChange("name", value)}
			           error={errors.name}/>
			<TextInput label={"Description"} value={venue.description}
			           onTextChange={value => onChange("description", value)}/>
			<TextInput label={"Address"} value={venue.address} onTextChange={value => onChange("address", value)}/>
			<TextInput label={"City"} value={venue.city} onTextChange={value => onChange("city", value)}/>
		</BaseModal>
	);
}
