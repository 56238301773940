import {useGetMaps,useGetRooms} from "../../services/ContentManager";
import React,{useEffect,useState} from "react";
import FilterAndSearchBar from "../../components/layout/FilterAndSearchBar";
import MapView from "../../components/mapview/MapView";
import HoverableButton from "../../components/layout/HoverableButton";
import makeStyles from "@mui/styles/makeStyles";
import EditRoomModal from "../../components/modals/EditRoomModal";
import {useSnackbar} from "notistack";
import {useQueryClient} from "react-query";
import {deleteElem,getErrorMessage,MAPS,post,put,ROOMS} from "../../services/Client";
import RoomPopup from "../../components/Popup/RoomPopup";
import {Grid} from "@mui/material";
import AngleCoordinatesPopup from "../../components/Popup/AngleCoordinatesPopup";

const useStyles = makeStyles((theme) => ({
    addButton: {
        color: theme.palette.primary.main,
        marginRight: "2rem"
    },
    addText: {
        fontWeight: 700,
    }
}));

export default function EditRooms() {

    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();
    let queryClient = useQueryClient();
    let {maps = []} = useGetMaps()
    let [selected, setSelected] = useState()
    let [newRoom, setNewRoom] = useState(null)
    let [editModal, setEditModal] = useState({})
    let [editRoom, setEditRoom] = useState({})
    let [popup, setPopup] = useState({});

    let filters = []
    if (maps) filters = maps.filter(m => m.floor !== -1);
    if (filters.length > 0 && !selected) setSelected(filters[0].id);

    let {rooms} = useGetRooms(selected);

    useEffect(() => {
        if(newRoom?.length >= 8 && newRoom[newRoom.length-2] === newRoom[0] && newRoom[newRoom.length-1] === newRoom[1]) {
            setEditModal({name: ""});
        }
    }, [newRoom])

    const onEditRoomName = (room) => {
        if(!room.id) {
            let toSaveRoom = {}
            toSaveRoom.name = room.name;
            toSaveRoom.vertices = encodeRooms(newRoom.slice(0, newRoom.length - 2))
            enqueueSnackbar("Saving...", {variant: "info"});
            post(`${MAPS}/${selected}/${ROOMS}`, {
                body: toSaveRoom
            }).then(() => {
                setNewRoom(null)
                setEditModal({});
                enqueueSnackbar("Saved",{variant : "success"});
                queryClient.invalidateQueries([MAPS,{map : selected},ROOMS])
            }).catch(e => enqueueSnackbar(getErrorMessage(e), {variant: "error"}));
        } else {
            saveEdit({name: room.name}, room.id)
        }
    }

    const saveEdit = (body, roomId) => {
        enqueueSnackbar("Saving...", {variant: "info"});
        put(`${MAPS}/${selected}/${ROOMS}`, {
            body,
            elem: roomId
        }).then(() => {
            setEditModal({});
            setPopup({})
            setEditRoom({})
            enqueueSnackbar("Saved",{variant : "success"});
            queryClient.invalidateQueries([MAPS,{map : selected},ROOMS])
        }).catch(e => enqueueSnackbar(getErrorMessage(e), {variant: "error"}));
    }

    const saveEditRoom = () => {
        let newRoom = {};
        newRoom.vertices = encodeRooms(editRoom.coordinates);
        newRoom.id = editRoom.id
        saveEdit(newRoom, editRoom.id)
    }

    let decodedRooms = rooms?.map(r => {
        return {
            id: r.id,
            coordinates: r.vertices.split(",").map(s => {
                return s.replace('(','').replace(')','')
            }),
            name: r.name
        }
    })

    function onRoomClick(room, popupPosition){
        if(popup.id && popup.id  === room.id) {
            setPopup({});
            return;
        }
        let newPopup = <RoomPopup
            roomName={room.name}
            popupPosition={popupPosition}
            onEditName={() => setEditModal(room)}
            onEditRoom={() => {
                setPopup({})
                setEditRoom(room)
            }}
            onDelete = {() => {
                enqueueSnackbar("Saving...", {variant: "info"});
                deleteElem(`${MAPS}/${selected}/${ROOMS}`, {elem: room.id})
                    .then(() => {
                        enqueueSnackbar("Deleted!",{variant : "success"});
                        setPopup({});
                    })
                    .catch(e => enqueueSnackbar(getErrorMessage(e), {variant: "error"}))
                    .finally(() => queryClient.invalidateQueries([MAPS, {map: selected}, ROOMS]));
            }}
        />
        setPopup({container: newPopup, id: room.id});
    }

    const onEditRoomAngleClick = (xIndex, x, y) => {
        let newPopup = <AngleCoordinatesPopup
            x={x} y={y}
            onChange={(label, newValue) => {
                let newCoordinates = [...editRoom.coordinates];
                newCoordinates[label === "x" ? xIndex : xIndex+1] = newValue
                setEditRoom({...editRoom, coordinates: newCoordinates})
                onEditRoomAngleClick(xIndex, newCoordinates[xIndex], newCoordinates[xIndex+1])
            }}
        />
        setPopup({container: newPopup})
    }

    return (
        <div style={{marginTop: "0.3rem"}}>
            <div style={{marginBottom: "0.3rem"}}>
            {
                filters.length > 1 &&
                <FilterAndSearchBar
                    selected={selected}
                    filters={filters}
                    onFilter={(selection) => {
                        setSelected(selection)
                        setPopup({})
                        setEditRoom({})
                    }}
                />
            }
            </div>
            {selected &&
                <MapView
                    map={selected}
                    rooms={decodedRooms}
                    newRoom={newRoom}
                    setNewRoom={setNewRoom}
                    onRoomClick={newRoom === null && !editRoom.id && onRoomClick}
                    onBackgroundClick={() => setPopup({})}
                    popup={popup.container}
                    editRoom={editRoom}
                    setEditRoom={setEditRoom}
                    onEditRoomAngleClick={(xIndex) => onEditRoomAngleClick(xIndex, editRoom.coordinates[xIndex], editRoom.coordinates[xIndex+1])}
                >
                    <HoverableButton
                        item={{
                            icon: require("../../images/icons/pop-up-aggiungi.svg").default,
                            iconHover: require("../../images/icons/pop-up-aggiungi-over.svg").default,
                            name: "ADD ROOM"
                        }}
                        onClick={() => {
                            setNewRoom(newRoom === null ? [] : null)
                            setPopup({})
                        }}
                        disabled={!!editRoom.id}
                        classes={{root:classes.addButton, text:classes.addText}}
                    />
                    {editRoom.id &&
                        <Grid container>
                            <HoverableButton
                                item={{
                                    icon: require("../../images/icons/pop-up-salva.svg").default,
                                    iconHover: require("../../images/icons/pop-up-salva-over.svg").default,
                                    name : "SAVE"
                                }}
                                onClick={saveEditRoom}
                                classes={{root : classes.addButton,text : classes.addText}}
                            />
                            <HoverableButton
                                item={{
                                    icon: require("../../images/icons/pop-up-chiudi.svg").default,
                                    iconHover: require("../../images/icons/pop-up-chiudi-over.svg").default,
                                    name : "CANCEL"
                                }}
                                onClick={() => {
                                    setEditRoom({})
                                    setPopup({})
                                }}
                                classes={{root : classes.addButton,text : classes.addText}}
                            />
                        </Grid>
                    }
                </MapView>
            }
            <EditRoomModal
                values={editModal}
                onSubmit={onEditRoomName}
                onClose={() => {
                    setNewRoom(null)
                    setEditModal({});
                }}
            />
        </div>
    );
}

/**
 *
 * @param coordinates like [x1, y1, x2, y2, ..., xn, yn]
 * @returns {string} like "(x1,y1),(x2,y2),...,(xn,yn)"
 */
function encodeRooms(coordinates) {
    let vertices = "";
    coordinates.forEach((value,i) => {
        if(i % 2) vertices += `${value}),`
        else vertices += `(${value},`
    })
    return vertices.slice(0,-1);
}