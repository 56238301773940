//React
import React from 'react';
import ReactDOM from 'react-dom';
//Redux
import {Provider} from "react-redux";
import {applyMiddleware, compose, createStore} from "redux";
import {persistStore, persistReducer, createTransform} from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web and AsyncStorage for react-native
import {PersistGate} from 'redux-persist/integration/react';
import {createLogger} from "redux-logger";
import thunk from "redux-thunk";
import reducers from "./redux";

import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

import App from './containers/App';
import * as serviceWorker from './serviceWorker';
import apiMiddleware from "./redux/middleware/apiMiddleware";
import { createTheme } from "@mui/material";
import { ThemeProvider } from '@mui/material/styles';
import { StyledEngineProvider } from '@mui/material/styles';
import {SnackbarProvider} from "notistack";
import Button from "@mui/material/Button";
import {LicenseInfo} from "@mui/x-data-grid-pro";

import dayjs from "dayjs";
import localeData from 'dayjs/plugin/localeData'

import {
	QueryClient,
	QueryClientProvider
} from "react-query";
import {LoadScript} from "@react-google-maps/api";
import {libraries} from "./components/mapview/GoogleMapContainer";

let middleware = [apiMiddleware, thunk];

if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
	// Dev-only middleware
	console.log("DEV_only middleware");
	middleware = [
		...middleware,
		//createLogger() // Logs state changes to the dev console
	];
}

const persistConfig = {
	key: 'root',
	storage,
	whitelist: ['session'],
	transforms: [
		// nested blacklist-paths require a custom transform to be applied
		createTransform((inboundState, key) => {

			return inboundState;
		}, null),
	],
};
const persistedReducer = persistReducer(persistConfig, reducers);

const createStoreWithMiddleware = compose(applyMiddleware(...middleware))(createStore);
let store = createStoreWithMiddleware(persistedReducer);
let persistor = persistStore(store);

Bugsnag.start({
	apiKey: 'b54a91baf5ce278e6c7576a439daec5a',
	plugins: [new BugsnagPluginReact()],
	enabledReleaseStages: [ 'production' ]
});

const ErrorBoundary = Bugsnag.getPlugin('react')
	.createErrorBoundary(React);

LicenseInfo.setLicenseKey(
	'96982ca227141c27dcacdc03f96229ecT1JERVI6NDA4ODQsRVhQSVJZPTE2ODAzNjY1NjMwMDAsS0VZVkVSU0lPTj0x',
);

const theme = createTheme({
	palette: {
		primary: {
			// Purple and green play nicely together.
			main: '#0A698A',
			light: "#D4EDFC",
			dark: "#12313B",
			contrastText: '#fff',
			error: "#d32f2f",
		},
		secondary: {
			// This is green.A700 as hex.
			main: '#5BC5F2',
		},
		text: {
			primary: "#12313B"
		}
	},
	typography: {
		body1: {
			fontSize: "0.875rem",
			lineHeight: 1.43,
			letterSpacing: "0.01071em",
		}
	},
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					whiteSpace: "nowrap",
					verticalAlign: "middle",
					userSelect: "none",
					border: "1px solid transparent",
					padding: "0.75rem 1rem",
					fontWeight: "bold",
					textAlign: "center",
					borderRadius: 0,
					minWidth: "auto",
					fontSize: "0.875rem",
					lineHeight: "1.25",
				}
			}
		},
		MuiTableRow: {
			styleOverrides: {
				root: {
					'&.Mui-hover': {backgroundColor: '#D4EDFC',}
				}
			}
		},
	},
});

const notistackRef = React.createRef();
const onClickDismiss = key => () => {
	notistackRef.current.closeSnackbar(key);
};

const queryClient = new QueryClient();

const duration = require('dayjs/plugin/duration')
const customParseFormat = require('dayjs/plugin/customParseFormat')
let utc = require('dayjs/plugin/utc')
let timezone = require('dayjs/plugin/timezone') // dependent on utc plugin
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(customParseFormat)
dayjs.extend(duration)
dayjs.extend(localeData)

ReactDOM.render(
	<React.StrictMode>
		<ErrorBoundary>
			<Provider store={store}>
				<PersistGate loading={null} persistor={persistor}>
					<StyledEngineProvider injectFirst>
                        <ThemeProvider theme={theme}>
                            <SnackbarProvider
                                ref={notistackRef}
                                action={(key) => (
                                    <Button onClick={onClickDismiss(key)}>
                                        'Close'
                                    </Button>
                                )}
                                autoHideDuration={3000}>
                                <QueryClientProvider client={queryClient}>
	                                <LoadScript googleMapsApiKey={"AIzaSyASkt6JkNa5yn_2cXq-tQGbBmGp8K-3QII"}
	                                            libraries={libraries} language={"en"}>
                                    <App/>
	                                </LoadScript>
                                </QueryClientProvider>
                            </SnackbarProvider>
                        </ThemeProvider>
                    </StyledEngineProvider>
				</PersistGate>
			</Provider>
		</ErrorBoundary>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
