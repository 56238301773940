import {baseUrl} from "../constants/network";
import {TokenManager} from "./TokenManager";

let axios = require('axios');
let tokenManager = new TokenManager();


export const LOGIN_URL = "developers/login";
export const REFRESH_TOKEN = "developers/refresh";
export const PASSWORD = "developers/password";
export const USERS = "developers";
export const VENUES = "venues";
export const BEACONS = "beacons";
export const GATEWAYS = "gateways";
export const POI = "poi";
export const PATHS = "paths";
export const NODES = "paths/nodes";
export const LINKS = "paths/links";
export const EVENTS = "events"
export const POSITIONS = "positions";
export const MAPS = "maps";
export const ROOMS = "rooms";
export const RGB = "rgb";
export const APPS = "apps";
// export const CT_GATEWAYS = "contact-tracing/gateways";
// export const CT_EVENTS = "contact-tracing/events";
// export const CT_OVERVIEW = "contact-tracing/overview";
export const ASSETS = "assets";
export const ASSETS_DETECTIONS = "assets/detections";
export const SETTINGS = "settings"


export function get(url, config = {elem: "", params: {}, header: {}}) {
	return axios.get(getUrl(url, config.elem), getConfig(config)).then(({data}) => data);
}

export function post(url, config = {elem: "", body: {}, params: {}, header: {}}) {
	return axios.post(getUrl(url, config.elem), config.body, getConfig(config));
}

export function put(url, config = {elem: "", body: {}, params: {}, header: {}}) {
	return axios.put(getUrl(url, config.elem), config.body, getConfig(config));
}

export function deleteElem(url, config = {elem: "", body: {}, params: {}, header: {}}) {
	return axios.delete(getUrl(url, config.elem), getConfig(config));
}

export function download(url) {
	return axios.get(getUrl(url), {responseType: 'blob', ...getConfig({})});
}

export function getErrorMessage(e) {
	let error = "Unknown error";
	if (e.response) {
		// The request was made and the server responded with a status code
		// that falls out of the range of 2xx
		error = e.response.data.error;
	} else {
		// Something happened in setting up the request that triggered an Error
		error = e.message || e.error;
	}
	console.log(error);
	return error;
}

function getConfig({params = {}, headers = {}}) {
	let token = tokenManager.get();
	if (token) headers['Authorization'] = `Bearer ${token}`;
	return {
		params: params,
		headers
	};
}

function getUrl(url, elem) {
	return elem ? `${baseUrl}${url}/${elem}` : `${baseUrl}${url}`;
}

