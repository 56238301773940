import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import Box from "@mui/material/Box";
import {Grid} from "@mui/material";

const useStyles = makeStyles((theme) => ({
    box: {
        padding: theme.spacing(1),
        paddingTop: 0,
        marginBottom: theme.spacing(1),
        borderBottom: `${theme.palette.secondary.main} 1px solid`,
        color: theme.palette.primary.light,
        fontWeight: "bold",
        flexGrow: 1,
    },
    label: {
        padding: theme.spacing(1),
        paddingBottom: "0.1rem",
        fontWeight: "bold",
        flexGrow: 1,
    }
}));

export default function TextBoxForPopup ({label, element}) {
    const classes = useStyles();

    return(<>
            <Box xs={12} className={classes.label}>{label}</Box>
            <Grid item xs={12}>
                <Box xs={12} className={classes.box}>{element}</Box>
            </Grid>
        </>
    );
}