//Lista delle voci del menu
//Aggiungi un elemento nell'array per avere una nuova voce

const dashboard = {
	name: "Dashboard",
	url: process.env.REACT_APP_ENVIRONMENT==='capodichino' ? "/capodichino" : "/dashboard",
	icon: require("../images/icons/menu-home.svg").default,
	iconHover: require("../images/icons/menu-home-over.svg").default,
	iconSelected: require("../images/icons/menu-home-select.svg").default
};

const maps = {
	name: "Maps",
	url: "/maps",
	icon: require("../images/icons/menu-maps.svg").default,
	iconHover: require("../images/icons/menu-maps-over.svg").default,
	iconSelected: require("../images/icons/menu-maps-select.svg").default
}

const capodichino_maps = {
	name: "Mappa",
	url: "/gpsmaps",
	icon: require("../images/icons/menu-maps.svg").default,
	iconHover: require("../images/icons/menu-maps-over.svg").default,
	iconSelected: require("../images/icons/menu-maps-select.svg").default
}

const tours = {
	name: "Thematic Tours",
	url: "/tours",
	icon: require("../images/icons/menu-routes.svg").default,
	iconHover: require("../images/icons/menu-routes-over.svg").default,
	iconSelected: require("../images/icons/menu-routes-select.svg").default
};

const content = {
	name: "Content",
	url: "/content",
	icon: require("../images/icons/menu-content.svg").default,
	iconHover: require("../images/icons/menu-content-over.svg").default,
	iconSelected: require("../images/icons/menu-content-select.svg").default
};

const users = {
	name: "User Management",
	url: "/users",
	icon: require("../images/icons/menu-user-management.svg").default,
	iconHover: require("../images/icons/menu-user-management-over.svg").default,
	iconSelected: require("../images/icons/menu-user-management-select.svg").default
};

const monitoring = {
	name: "Monitoring",
	url: "/monitoring",
	icon: require("../images/icons/menu-monitoring.svg").default,
	iconHover: require("../images/icons/menu-monitoring-over.svg").default,
	iconSelected: require("../images/icons/menu-monitoring-select.svg").default
};


const admin = {
	name: "Amministrazione",
	url: "/administration",
	icon: require("../images/icons/menu-advanced.svg").default,
	iconHover: require("../images/icons/menu-advanced-over.svg").default,
	iconSelected: require("../images/icons/menu-advanced-select.svg").default
};
const stats = {
	name: "Nextostats",
	url: "/stats",
	icon: require("../images/icons/menu-advanced.svg").default,
	iconHover: require("../images/icons/menu-advanced-over.svg").default,
	iconSelected: require("../images/icons/menu-advanced-select.svg").default
};


const industrial = {
	name: "Industrial",
	url: "/stats/industrial",
	icon: require("../images/icons/menu-advanced.svg").default,
	iconHover: require("../images/icons/menu-advanced-over.svg").default,
	iconSelected: require("../images/icons/menu-advanced-select.svg").default
}
const healthcare = {
	name: "Healthcare",
	url: "/stats/healthcare",
	icon: require("../images/icons/menu-advanced.svg").default,
	iconHover: require("../images/icons/menu-advanced-over.svg").default,
	iconSelected: require("../images/icons/menu-advanced-select.svg").default
}
const offices = {
	name: "Offices",
	url: "/stats/offices",
	icon: require("../images/icons/menu-advanced.svg").default,
	iconHover: require("../images/icons/menu-advanced-over.svg").default,
	iconSelected: require("../images/icons/menu-advanced-select.svg").default
}
const museums = {
	name: "Museums",
	url: "/stats/museums",
	icon: require("../images/icons/menu-advanced.svg").default,
	iconHover: require("../images/icons/menu-advanced-over.svg").default,
	iconSelected: require("../images/icons/menu-advanced-select.svg").default
}

const settings = {
	name: "Settings",
	url: "/settings",
	//todo cambia le icone
	icon: require("../images/icons/menu-advanced.svg").default,
	iconHover: require("../images/icons/menu-advanced-over.svg").default,
	iconSelected: require("../images/icons/menu-advanced-select.svg").default
};

export default {
	admin,
	content,
	dashboard,
	maps,
	capodichino_maps,
	monitoring,
	tours,
	users,
	stats,
	industrial,
	healthcare,
	offices,
	museums,
	settings
};

