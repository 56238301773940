import {apiAction} from "../middleware/actions";

export const SET_USERS = "SET_USERS";
export const SAVE = "SAVE_USERS";

const USERS_URL = "developers";

export function getUsers(venueId) {
	return apiAction({
		url: USERS_URL,
		onSuccess: setUsers,
		label: USERS_URL,
		data: {venueId}
	});
}

export function saveUser(user) {
	if (user.id)
		return apiAction({
			url: `${USERS_URL}/${user.id}`,
			method: "PUT",
			data: user,
			onSuccess: save,
			label: SAVE
		});
	else return apiAction({
		url: USERS_URL,
		method: "POST",
		data: user,
		onSuccess: save,
		label: SAVE
	});
}

function setUsers(users) {
	return {
		type: SET_USERS,
		data: users
	};
}

function save(user) {
	return {
		type: SAVE,
		data: user
	};
}
