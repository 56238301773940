import {combineReducers} from "redux";

import users from "./users/reducers"
import venues from "./venues/reducers"
import session from "./session/reducers"

const rootReducer = combineReducers({
	session, users, venues
});

export default rootReducer;
