import React, {useEffect, useState} from "react";

import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import {get} from "../services/Client";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles({
    report: {
        height:"80vh"
    }
});

export default function PowerBI({reportId}) {

    const [id, setId] = React.useState();
    const [embedUrl, setEmbedUrl] = React.useState();
    const [accessToken, setAccessToken] = React.useState();
    const classes = useStyles();
    const [config, setConfig] = useState({
        type: 'report',
        tokenType: models.TokenType.Embed,
        settings: {
            panes: {
                filters: {
                    expanded: false,
                    visible: false
                }
            },
            background: models.BackgroundType.Transparent,
        }
    })

    useEffect(() => {
        get("getembedinfo", {params: {reportId}}).then(r => {
            let newConf = {
                ...config,
                id:r.embedReports[0].reportId,
                embedUrl:r.embedReports[0].embedUrl,
                accessToken:r.embedToken,
            }
            setConfig(newConf)
        })
    }, [reportId])

    return (

        <PowerBIEmbed
            embedConfig = {config}

            eventHandlers = {
                new Map([
                    ['loaded', function () {console.log('Report loaded');}],
                    ['rendered', function () {console.log('Report rendered');}],
                    ['error', function (event) {console.log(event.detail);}]
                ])
            }

            cssClassName = { classes.report }

            getEmbeddedComponent = {console.log}
        />
    )
}
