import React from "react";
import HoverableIconButton from "../hoverables/HoverableIconButton";
import {Grid} from "@mui/material";
import {Link} from "react-router-dom";

import gatewayIcon from "../../images/icons/on-map-cloud-beacon.svg";
import move from "../../images/icons/pop-up-sposta.svg";
import moveHover from "../../images/icons/pop-up-sposta-over.svg";
import edit from "../../images/icons/pop-up-edit.svg";
import editHover from "../../images/icons/pop-up-edit-over.svg";
import deleteIcon from "../../images/icons/pop-up-delete.svg";
import deleteIconHover from "../../images/icons/pop-up-delete-over.svg";
import list from "../../images/icons/pop-up-vedi-in-tabella.svg";
import listHover from "../../images/icons/pop-up-vedi-in-tabella-over.svg";
import ConfirmIconButton from "../buttons/ConfirmIconButton";
import TextBoxForPopup from "./TextBoxForPopup";
import BasePopup from "./BasePopup";
import {useSelector} from "react-redux";
import Can from "../../services/Can";

export default function GatewayPopup ({gateway, popupPosition, onMove, onEdit, onDelete}) {

    let venue = useSelector(store => store.session.venue);

    return(
        <BasePopup popupPosition={popupPosition} header={
            <>
                <Grid item>
                    <HoverableIconButton src={gatewayIcon} hoverSrc={gatewayIcon}/>
                </Grid>
                <Grid item>
                    <Can I="write" a={venue.id + ""} field="gateways">
                        {onMove && <HoverableIconButton src={move} hoverSrc={moveHover} onClick={onMove}/>}
                        {onEdit && <HoverableIconButton src={edit} hoverSrc={editHover} onClick={onEdit}/>}
                        {onDelete && <ConfirmIconButton item={{icon : deleteIcon,iconHover : deleteIconHover}}
                                            text="Are you sure you want to permanently remove the selected gateway?"
                                            title="Confirm?" onConfirm={onDelete}/>}
                    </Can>
                    <Link to={"/content"}><HoverableIconButton src={list} hoverSrc={listHover}/></Link>
                </Grid>
            </>
        }>
            <TextBoxForPopup element={gateway.serial} label="Serial"/>
            <TextBoxForPopup element={gateway.label} label="Label"/>
        </BasePopup>
    );
}