import React,{useContext,useEffect,useState} from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";

import {LoginPage} from "../components/pages/LoginPage";
import Main from "./Main";
import {AbilityContext} from "../services/Can";
import ability from "../services/ability";
import ChangePasswordPage from "../components/pages/ChangePasswordPage";
import {TokenManager} from "../services/TokenManager";
import {login,refreshToken} from "../services/UserManager";
import ForgotPasswordPage from "../components/pages/ForgotPasswordPage";
import Bugsnag from "@bugsnag/js";
let tokenManager = new TokenManager();
export const UserContext = React.createContext(null);

function App() {

    let [user, setUser] = useState({status:{ready:false}, data:{}, onLogout: logout, onLogin: onLogin})
    let [error, setError] = useState(null)

    useEffect(() => {
        onLogin()
    }, []);

    function logout() {
        tokenManager.delete();
        setUser({
            ...user,
            data: {},
            status: {isLogged: false, ready: true}
        })
    }

    function onLogin(email, password) {
        let promise
        if(email && password) promise = login(email, password)
        else promise = refreshToken()
        promise.then((data) => {
            setUser({
                ...user,
                status: {
                    isLogged: true,
                    ready: true
                },
                data:data.developer
            })
            Bugsnag.setUser(data.developer.id, data.developer.email, data.developer.username)
        }).catch(e => {
            console.log(e)
            if(e?.includes("Incorrect")) e = "Email o password errati"
            setError(e)
            setUser({
                ...user,
                status: {
                    isLogged: false,
                    ready: true,
                    changePassword: e === "New password is required" ? email : undefined
                }
            })
        })
    }

    function onSuccessUpdatePassword({data}) {
        const {accessToken, expiresIn, refreshToken} = data
        tokenManager.save({accessToken,expiresIn,refreshToken})
        setUser({
            ...user,
            status: {
                isLogged: true,
                ready: true
            },
            data: data.developer
        })
    }

    if (!user.status.ready) return <div/>
    if (user.status.changePassword) return <ChangePasswordPage onSuccess={onSuccessUpdatePassword} email={user.status.changePassword}/>;

    return (
        <Router>
            <UserContext.Provider value={user}>

                <Switch>
                    <UnloggedRoute path="/login">
                        <LoginPage onSubmit={onLogin} error={error}/>
                    </UnloggedRoute>
                    <UnloggedRoute path="/forgotPassword">
                        <ForgotPasswordPage/>
                    </UnloggedRoute>
                    <AbilityContext.Provider value={ability(user.data)}>
                        <PrivateRoute path="/">
                            <Main/>
                        </PrivateRoute>
                    </AbilityContext.Provider>
                </Switch>
            </UserContext.Provider>
        </Router>
    );
}

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
function PrivateRoute({ children, ...rest }) {
    const user = useContext(UserContext);
    return (
        <Route
            {...rest}
            render={({ location }) =>
                user.status.isLogged ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: { from: location }
                          }}
                    />
                    )
            }
        />
  );
}

function UnloggedRoute({ children, ...rest }) {
    const user = useContext(UserContext);
    return (
        <Route
            {...rest}
            render={() =>
                user.status.isLogged ? (
                    <Redirect
                        to={{
                            pathname: "/"
                        }}
                    />
                ) : (
                    children
                )
            }
        />
    );
}

export default App;
