import React,{useState} from "react";
import BaseModal from "./BaseModal";
import Grid from "@mui/material/Grid";
import TextInput from "../forms/TextInput";
import RadioButtons from "../forms/RadioButtons";
import {ASSET_BEACON_TYPES} from "../../containers/Content/AssetBeacons";
import {ASSETS,getErrorMessage,post,put} from "../../services/Client";
import {useQueryClient} from "react-query";
import {useSelector} from "react-redux";
import {useSnackbar} from "notistack";

export default function AssetBeaconModal({open, onClose}) {

    let venue = useSelector(store => store.session.venue);
    const {enqueueSnackbar} = useSnackbar();
    let queryClient = useQueryClient();
    let [values, setValues] = useState({type: 1});
    let [errors, setErrors] = useState({});

    const close = () => {
        setValues({type: 1});
        setErrors({});
        onClose();
    }

    const save = () => {
        if(!values.uuid) {
            setErrors({uuid: "Mandatory field"});
            return;
        }
        if(!values.label) {
            setErrors({label: "Mandatory field"});
            return;
        }
        if(values.type === 1) {
            if(!values.major) {
                setErrors({major : "Mandatory field"});
                return;
            }
            if(!values.minor) {
                setErrors({minor : "Mandatory field"});
                return;
            }
        } else {
            delete values.minor
            delete values.major
        }
        enqueueSnackbar("Saving...", {variant: "info"});
        post(ASSETS,{body: {venue: venue.id, ...values}})
            .then(() => {
                enqueueSnackbar("Saved",{variant : "success"})
                close()
            })
            .catch(e => enqueueSnackbar(getErrorMessage(e), {variant: "error"}))
            .finally(() => queryClient.invalidateQueries(['assets', {venue: venue.id}]))
    }

    const onTextChange = (name, value) => {
        let newValues = {...values};
        newValues[name] = value;
        setErrors({});
        setValues(newValues);
    }

    return <BaseModal open={open} onClose={close} onSave={save}>
        <Grid container spacing={4} style={{padding: "0.5rem 1rem", marginBottom: "1.5rem"}}>
            <Grid item xs={12}>

                <TextInput label="Label" value={values.label}
                           onTextChange={(value) => onTextChange("label", value)}
                           error={errors["label"]} required/>

            </Grid>
            <Grid item xs={12}>

                <TextInput label="UUID Beacon" value={values.uuid}
                           onTextChange={(value) => onTextChange("uuid", value.trim())}
                           error={errors["uuid"]} required/>

            </Grid>
            <Grid item xs={12}>
                <RadioButtons label={"Type"} value={values.type} row
                              onChange={(value) => onTextChange("type", parseInt(value))}
                              options={ASSET_BEACON_TYPES}/>
            </Grid>
            {values.type === 1 &&
                <>
                    <Grid item xs={6}>

                        <TextInput label="Major Beacon" value={values.major} type="number"
                                   step={1} min={0} max={65535} required
                                   onTextChange={(value) => onTextChange("major",value.trim())}
                                   error={errors[ "major" ]}/>

                    </Grid>
                    <Grid item xs={6}>

                        <TextInput label="Minor Beacon" value={values.minor} type="number"
                                   step={1} min={0} max={65535} required
                                   onTextChange={(value) => onTextChange("minor",value.trim())}
                                   error={errors[ "minor" ]}/>

                    </Grid>
                </>
            }
        </Grid>
    </BaseModal>

}