import React,{useEffect,useState} from 'react';
import {useSelector, useDispatch} from "react-redux";
import HereMap from "../components/mapview/HereMap";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import {makeStyles} from "@mui/styles";
import {usePrefetchContent} from "../services/ContentManager";
import IconButton from "@mui/material/IconButton";
import edit from "../images/icons/pop-up-edit.svg";
import save from "../images/icons/pop-up-salva.svg";
import discard from "../images/icons/pop-up-chiudi.svg";
import InputBase from "@mui/material/InputBase";
import {setVenue} from "../redux/session/actions";
import {getErrorMessage,put,VENUES} from "../services/Client";
import {useSnackbar} from "notistack";
import {TextItem} from "../components/TextItem";
import TextInput from "../components/forms/TextInput";

const useStyles = makeStyles(theme => ({
	page: {
		padding: theme.spacing(2)
	},
	container: {
		marginBottom: theme.spacing(2)
	},
	box: {
		padding: theme.spacing(1),
		margin: theme.spacing(1),
		borderBottom: `${theme.palette.secondary.main} 1px solid`,
		color: theme.palette.primary.dark,
		fontWeight: "bold",
		flexGrow: 1,
	},
	textField: {
		padding: theme.spacing(1),
		margin: theme.spacing(1),
		border: `${theme.palette.secondary.main} 1px solid`,
		color: theme.palette.primary.dark,
		fontWeight: "bold",
		flexGrow: 1
	}
}));

let devicePopUp = (name, ts) => `<div class="bubble">
									<div class="name">id</div><div>${name}</div>
								</div>`;


export default function Dashboard() {

	let [isEditing, setIsEditing] = useState(false);
	let venue = useSelector(store => store.session.venue);
	let [editVenue, setEditVenue] = useState({});
	let [zoom, setZoom] = useState(10);
	const dispatch = useDispatch();
	let classes = useStyles();
	usePrefetchContent(venue);
	const {enqueueSnackbar} = useSnackbar();

	let center, markers;
	if (venue.lat && venue.lng) {
		markers = [{
			lat: venue.lat,
			lng: venue.lng,
			data: devicePopUp(venue.name, "")
		}];
		center = {lat: venue.lat, lng: venue.lng};
	} else {
		markers = [];
		center = {
			lat: 40.967320,
			lng: 17.115670
		};
	}

	useEffect(() => {
		if(venue) {
			let newVenue = venue;
			delete newVenue.versions
			setEditVenue(newVenue);
		}
	}, [venue])

	const form = [
		{
			name: "name",
			label: "Name"
		},
		{
			name: "description",
			label: "Description"
		},
		{
			name: "address",
			label: "Address"
		},
		{
			name: "city",
			label: "City"
		},
	]

	const onChange = (name, value) => {
		let newVenue = Object.assign({}, editVenue, {[name]: value});
		setEditVenue(newVenue);
	}

	const saveEditVenue = () => {
		enqueueSnackbar("Saving...", {variant: "info"});
		put(VENUES,{body: editVenue, elem: venue.id})
			.then(() => {
				enqueueSnackbar("Saved", {variant: "success"});
				dispatch(setVenue(editVenue));
				setIsEditing(false);
			})
			.catch(e => enqueueSnackbar(getErrorMessage(e), {variant: "error"}));
	}

	const discardEditVenue = () => {
		setEditVenue(venue);
		setIsEditing(false);
	}

	const getIsEditingForm = () => {

		return <Grid container spacing={3} style={{padding: "0.5rem 1rem"}}>
			{
				form.map(f => <Grid item xs={12} sm={6} alignItems={"flex-end"} style={{display: "flex"}}>
					<TextInput value={editVenue[f.name]} onTextChange={(value) => onChange(f.name, value)}
							   label={f.label} color="dark"/>
				</Grid>)
			}
		</Grid>
	}

	return (
        <Paper className={classes.page}>
			<Grid container alignItems={"flex-end"} className={classes.container}>
				<Grid item xs={6} alignItems={"flex-end"} style={{display: "flex"}}>
				</Grid>
				<Grid item xs={6} alignItems={"flex-end"} style={{display: "flex", "justify-content": "flex-end"}} >
					{isEditing ?
						<Grid>
						<IconButton onClick={saveEditVenue} size="large">
							<img src={save} alt="save"/>
						</IconButton>
						<IconButton onClick={discardEditVenue} size="large">
							<img src={discard} alt="discard"/>
						</IconButton>
						</Grid>
						:
						<IconButton onClick={() => setIsEditing(true)} size="large">
							<img src={edit} alt="edit"/>
						</IconButton>
					}
				</Grid>
			</Grid>

			<Grid container alignItems={"flex-end"} className={classes.container}>
				{
					isEditing ?
						getIsEditingForm()
						:
						form.map(f => <Grid item xs={12} sm={6} alignItems={"flex-end"} style={{display: "flex"}}>
							<TextItem label={f.label}>
								{venue[f.name] || ""}
							</TextItem>
						</Grid>)
				}
			</Grid>
			<HereMap
				center={center}
				zoom={zoom}
				theme={"normal.day"}
				markers={markers}
				style={{
					width: "100%",
					height: "50vmin",
					background: "grey"
				}}
			/>
		</Paper>
    );
}
