import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import makeStyles from '@mui/styles/makeStyles';
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

import {useSnackbar} from 'notistack';
import {useQueryClient} from 'react-query';

import VenueModal from "../components/modals/VenueModal";

import {setVenue} from "../redux/session/actions";
import {isSuperUser} from "../services/PermissionManager";
import {useGetVenues} from "../services/ContentManager";
import {getErrorMessage, post, VENUES} from "../services/Client";
import {useContext} from "react";
import {UserContext} from "./App";

const useStyles = makeStyles((theme) => ({
	pill: {
		backgroundColor: theme.palette.primary.light,
		color: theme.palette.primary.dark,
		fontWeight: "bold",
		'&:hover': {
			backgroundColor: theme.palette.primary.dark,
			color: "white",
			cursor: "pointer"
		}
	},
	pillElementContainer: {
		padding: theme.spacing(1)
	},
	searchbar: {
		width: '100%',
		marginRight: theme.spacing(2)
	}
}));

function VenuePill({venue}) {
	let classes = useStyles();
	let [hover, setHover] = useState(false);
	let dispatch = useDispatch();
	return (
        <Grid container
                     spacing={2}
                     justifyContent={"space-between"}
                     alignItems={"center"}
                     key={venue.id}
                     onMouseOver={() => setHover(true)}
                     onMouseOut={() => setHover(false)}
                     className={classes.pill}
                     onClick={() => dispatch(setVenue(venue))}
        >
			<div className={classes.pillElementContainer}>
				<Avatar className={hover ? classes.iconHover : classes.icon}
						sx={{bgcolor: hover ? "primary.light" : "primary.dark"}}
						src={hover ? require("../images/icons/venue-generic-33.svg").default :
							require("../images/icons/venue-generic-over-33.svg").default}/>
			</div>
            <div className={classes.pillElementContainer}>{venue.name}</div>
            <div className={classes.pillElementContainer}>{venue.id}</div>
        </Grid>
    );
}

export default function VenueList() {

	let [search, setSearch] = useState();
	let [modal, setModal] = useState(false);
	let user = useContext(UserContext).data;
	const {enqueueSnackbar} = useSnackbar();
	const dispatch = useDispatch();
	let queryClient = useQueryClient();
	const classes = useStyles();

	const {venues, error, status} = useGetVenues();
	if (error) enqueueSnackbar(getErrorMessage(error), {variant: "error"});

	function showError(e) {
		enqueueSnackbar(getErrorMessage(e), {variant: "error"});
	}

	function saveVenue(venue) {
		post(VENUES, {body: venue})
			.then(() => queryClient.invalidateQueries("venues"))
			.catch(showError)
			.finally(() => setModal(null));
	}

	if (venues && venues.length === 1) {
		dispatch(setVenue(venues[0]));
	}

	let data = venues ? venues.slice() : [];
	if (search) {
		let searches = search.toLowerCase().split(' ');
		searches.forEach(s => data = data.filter(elem => elem.name.toLowerCase().includes(s) || (elem.description && elem.description.toLowerCase().includes(s)) || elem.id.toString().includes(s)));
	}
	data.sort((a, b) => b.id - a.id);

	return (
        <Grid container sx={{padding: "0.5rem"}} spacing={1}>
			<Grid container alignItems={"center"} spacing={2}>
				<Grid item xs>
					<TextField margin={"dense"} className={classes.searchbar} label="Search" variant="outlined"
					           value={search} onChange={({target}) => setSearch(target.value)}/>
				</Grid>
				{
					isSuperUser(user) &&
					<Grid item>
						<Button variant="contained" color="primary" disableElevation onClick={() => setModal(true)} sx={{borderRadius: 2}}>
							+
						</Button>
					</Grid>
				}

			</Grid>
			<Grid item xs={12}>
				{
					status === "loading" ?
						<Grid container justifyContent={"center"}><CircularProgress/></Grid> :
						<Grid container spacing={3} style={{marginTop: 0}}>
							{data.map(v => <Grid item sm={6} key={v.id}><VenuePill venue={v}/></Grid>)}
						</Grid>
				}
			</Grid>
			<VenueModal
				open={modal}
				onClose={() => setModal(false)}
				onSubmit={saveVenue}
			/>
		</Grid>
    );

}
