export const API = "API_ACTION";
export const API_START = "API_START";
export const API_END = "API_END";
export const ACCESS_DENIED = "ACCESS_DENIED";
export const API_ERROR = "API_ERROR";

export function apiAction({
	                   url = "",
	                   method = "GET",
	                   data = null,
	                   onSuccess = () => {},
	                   onFailure = null,
	                   label = "",
	                          headers={}
                   }) {
	return {
		type: API,
		payload: {
			url,
			method,
			data,
			onSuccess,
			onFailure,
			label,
			headers
		}
	};
}

export const apiStart = label => ({
	type: API_START,
	data: label
});

export const apiEnd = label => ({
	type: API_END,
	data: label
});

export const accessDenied = url => ({
	type: ACCESS_DENIED,
	data: {
		url
	}
});

export const apiError = error => ({
	type: API_ERROR,
	data: error
});
