import NTMSelect from "../../components/NTMSelect";
import {Grid} from "@mui/material";
import TextInput from "../../components/forms/TextInput";
import React from "react";
import MenuItem from "@mui/material/MenuItem";

export default function RenderFields({fields}){

    return <>
        {
            fields.map(field => {
                if(field.type === "searchSelect")
                    return <Grid item xs={field.xs} md={field.md} style={{padding: "16px"}}>
                        <NTMSelect
                            color={'primary'}
                            label={field.label}
                            items={field.options}
                            onChange={field.onChange}
                            value={field.value}
                        />
                    </Grid>

                if(field.type === "boolean")
                    return <Grid item xs={field.xs} md={field.md} style={{padding: "16px"}}>
                        <TextInput label={field.label} value={field.value.toLowerCase()} color="dark"
                                   type="select" select onTextChange={field.onChange}>
                            <MenuItem key="true" value="true">True</MenuItem>
                            <MenuItem key="false" value="false">False</MenuItem>
                        </TextInput>
                    </Grid>

                return <Grid item xs={field.xs} md={field.md} style={{padding: "16px"}}>
                    <TextInput
                        color="dark"
                        label={field.label}
                        value={field.value}
                        type={field.type}
                        onTextChange={value => field.onChange(value.trim())}
                        step={field.step} min={field.min}
                        error={field.error}
                    />
                </Grid>
            })
        }
    </>
}