import React,{useState} from "react";
import makeStyles from '@mui/styles/makeStyles';
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import {Visibility,VisibilityOff} from "@mui/icons-material";

export default function TextInput({label, value, onTextChange, required, error, readOnly, type = "text", step, min, max, noHelperText, multiline,
									  startAdornment, helperText, rows, select, InputLabelProps, showPasswordIcon, autoComplete = 'new-password',
									  InputProps, onClick, autoFocus, disabled, inputRef, inputProps, children, color = 'light', onKeyPress}) {

	const useStyles = makeStyles(theme => ({
		root: {
			'& label': {
				color: color === 'light' ? theme.palette.primary.light : theme.palette.primary.dark,
				fontStyle: "italic",
				'&.MuiInputLabel-shrink': {
					fontStyle: "normal"
				},
				'&.Mui-focused': {
					color: color === 'light' ? theme.palette.secondary.main : theme.palette.primary.main,
				},
				'&:hover': {
					color: color === 'light' ? theme.palette.secondary.main : theme.palette.primary.main,
				},
				'&.Mui-disabled': {
					color: "#a1a1a1"
				},
			},
			'& .MuiOutlinedInput-root': {
				borderRadius: 0,
				'& fieldset': {
					borderColor: color === 'light' ? theme.palette.primary.light : theme.palette.primary.dark,
				},
				'&:hover fieldset': {
					borderColor: color === 'light' ? theme.palette.secondary.main : theme.palette.primary.main,
				},
				'&.Mui-focused fieldset': {
					borderColor: color === 'light' ? theme.palette.secondary.main : theme.palette.primary.main,
					borderWidth: "3px"
				},
				'&.Mui-disabled fieldset': {
					borderColor: "#a1a1a1"
				},
			}
		},
	}));

	const classes = useStyles();
	let [showPassword, setShowPassword] = useState(false);

	const preventLoseFocus = (event) => {
		event.preventDefault()
	}

	return (
		<TextField
			variant="outlined"
			fullWidth
			classes={{root: classes.root}}
			label={label}
			value={value}
			onChange={({target}) => onTextChange(target.value)}
			required={required}
			multiline={multiline}
			error={error}
			helperText={
				!noHelperText && (
					<>
						{
							helperText &&
							<span className={classes.colorGrey}>{helperText}</span>
						} {helperText && <br/>} {error || ''}
					</>
				)
			}
			sx={{
				"& .Mui-disabled": {
					"-webkit-text-fill-color": "#a1a1a1"
				},
				"& .MuiOutlinedInput-input": {
					color: color === 'light' ? "primary.light" : "primary.dark",
					'&:hover': {
						color: color === 'light' ? "secondary.main" : "primary.main",
					}
				},
				"& .MuiInputBase-root": {
					margin: 0
				}
			}}
			inputProps={inputProps || {
				className: classes.input,
				readOnly,
				type: showPassword ? "text" : type,
				step, min, max,
				autoComplete: autoComplete,
			}}
			InputProps={InputProps || {
				endAdornment: (showPasswordIcon &&
					<InputAdornment position="end" sx={{cursor: 'pointer'}}>
						{showPassword ?
							<Visibility onClick={() => setShowPassword(false)} onMouseUp={preventLoseFocus}
										onMouseDown={preventLoseFocus} color={color === 'light' ? "secondary" : "inherit"}/> :
							<VisibilityOff onClick={() => setShowPassword(true)} onMouseUp={preventLoseFocus}
										   onMouseDown={preventLoseFocus} color={color === 'light' ? "secondary" : "inherit"}/>}
					</InputAdornment>),
				startAdornment
			}}
			rows={rows}
			select={select}
			InputLabelProps={InputLabelProps}
			onClick={onClick}
			autoFocus={autoFocus}
			disabled={disabled}
			inputRef={inputRef}
			onKeyPress={onKeyPress}
		>
			{children}
		</TextField>
	);
}
