import {Circle,Group,Layer,Line,Rect,Text} from "react-konva";
import React,{useEffect,useState} from "react";

export default function RoomsLayer({rooms, backgroundSize, ratio, scale, newRoom = null, setNewRoom, cursorPosition,
                                       onRoomClick, editRoom = {}, setEditRoom,onEditRoomAngleClick}) {

    let [lastCursorPosition, setLastCursorPosition] = useState({})
    let [editRoomCoordinates, setEditRoomCoordinates] = useState([])

    useEffect(() => {
        if(editRoom.id || editRoomCoordinates.length !== 0) {
            if(!editRoom.id) setEditRoomCoordinates([])
            else if(editRoom.id !== editRoomCoordinates.id && editRoom.coordinates)
                setEditRoomCoordinates(formatFromArrayToArrayOfCoordinates(editRoom.coordinates))
        }
    }, [editRoom])

    useEffect(() => {

        let newCursorPosition = {...cursorPosition};

        if(newRoom !== null && newCursorPosition) {
            newCursorPosition.x = Math.round(newCursorPosition.x)
            newCursorPosition.y = Math.round(newCursorPosition.y)

            //non permetto al cursore di uscire fuori dall'immagine
            if(newCursorPosition.x < 0) newCursorPosition.x = 0;
            if(newCursorPosition.y < 0) newCursorPosition.y = 0;
            if(newCursorPosition.x > backgroundSize.w) newCursorPosition.x = backgroundSize.w;
            if(newCursorPosition.y > backgroundSize.h) newCursorPosition.y = backgroundSize.h;

            if(newRoom?.length === 0) {
                let inside = false;
                rooms.map(({coordinates}) => {
                    let formattedRoom = formatFromArrayToArrayOfCoordinates(coordinates)
                    if(pointInsidePolygon(newCursorPosition, formattedRoom)) {
                        //se il puntatore è dentro questa stanza, non puo' essere dentro altre stanze
                        let intersectionPoint = findFirstIntersectionPointLineIntersectPolygon([[lastCursorPosition.x, lastCursorPosition.y], [newCursorPosition.x, newCursorPosition.y]], formattedRoom)
                        if(intersectionPoint) {
                            intersectionPoint.x = Math.round(intersectionPoint.x)
                            intersectionPoint.y = Math.round(intersectionPoint.y)

                            setLastCursorPosition(findExternalPoint(intersectionPoint,formattedRoom))
                        }
                        inside = true;
                    }
                })
                if(inside) return;
            }

            if(newRoom?.length !== 0) {

                //se la linea è quasi dritta, aiuto
                if(Math.abs(newCursorPosition.x - newRoom[newRoom.length - 2]) * ratio < 10) newCursorPosition.x = newRoom[newRoom.length - 2];
                if(Math.abs(newCursorPosition.y - newRoom[newRoom.length - 1]) * ratio < 10) newCursorPosition.y = newRoom[newRoom.length - 1];

                //array che andrà a contenere il punto di intersezione più vicino per ogni stanza
                let intersectionPoints = []

                rooms.map(({coordinates}) => {
                    let formattedRoom = formatFromArrayToArrayOfCoordinates(coordinates)

                    let intersection = findFirstIntersectionPointLineIntersectPolygon([[newRoom[newRoom.length -2], newRoom[newRoom.length -1]], [newCursorPosition.x, newCursorPosition.y]], formattedRoom)
                    if(intersection) intersectionPoints.push(intersection)
                })

                if(intersectionPoints.length > 0) {
                    let nearestIntersection;
                    if(intersectionPoints.length > 1) nearestIntersection = findNearestPointToPoints({x: newRoom[newRoom.length -2], y: newRoom[newRoom.length -1]}, intersectionPoints)
                    else nearestIntersection = intersectionPoints[0]

                    rooms.map(({coordinates}) => {
                        let formattedRoom = formatFromArrayToArrayOfCoordinates(coordinates)
                        if(pointInsidePolygon(newCursorPosition,formattedRoom)) {
                            nearestIntersection.x = Math.round(nearestIntersection.x)
                            nearestIntersection.y = Math.round(nearestIntersection.y)

                            setLastCursorPosition(findExternalPoint(nearestIntersection, formattedRoom))
                        }
                    })

                    return;
                }
            }

            if(newRoom?.length >= 6 &&
                Math.abs(newCursorPosition.x - newRoom[0]) * ratio < 10 &&
                Math.abs(newCursorPosition.y - newRoom[1]) * ratio < 10) {

                setLastCursorPosition({x : newRoom[ 0 ],y : newRoom[ 1 ]});

            }
            else setLastCursorPosition(newCursorPosition)
        }
    }, [cursorPosition])

    const onClick = () => {
        if(newRoom !== null) {
            setNewRoom(points => [...points,lastCursorPosition.x,lastCursorPosition.y])
        }
    }

    const onDragVertex = (indexPoint, event) => {
        //salvo la nuova posizione e resetto quella attuale, così posso gestirla in maniera controllata
        const newPosition = [Math.round(event.target.x() / ratio), Math.round(event.target.y() / ratio)];
        event.target.position({ x: editRoomCoordinates[indexPoint][0] * ratio, y: editRoomCoordinates[indexPoint][1] * ratio});
        
        let newEditRoom = [...editRoomCoordinates];
        newEditRoom[indexPoint] = newPosition
        setEditRoomCoordinates(newEditRoom)
        setEditRoom({...editRoom, coordinates: formatFromArrayOfCoordinatesToArray(newEditRoom)})
        onEditRoomAngleClick(indexPoint*2)
    }

    return <Layer onClick={onClick}>
        {rooms.sort((a, b) => Number(a.active) - Number(b.active)).map(r => {
            if(editRoom && editRoom.id === r.id) return;
            let colorLine = r.active ? "rgb(255,1,1)" : "rgb(10,104,137)"
            let colorFill = r.active ? "rgba(255,1,1,0.2)" : "rgb(10,104,137,0.1)"
            if(r.coordinates.length > 2)
                return <>
                    <Line
                        id={r.id}
                        points={r.coordinates.map(c => c * ratio)}
                        closed
                        stroke={colorLine}
                        fill={colorFill}
                        strokeWidth={2 / scale}
                        onCLick={() => onRoomClick(r)}
                    />
                    {editRoom.id && formatFromArrayToArrayOfCoordinates(r.coordinates).map(fc => {
                        return <CircleWithText ratio={ratio} scale={scale}
                                        coordinates={{x : fc[ 0 ], y : fc[ 1 ]}}/>
                    })}
                </>
            else {
                let cathetus1 = Math.abs(r.coordinates[ 0 ] - r.coordinates[ 2 ])
                let cathetus2 = Math.abs(r.coordinates[ 1 ] - r.coordinates[ 3 ])
                let radius = Math.sqrt(Math.pow(cathetus1, 2) + Math.pow(cathetus2, 2));
                return <Circle
                    id={r.id}
                    x={r.coordinates[ 0 ] * ratio}
                    y={r.coordinates[ 1 ] * ratio}
                    radius={radius * ratio}
                    stroke={colorLine}
                    fill={colorFill}
                    strokeWidth={2 / scale}
                />
            }
        })}
        {newRoom !== null && lastCursorPosition && <>
                <Circle
                    x={lastCursorPosition.x * ratio}
                    y={lastCursorPosition.y * ratio}
                    radius={3 / scale}
                    strokeWidth={3 / scale}
                    fill="#01FFDE"
                    stroke="#01FFDE"
                />
                {newRoom.length > 0 &&
                    <Line
                        points={[...newRoom.map(c => c * ratio), lastCursorPosition.x * ratio, lastCursorPosition.y * ratio]}
                        stroke="#01FFDE"
                        fill="#01FFDE"
                        strokeWidth={2 / scale}
                    />
                }
            </>
        }
        {newRoom !== null && cursorPosition && //cerchio trasparente per avere il layer sempre sotto il cursore e quindi permettere di cliccare sul layer
            <Circle
                x={cursorPosition.x * ratio}
                y={cursorPosition.y * ratio}
                radius={3 / scale}
                strokeWidth={3 / scale}
            />
        }
        {
            editRoomCoordinates?.map((coordinate, index) => {
                let nextIndex = editRoomCoordinates.length === index + 1 ? 0 : index + 1
                return <>
                    <Circle
                        id={index}
                        x={coordinate[0] * ratio}
                        y={coordinate[1] * ratio}
                        radius={4 / scale}
                        strokeWidth={3 / scale}
                        fill="#01FFDE"
                        stroke="#01FFDE"
                        draggable
                        onDragMove={e => onDragVertex(index, e)}
                        onDragStart={() => onEditRoomAngleClick(index * 2)}
                        onClick={() => onEditRoomAngleClick(index*2)}
                    />
                    <Line
                        id={index}
                        points={[
                            coordinate[0] * ratio, coordinate[1] * ratio,
                            editRoomCoordinates[nextIndex][0] * ratio, editRoomCoordinates[nextIndex][1] * ratio
                        ]}
                        stroke="#01FFDE"
                        fill="#01FFDE"
                        strokeWidth={2 / scale}
                    />
                </>
            })
        }
    </Layer>
}

function CircleWithText ({coordinates, scale, ratio}) {
    let [showText, setShowText] = useState(false)
    const textRef = React.useRef();
    const [size, setSize] = React.useState({ width: 0, height: 0 });

    React.useEffect(() => {
        if(showText){
            setSize({
                width : textRef.current.width() +15,
                height : textRef.current.height() +7
            });
        }
    }, [showText]);

    return <>
        {showText && <Group x={coordinates.x * ratio - 20} y={coordinates.y * ratio - 20}>
                <Rect
                    width={size.width}
                    height={size.height}
                    x={-5} y={-3}
                    fill={'#ffffff'}
                    stroke={'#000000'}
                    strokeWidth={2} cornerRadius={5}
                />
                <Text
                    ref={textRef}
                    text={`x: ${coordinates.x}, y: ${coordinates.y}`}
                    fontSize={10}
                    stroke={'#000000'}
                    strokeWidth={1}
                    align="center"
                />
            </Group>
        }
        <Circle
            x={coordinates.x * ratio}
            y={coordinates.y  * ratio}
            radius={3 / scale}
            strokeWidth={3 / scale}
            fill={"rgb(10,104,137)"}
            stroke={"rgb(10,104,137)"}
            onMouseEnter={() => setShowText(true)}
            onMouseLeave={() => setShowText(false)}
        />
    </>
}

/**
 * funzione che controlla se un punto è dentro un poligono
 * @param point type {x, y} example: {x: '3552', y: '4040'}
 * @param polygon example: [['3552', '4040'], ['6896', '4040'], ['6896', '6368'], ['3552', '6368']]
 * @returns {boolean} true: il punto è dentro il poligono, false: altrimenti, non ben definito quando il punto è sul bordo
 */
function pointInsidePolygon(point, polygon) {
    let inside = false;

    for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
        let xi = polygon[i][0], yi = polygon[i][1];
        let xj = polygon[j][0], yj = polygon[j][1];

        let intersect = ((yi > point.y) !== (yj > point.y))
            && (point.x < (xj - xi) * (point.y - yi) / (yj - yi) + xi);
        if (intersect) inside = !inside;
    }

    return inside;
}

/**
 * funzione utile a trovare il punto di intersezione di una linea a un poligono,
 * più vicino rispetto al primo punto della linea
 * @param line example: [['3552', '4040'], ['6896', '4040']]
 * @param polygon example: [['3552', '4040'], ['6896', '4040'], ['6896', '6368'], ['3552', '6368']]
 * @returns {null|*} null: nessuna intersezione trovata, {x, y} punto trovato
 */
function findFirstIntersectionPointLineIntersectPolygon(line, polygon) {
    //deve ritornare un array di punti che identificano tutti i punti di intersezione

    let lines = []
    for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
        lines.push([
            [polygon[i][0], polygon[i][1]],
            [polygon[j][0], polygon[j][1]]
        ])
    }
    //semplificabile riportando tutto nel for di sopra
    let intersectionPoints = []
    lines.forEach(l => {
        let intersection = lineIntersectLine(line[0][0], line[0][1], line[1][0], line[1][1], l[0][0], l[0][1], l[1][0], l[1][1])
        if(intersection) intersectionPoints.push(intersection)
    })

    //se non trova punti di intersezione ritorno null
    if(intersectionPoints.length === 0) return null;

    if(intersectionPoints.length > 1) {
        return findNearestPointToPoints({x: line[0][0], y: line[0][1]}, intersectionPoints)
    }

    return intersectionPoints[0]
}

function lineIntersectLine(x1, y1, x2, y2, x3, y3, x4, y4) {

    // Check if none of the lines are of length 0
    if ((x1 === x2 && y1 === y2) || (x3 === x4 && y3 === y4)) {
        return false
    }

    let denominator = ((y4 - y3) * (x2 - x1) - (x4 - x3) * (y2 - y1))

    // Lines are parallel
    if (denominator === 0) {
        return false
    }

    let ua = ((x4 - x3) * (y1 - y3) - (y4 - y3) * (x1 - x3)) / denominator
    let ub = ((x2 - x1) * (y1 - y3) - (y2 - y1) * (x1 - x3)) / denominator

    // is the intersection along the segments
    if (ua < 0 || ua > 1 || ub < 0 || ub > 1) {
        return false
    }

    // Return a object with the x and y coordinates of the intersection
    let x = x1 + ua * (x2 - x1)
    let y = y1 + ua * (y2 - y1)

    return {x, y}
}

/**
 * trova tra points il punto più vicino a point
 * @param point type {x, y} example: {x: '3552', y: '4040'}
 * @param points [{x, y}]
 * @returns type {x, y} punto trovato
 */
function findNearestPointToPoints(point, points) {
    let minDistance;
    let minPointIndex;

    for(let i = 0; i < points.length; i++) {

        let a = point.x - points[ i ].x;
        let b = point.y - points[ i ].y;
        let distance = Math.sqrt(a * a + b * b);

        if(!minDistance || distance < minDistance) {
            minDistance = distance;
            minPointIndex = i;
        }
    }

    return points[minPointIndex]
}

/**
 *
 * @param intersectionPoint type {x, y} example: {x: '3552', y: '4040'}
 * @param polygon example: [['3552', '4040'], ['6896', '4040'], ['6896', '6368'], ['3552', '6368']]
 */
function findExternalPoint(intersectionPoint, polygon){
    const yMinusInside = pointInsidePolygon({...intersectionPoint, y: intersectionPoint.y - 1}, polygon);
    const yPlusInside = pointInsidePolygon({...intersectionPoint, y: intersectionPoint.y + 1}, polygon);
    const xMinusInside = pointInsidePolygon({...intersectionPoint, x: intersectionPoint.x - 1}, polygon);
    const xPlusInside = pointInsidePolygon({...intersectionPoint, x: intersectionPoint.x + 1}, polygon);

    let considerY;
    let considerX;

    if(yMinusInside === yPlusInside) considerY = intersectionPoint.y;
    else if(yMinusInside && !yPlusInside) considerY = intersectionPoint.y + 1;
    else if(!yMinusInside && yPlusInside) considerY = intersectionPoint.y - 1;

    if(xMinusInside === xPlusInside) considerX = intersectionPoint.x;
    else if(xMinusInside && !xPlusInside) considerX = intersectionPoint.x + 1;
    else if(!xMinusInside && xPlusInside) considerX = intersectionPoint.x - 1;

    return {x: considerX, y: considerY}
}

/**
 * @param coordinates like [x1, y1, x2, y2, ..., xn, yn]
 * @returns {*[]} like [[x1,y1],[x2,y2],...,[xn,yn]]
 */
function formatFromArrayToArrayOfCoordinates(coordinates) {
    let formattedRoom = []
    for(let i = 0; i < coordinates.length/2; i++) {
        formattedRoom.push([coordinates[i*2], coordinates[(i*2)+1]])
    }
    return formattedRoom
}

/**
 * @param coordinates like [[x1,y1],[x2,y2],...,[xn,yn]]
 * @returns {*[]} like [x1, y1, x2, y2, ..., xn, yn]
 */
function formatFromArrayOfCoordinatesToArray(coordinates) {
    let room = []
    coordinates.map(c => {
        room.push(c[0], c[1])
    })
    return room
}