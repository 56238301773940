import React,{useContext,useState} from "react";
import {deleteElem,download,getErrorMessage,MAPS,post,put} from "../../services/Client";
import AddNewButton from "../../components/buttons/AddNewButton";
import {useSnackbar} from "notistack";
import MapModal from "../../components/modals/MapModal";
import ConfirmIconButton from "../../components/buttons/ConfirmIconButton";
import {useQueryClient} from "react-query";
import {useGetMaps} from "../../services/ContentManager";
import Can,{AbilityContext} from "../../services/Can";
import {UserContext} from "../App";
import NTMXGrid from "../../components/NTMXGrid";
import {Grid,Paper} from "@mui/material";
import HoverableIconButton from "../../components/hoverables/HoverableIconButton";
import MenuItem from "@mui/material/MenuItem";
import JSZip from "jszip";

export default function Maps({venue}) {

	let user = useContext(UserContext).data;
	let {maps} = useGetMaps();
	let [modal, setModal] = useState(null);
	let [selectedRows, setSelectedRows] = useState([])
	const {enqueueSnackbar} = useSnackbar();
	const ability = useContext(AbilityContext);
	let queryClient = useQueryClient();

	function saveMap(map) {
		enqueueSnackbar("Saving...", {variant: "info"});
		map.venue = venue.id;
		if (map.id) {
			put(MAPS, {
				body: map,
				elem: map.id
			}).then(() => {
				setModal(null)
				enqueueSnackbar("Saved", {variant: "success"});
				queryClient.invalidateQueries(["maps", {venue: venue.id}]);
			}).catch(e => enqueueSnackbar(getErrorMessage(e), {variant: "error"}));
		} else {
			post(MAPS, {
				body: map
			}).then(() => {
				setModal(null)
				enqueueSnackbar("Saved", {variant: "success"});
				queryClient.invalidateQueries(["maps", {venue: venue.id}]);
			}).catch(e => enqueueSnackbar(getErrorMessage(e), {variant: "error"}));
		}
	}

	function deleteMap(mapid) {
		enqueueSnackbar("Deleting...", {variant: "info"});
		deleteElem(MAPS, {
			elem: mapid
		}).then(() => {
			enqueueSnackbar("Deleted", {variant: "success"});
			queryClient.invalidateQueries(["maps", {venue: venue.id}]);
		}).catch(e => enqueueSnackbar(getErrorMessage(e), {variant: "error"}));
	}

	function downloadMap (map) {
		enqueueSnackbar("Downlaoding...", {variant: "info"});
		download(`${MAPS}/${map.id}/image`)
			.then(response => saveData(response.data, `${map.name}.png`))
			.catch(e => enqueueSnackbar(getErrorMessage(e), {variant: "error"}));
		download(`${MAPS}/${map.id}/tiles`)
			.then(response => saveData(response.data, `${map.name} tiles.zip`))
			.catch(e => enqueueSnackbar(getErrorMessage(e), {variant: "error"}));
	}

	const saveData = (data, fileName) => {
		let dataURL = window.URL.createObjectURL(data);
		let tempLink = document.createElement('a');
		tempLink.href = dataURL;
		tempLink.download = fileName;
		tempLink.click();
		window.URL.revokeObjectURL(dataURL);
	}

	const ExportZipMaps = () => {

		return <MenuItem
			onClick={() => {
				enqueueSnackbar("Downlaoding...", {variant: "info"});
				let allPromises = []
				let mapsName = []
				let consideringRows = selectedRows.length > 0 ? selectedRows : maps.map(m => m.id)
				consideringRows.map(mapId => {
					allPromises.push(download(`${MAPS}/${mapId}/image`))
					mapsName.push(maps.find(m => m.id === mapId).name)
				})
				let zip = new JSZip();
				Promise.allSettled(allPromises)
					.then(rs => {
						rs.filter(r => r.status === "fulfilled").map((response, i) => {
							zip.file(`${mapsName[i]}.png`,response.value.data,{base64 : true})
						})
						zip.generateAsync({type:"blob"}).then(function(content) {
							saveData(content, `${venue.name}.zip`);
						});
					})
			}}
		>
			Download png as Zip
		</MenuItem>
	};

	let columns = [
		{headerName: "Name", field: 'name', flex: 4},
		{headerName: "North", field: 'north', flex: 2},
		{headerName: "Scale", field: 'scale', flex: 2},
		{headerName: "Width", field: 'width', flex: 2, valueGetter: ({value}) => value ? value + " px" : "-"},
		{headerName: "Height", field: 'height', flex: 2, valueGetter: ({value}) => value ? value + " px" : "-"}
	];

	if(ability.can("write",venue.id + "","maps")) columns.push(
		{
			field: 'Actions',
			type: 'actions',
			width: 200,
			getActions: (params) => {
				return [
					<HoverableIconButton
						src={require("../../images/icons/tabella-download.svg").default}
						hoverSrc={require("../../images/icons/tabella-download-over.svg").default}
						onClick={() => downloadMap(params.row)}
					/>,
					<HoverableIconButton
						src={require("../../images/icons/pop-up-edit.svg").default}
						hoverSrc={require("../../images/icons/pop-up-edit-over.svg").default}
						onClick={() => setModal(params.row)}
					/>,
					<ConfirmIconButton
						item={{
							icon : require("../../images/icons/pop-up-delete.svg").default,
							iconHover : require("../../images/icons/pop-up-delete-over.svg").default
						}}
						title={"Delete?"}
						onConfirm={() => deleteMap(params.row.id)}
					/>
				]
			}
		}
	)

	return (
		<Paper>
			<NTMXGrid
				columns={columns}
				rows={maps.filter(m => m.floor !== -1)}
				rightButton={<Grid container justifyContent={"flex-end"}>
					<Can I="write" a={venue.id + ""} field="maps">
						<AddNewButton onClick={() => setModal({})}/>
					</Can>
				</Grid>
				}

				customExportComponent={ability.can("write",venue.id + "","maps") && <ExportZipMaps/>}
				checkboxSelection={ability.can("write",venue.id + "","maps")}
				onSelectionModelChange={(newSelection) => {
					setSelectedRows(newSelection);
				}}
				selectionModel={selectedRows || []}
			/>
			<MapModal open={!!modal} onClose={() => setModal(null)} defaultValue={modal}
			          onSubmit={saveMap}/>
		</Paper>
	);

}
