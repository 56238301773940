import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import ButtonBase from "@mui/material/ButtonBase";

const useStyles = makeStyles((theme) => ({
    box: {
        padding: theme.spacing(1),
        paddingBottom: "0.1rem",
        marginBottom: theme.spacing(1),
        flexGrow: 1,
    },
    languageFilled: {
        textAlign: "center",
        width: "3rem",
        color: theme.palette.secondary.main,
    },
    divisorFilled: {
        borderColor: theme.palette.secondary.main,
        borderLeft: "0.2rem solid"
    }
}));

export default function LanguageInputForPopup ({selectedLanguage,setLanguage, filledLanguages}) {
    const classes = useStyles();

    return(
            <Box xs={12} className={classes.box}>
                <Grid  item xs={12}>
                    <span className={classes.divisorFilled}/>
                    {filledLanguages.map((lan) =>
                        <>
                            <ButtonBase disableTouchRipple className={classes.languageFilled} style={(selectedLanguage === lan) ? {textDecoration: "underline"} : {}}
                                        onClick={() => setLanguage(lan)}>
                                {lan.toUpperCase()}
                            </ButtonBase>
                            <span className={classes.divisorFilled}/>
                        </>
                    )}
                </Grid>
            </Box>
    );
}