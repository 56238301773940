import React,{useContext,useEffect,useState} from "react";
import {useSelector} from "react-redux";

import {AbilityContext} from "../services/Can";
import {resources} from "../services/ability";

import FilterAndSearchBar from "../components/layout/FilterAndSearchBar";
import Gateways from "./Content/Gateways";
import AssetBeacons from "./Content/AssetBeacons";
import {Redirect,useHistory,useParams} from "react-router-dom";
import Maps from "./Content/Maps";
import Beacons from "./Content/Beacons";
import {TRACKING_TYPES} from "../services/ContentManager";
import Tracking from "./Monitoring/Tracking";

export default function Content({type}) {

	let {beacon} = useParams();
	let [selected, setSelected] = useState(0);
	let [selectedBeacon, setSelectedBeacon] = useState(null);
	const ability = useContext(AbilityContext);
	let venue = useSelector(store => store.session.venue);
	let history = useHistory();

	useEffect(() => {
		if(beacon){
			setSelectedBeacon(parseInt(beacon));
			setSelected(1);
			history.replace({pathname : "/content"});
		}
	}, []);

	let filters;

	if (type === "content") filters = getContentFilters();
	if (type === "monitoring") filters = getMonitoringFilters();

	function getContentFilters() {
		let filters = [];
		let id = 0;
		if (ability.can('read', venue.id + "", resources.MAPS)) {
			filters.push({
				id,
				name: "Maps",
				component: <Maps venue={venue}/>
			});
			id++;
		}
		if (ability.can('read', venue.id + "", resources.BEACONS)) {
			filters.push({
				id,
				name: "Beacons",
				component: <Beacons selectedBeacon={selectedBeacon}/>
			});
			id++;
		}

		if (ability.can('read', venue.id + "", resources.GATEWAYS)) {
			filters.push({
				id,
				name: "Gateways",
				component: <Gateways/>
			});
			id++;
		}
		if (ability.can('read', venue.id + "", resources.ASSET)) {
			filters.push({
				id,
				name: "Assets",
				component: <AssetBeacons/>
			});
			id++;
		}
		return filters;
	}

	function getMonitoringFilters() {
		let filters = [];
		let id = 0;
		if (ability.can('read', venue.id + "", resources.ASSET)) {
			filters.push({
				id,
				name: "Asset Tracking",
				component: <Tracking type={TRACKING_TYPES.ASSETS}/>
			});
			id++;
		}
		if(venue.id ===1541) {
			filters.push({
				id,
				name: "People Tracking",
				component: <Tracking type={TRACKING_TYPES.POSITIONS}/>
			});
			id++;
		}
		return filters;
	}

	if (!venue) return <Redirect to={"/"}/>;

	return (
		<div>
			<FilterAndSearchBar
				filters={filters}
				onFilter={setSelected}
				selected={selected}
			/>
			{filters.find(f => f.id === selected)?.component}
		</div>
	);

}
