import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {Link, useHistory} from "react-router-dom";
import clsx from "clsx";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import AppBar from "@mui/material/AppBar";
import makeStyles from '@mui/styles/makeStyles';
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import HoverableButton from "./HoverableButton";
import {setVenue} from "../../redux/session/actions";
import {useGetVenues} from "../../services/ContentManager";
import {useQueryClient} from "react-query";
import {UserContext} from "../../containers/App";
import {useContext} from "react";
import Grid from "@mui/material/Grid";
import {useTheme} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
	appBar: {
		backgroundColor: theme.palette.secondary.main,
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
	appBarShift: {
		marginLeft: drawerWidth,
		// width: `calc(100% - ${drawerWidth}px)`,
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create(
			['width', 'margin'], {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.enteringScreen,
			}),
	},
	menuButton: {
		marginRight: theme.spacing(4),
	},
	hide: {
		display: 'none',
	},
	toolbar: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		padding: theme.spacing(0, 1),
		// necessary for content to be below app bar
		...theme.mixins.toolbar,
	},
	venuesButton: {
		color: theme.palette.primary.contrastText,
		borderColor: theme.palette.primary.contrastText,
	}
}));

export default function Header({menuOpen, toggleMenu}) {
	const classes = useStyles();
	let user = useContext(UserContext);
	let venue = useSelector(store => store.session.venue);
	let {venues} = useGetVenues();
	let history = useHistory();
	let dispatch = useDispatch();
	let queryClient = useQueryClient();
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const theme = useTheme();
	const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

	const handleMenu = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const selectVenue = (v) => {
		handleClose();
		dispatch(setVenue(v));
		history.push("/");
	};
	return (
        <AppBar
			position="fixed"
			className={clsx(classes.appBar, {
				[classes.appBarShift]: menuOpen,
			})}
		>
			<Toolbar>
				<IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={() => toggleMenu(!menuOpen)}
                    edge="start"
                    className={clsx(classes.menuButton)}
                    size="large">
					<MenuIcon/>
				</IconButton>
				<Link to="/">
					<img style={{height: "2rem"}} alt={"home"} src={process.env.REACT_APP_ENVIRONMENT==='capodichino' ? require("../../images/logo-gam.jpg") : require("../../images/logo_orizzontale.png")}/>
				</Link>
				<div style={{flexGrow: 1}}/>
				{
					venue &&
					<Grid sx={{paddingRight: "1rem"}}>
						<Button
							aria-label="venues"
							aria-controls="menu-appbar"
							aria-haspopup="true"
							onClick={handleMenu}
							className={classes.venuesButton}
							variant="outlined"
						>
							{`${venue.id} - ${venue.name}`}
						</Button>
						{
							venues && venues.length > 1 &&
							<Menu
								id="menu-appbar"
								anchorEl={anchorEl}
								anchorOrigin={{
									horizontal: 'center',
								}}
								keepMounted
								transformOrigin={{
									vertical: 'top',
									horizontal: 'center',
								}}
								open={open}
								onClose={handleClose}
							>
								{
									venues.length < 6 && venues.map(v =>
										<MenuItem onClick={() => selectVenue(v)}>{`${v.id} - ${v.name}`}</MenuItem>
									)
								}
								<MenuItem onClick={() => selectVenue(null)}>Venue List</MenuItem>
							</Menu>
						}

					</Grid>
				}
				<Link to={"/profile/" + user.data.id} style={{textDecoration: "none"}}>
					<HoverableButton
						item={{
							name: isDesktop ? `${user.data.firstName} ${user.data.lastName}` : null,
							icon: require("../../images/icons/top_bar-profilo-over.svg").default,
							iconHover: require("../../images/icons/top_bar-profilo-30.svg").default,
						}}
					/>
				</Link>
				<HoverableButton
					item={{
						name: isDesktop ? "LogOut" : null,
						icon: require("../../images/icons/top_bar-log-out.svg").default,
						iconHover: require("../../images/icons/top_bar-log-out-over.svg").default,
					}}
					onClick={() => {
						user.onLogout();
						queryClient.removeQueries("venues");
						queryClient.removeQueries("maps");
					}}
				/>
			</Toolbar>
		</AppBar>
    );
}
