import React,{useEffect,useState} from "react";
import HoverableIconButton from "../hoverables/HoverableIconButton";
import {Grid} from "@mui/material";
import {Link} from "react-router-dom";

import poiIcon from "../../images/icons/on-map-poi.svg";
import move from "../../images/icons/pop-up-sposta.svg";
import moveHover from "../../images/icons/pop-up-sposta-over.svg";
import edit from "../../images/icons/pop-up-edit.svg";
import editHover from "../../images/icons/pop-up-edit-over.svg";
import deleteIcon from "../../images/icons/pop-up-delete.svg";
import deleteIconHover from "../../images/icons/pop-up-delete-over.svg";
import list from "../../images/icons/pop-up-vedi-in-tabella.svg";
import listHover from "../../images/icons/pop-up-vedi-in-tabella-over.svg";
import ConfirmIconButton from "../buttons/ConfirmIconButton";
import TextBoxForPopup from "./TextBoxForPopup";
import BasePopup from "./BasePopup";
import LanguageInputForPopup from "./LanguageInputForPopup";
import Can from "../../services/Can";
import {useSelector} from "react-redux";

export default function PoiPopup ({poi, popupPosition, onMove, onEdit, onDelete}) {
    let [language, setLanguage] = useState('en');
    let venue = useSelector(store => store.session.venue);
    let filledLanguages = [];

    useEffect(() => {
        (poi.descriptions && poi.descriptions.forEach((item) => item.name !== "" && filledLanguages.push(item.language)));
        filledLanguages.sort((a,b) => {
            let map = {'en':-3,'it':-2,'fr':-1, 'de': 0};
            return map[a] - map[b];
        });
        setLanguage(filledLanguages[0]);
    }, [poi]);

    let consideringDescription = (poi.descriptions && poi.descriptions.find((e) => e.language === language)) || {};
    (poi.descriptions && poi.descriptions.forEach((item) => item.name !== "" && filledLanguages.push(item.language)));
    filledLanguages.sort((a,b) => {
        let map = {'en':-3,'it':-2,'fr':-1, 'de': 0};
        return map[a] - map[b];
    });

    return(
        <BasePopup popupPosition={popupPosition} header={
            <>
                <Grid item>
                    <HoverableIconButton src={poiIcon} hoverSrc={poiIcon}/>
                </Grid>
                <Grid item>
                    <Can I="write" a={venue.id + ""} field="poi">
                        {onMove && <HoverableIconButton src={move} hoverSrc={moveHover} onClick={onMove}/>}
                        {onEdit && <HoverableIconButton src={edit} hoverSrc={editHover} onClick={onEdit}/>}
                        {onDelete && <ConfirmIconButton item={{icon : deleteIcon,iconHover : deleteIconHover}}
                                                        text="Are you sure you want to permanently remove the selected Point of Interest?"
                                                        title="Confirm?" onConfirm={onDelete}/>}
                    </Can>
                    <Link to={"/content"}><HoverableIconButton src={list} hoverSrc={listHover}/></Link>
                </Grid>
            </>
        }>
            <LanguageInputForPopup selectedLanguage={language} setLanguage={setLanguage} filledLanguages={filledLanguages}/>
            <TextBoxForPopup element={consideringDescription.name} label="Name"/>
            <TextBoxForPopup element={consideringDescription.description} label="Description"/>
        </BasePopup>
    );
}