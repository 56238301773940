import React, {useState} from "react";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import {Link, useLocation} from "react-router-dom";
import Icon from "@mui/material/Icon";
import makeStyles from '@mui/styles/makeStyles';
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		justifyContent: "center",
		padding:theme.spacing(2),
		color:"white",
		'&:hover': {
			color:theme.palette.secondary.main,
			backgroundColor: theme.palette.primary.main
		}
	},
	selected: {
		backgroundColor: theme.palette.secondary.main,
		'&:hover': {
			backgroundColor: theme.palette.primary.main
		}
	},
	imageIcon: {
		height: '100%'
	},
	iconRoot: {
		display: "flex"
	},
	imageText: {
		margin:0,
		paddingLeft: "1rem",
	}
}));

export default function HoverableListItem({item, open}) {

	let classes = useStyles();
	let [hover, setHover] = useState(false);
	let location = useLocation();
	let selected = location.pathname === item.url;
	let icon;
	if(hover) {
		icon = item.iconHover;
	} else if(selected) {
		icon = item.iconSelected;
	} else {
		icon = item.icon;
	}

	return (
		<Link to={item.url} style={{textDecoration: "none"}}>
			<ListItem
				button
				className={clsx(classes.root, {[classes.selected]:selected})}
				onMouseOver={() => setHover(true) }
				onMouseOut={() => setHover(false) }
			>
				<Icon classes={{root: classes.iconRoot}}>
					<img alt={item.name} className={classes.imageIcon} src={icon} onMouseOver={() => setHover(true) }/>
				</Icon>
				{
					open && <ListItemText className={classes.imageText}>{item.name}</ListItemText>
				}
			</ListItem>
		</Link>

	);
}
