import React, {useState} from "react";
import Icon from "@mui/material/Icon";
import makeStyles from '@mui/styles/makeStyles';
import clsx from "clsx";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

const useStyles = makeStyles(theme => ({
	root: {
		color:"white",
		display: "flex",
		justifyContent: "center",
		padding:theme.spacing(2),
		transition: "all 0s",
		'&:hover': {
			color: theme.palette.primary.main,
			transition: "all 0s"
		}
	},
	imageIcon: {
		height: '100%'
	},
	iconRoot: {
		display: "flex"
	},
	imageText: {
		margin:0,
		paddingLeft: "1rem",
	}
}));

export default function HoverableButton({item, onClick, disabled, classes={}}) {

	let innerClasses = useStyles();
	let [hover, setHover] = useState(false);
	let icon;

	if(disabled) icon = item.icon; //todo deve diventare icona disabilitata
	else if(hover) icon = item.iconHover;
	else icon = item.icon;

	return (
		<Button
			className={classes.root || innerClasses.root}
			onMouseOver={() => setHover(true) }
			onMouseOut={() => setHover(false) }
			onClick={onClick}
			disabled={disabled}
		>
			<Icon classes={{root: innerClasses.iconRoot}}>
				<img alt={item.name} className={innerClasses.imageIcon} src={icon} onMouseOver={() => setHover(true) }/>
			</Icon>
			<Typography className={clsx(innerClasses.imageText,classes.text)}>{item.name}</Typography>
		</Button>
	);
}
