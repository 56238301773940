import {useQueryClient, useQuery} from "react-query";
import {
	get,
	MAPS,
	VENUES,
	USERS,
	BEACONS,
	POI,
	PATHS,
	EVENTS,
	GATEWAYS,
	ASSETS_DETECTIONS,
	ASSETS,
	ROOMS,
	APPS,
	SETTINGS
} from "./Client";
import {useSelector} from "react-redux";

export function useGetGateways() {
	let venue = useSelector(store => store.session.venue);
	const {status, data, error} = useQuery(['gateways', {venue: venue.id}], () =>
		get(GATEWAYS, {params: {venue: venue.id}})
	);
	return {status, gateways: data || [], error};
}

export function useGetPOI() {
	let venue = useSelector(store => store.session.venue);
	const {status, data, error} = useQuery(['poi', {venue: venue.id}], () =>
		get(POI, {params: {venue: venue.id}})
	);
	return {status, poi: data || [], error};
}

export function useGetPaths() {
	let venue = useSelector(store => store.session.venue);
	const {status, data, error} = useQuery(['paths', {venue: venue.id}], () =>
		get(PATHS, {params: {venue: venue.id}})
	);
	return {status, paths: data || [], error};
}

export function useGetEvents() {
	let venue = useSelector(store => store.session.venue);
	const {status, data, error} = useQuery(['events', {venue: venue.id}], () =>
		get(EVENTS, {params: {venue: venue.id}})
	);
	return {status, events: data || [], error};
}

export function useGetAssets(vid) {
	let venue = useSelector(store => store.session.venue);
	const {status, data, error} = useQuery(['assets', {venue: venue?.id || vid}], () =>
		get(ASSETS, {params: {venue: venue?.id || vid}})
	);
	return {status, assets: data || [], error};
}

export const TRACKING_TYPES = {
	ASSETS: "assets",
	POSITIONS: "positions"
}

export function useGetPositions(mid, type, refetchInterval = false, t=15) {
	const {status, data, error} = useQuery(["realtime", type,  {map: mid}], () =>
		mid && get(`${type}/${mid}/snapshot`, {params:{t}}), {refetchInterval}
	);
	return {status, assets: data || [], error};
}

export function useGetVisits(mid, type, refetchInterval = false, t=15) {
	const {status, data, error} = useQuery(["visits", type, {map: mid}], () =>
		mid && get(`visits/${type}/${mid}/snapshot`, {params:{t}}), {refetchInterval}
	);
	return {status, visits: data || [], error};
}

export function useGetMaps() {
	let venue = useSelector(store => store.session.venue);
	const {status, data, error} = useQuery(["maps", {venue: venue.id}], () =>
		get(MAPS, {params: {venue: venue.id}})
	);
	return {status, maps: data || [], error};
}

export function useGetRooms(mid) {
	const {status, data, error} = useQuery([MAPS, {map: mid}, ROOMS], () =>
		mid && get(`${MAPS}/${mid}/${ROOMS}`), {staleTime: Infinity});
	return {status, rooms: data || [], error};
}

export function useGetBeacons() {
	let venue = useSelector(store => store.session.venue);
	const {status, data, error} = useQuery(["beacons", {venue: venue.id}], () =>
		get(BEACONS, {params: {venue: venue.id}})
	);
	return {status, beacons: data || [], error};
}

export function useGetSettings() {
	let venue = useSelector(store => store.session.venue);
	const {status, data, error} = useQuery([SETTINGS, {venue: venue.id}], () =>
		get(`${VENUES}/${venue.id}/${SETTINGS}`)
	);
	return {status, settings: data || [], error};
}

export function useGetUsers(venue) {
	const {status, data, error} = useQuery(["users", {venue: venue.id}], () =>
		get(USERS, {params: {venue: venue.id}})
	);
	return {status, users: data || [], error};
}

export function useGetUsersApps(uid) {
	const {status, data, error} = useQuery([APPS, {"user": uid}], () =>
		get(APPS, {params: {user: uid}})
	);
	return {status, apps: data || [], error};
}

export function useGetVenues() {
	const {status, data, error} = useQuery("venues", () => get(VENUES));
	return {status, venues: data || [], error};
}

export function usePrefetchContent() {
	let queryClient = useQueryClient();
	let venue = useSelector(store => store.session.venue);
	venue && queryClient.prefetchQuery(["maps", {venue: venue.id}], () =>
		get(MAPS, {params: {venue: venue.id}})
	);
	queryClient.prefetchQuery("venues", () => get(VENUES));

}
