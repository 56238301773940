import React, {useEffect, useState} from "react";
import BaseModal from "../../components/modals/BaseModal";
import {UserPermissionTable} from "./UserPermissionTable";
import {useDispatch} from "react-redux";
import {saveUser} from "../../redux/users/actions";
import {deleteElem, getErrorMessage, USERS} from "../../services/Client";
import {useQueryClient} from "react-query";

export default function AuthorizationsModal({open, onClose, defaultUser, venue}) {

	let [user, setUser] = useState(Object.assign({}, defaultUser));
	let dispatch = useDispatch();
	let queryClient = useQueryClient();

	function onChange(name, value) {
		setUser(user => {
			return {...user,[ name ] : value}
		});
	}

	function submit() {
		dispatch(saveUser(user));
		queryClient.invalidateQueries(["users", {venue: venue.id}]);
		onClose();
	}

	function revokeAll() {
		deleteElem(`${USERS}/${defaultUser.id}/authorizations/${venue.id}`)
			.then(() => {
				queryClient.invalidateQueries(["users", {venue: venue.id}]);
				onClose();
			})
			.catch(e => console.log(getErrorMessage(e)));
	}

	return (
		<BaseModal open={open} onClose={onClose} onSave={submit}>
			<UserPermissionTable
				user={user}
				venue={venue}
				onChange={value => onChange("authorizations", value)}
				onRevokeAll={defaultUser ? revokeAll : null}
			/>
		</BaseModal>
	);
}
