import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import Box from "@mui/material/Box";
import {Grid} from "@mui/material";
import Battery from "./Battery";

const useStyles = makeStyles((theme) => ({
    label: {
        padding: theme.spacing(1),
        paddingBottom: "0.1rem",
        fontWeight: "bold",
        flexGrow: 1,
    }
}));

export default function BatteryForPopup ({batteryLevel}) {
    const classes = useStyles();

    return (
        <Grid container xs={7} alignItems={"space-around"}>
            <Box className={classes.label}>Battery level: {batteryLevel || "-"}%</Box>
            <Battery batteryLevel={batteryLevel}/>
        </Grid>
    );
}