import BasePopup from "./BasePopup";
import {Grid} from "@mui/material";
import HoverableIconButton from "../hoverables/HoverableIconButton";
import pathsIcon from "../../images/icons/on-map-paths-popup.svg";
import move from "../../images/icons/pop-up-sposta.svg";
import moveHover from "../../images/icons/pop-up-sposta-over.svg";
import ConfirmIconButton from "../buttons/ConfirmIconButton";
import deleteIcon from "../../images/icons/pop-up-delete.svg";
import deleteIconHover from "../../images/icons/pop-up-delete-over.svg";
import React from "react";
import edit from "../../images/icons/pop-up-edit.svg";
import editHover from "../../images/icons/pop-up-edit-over.svg";

export default function RoomPopup({roomName, popupPosition, onEditRoom, onEditName, onDelete}) {

    return(
        <BasePopup popupPosition={popupPosition} header={
            <>
                <Grid item>
                    <HoverableIconButton src={pathsIcon} hoverSrc={pathsIcon}/>
                    {roomName}
                </Grid>
                <Grid item>
                        {onEditRoom && <HoverableIconButton src={move} hoverSrc={moveHover} onClick={onEditRoom}/>}
                        {onEditName && <HoverableIconButton src={edit} hoverSrc={editHover} onClick={onEditName}/>}
                        {onDelete && <ConfirmIconButton item={{icon : deleteIcon,iconHover : deleteIconHover}}
                                                        text={"Are you sure you want to permanently remove the selected room?"}
                                                        title="Confirm?" onConfirm={onDelete}/>}
                </Grid>
            </>
        }/>
    );
}