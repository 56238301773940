import {Grid} from "@mui/material";
import RenderFields from "./RenderFields";

export default function Generals({values, setValues}) {

    const onChange = (name, value) => {
        let newValues = {...values}
        newValues[name] = value
        setValues(newValues);
    }

    const fields = [
        {
            type: "searchSelect",
            label: "Timezone",
            value: values.timezone,
            onChange: (newValue) => onChange("timezone", newValue),
            options: Intl.supportedValuesOf('timeZone').map(l => ({value: l, text: l})),
            xs: 12
        }
    ]

    return <Grid container spacing={4} style={{margin: 0, width: "100%", marginBottom: "0.5rem"}}>
        <RenderFields fields={fields}/>
    </Grid>
}