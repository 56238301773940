import {
    Button,
    ClickAwayListener,
    FormControl,
    Grid,
    Grow,
    InputAdornment,
    MenuItem,
    MenuList,
    Paper,
    Popper
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import React, {useEffect, useRef, useState} from "react";
import TextInput from "./forms/TextInput";
import {ArrowDropDown, ArrowDropUp, Close} from "@mui/icons-material";

const useStyles = (isArray) => makeStyles(theme => ({
    root: {
        width: '100%',
    },
    marginRight: {
        marginRight: theme.spacing(2),
    },
    selected: {
        backgroundColor: theme.palette.secondary.light
    },
    input: {
        '& .MuiInputBase-input': {
            cursor: 'pointer',
            color: theme.palette.text.primary,
        },
        '& input': isArray && //se è un array allora nascondo l'input per mostrare i pulsanti della multiselezione
            {
                visibility: 'hidden',
                width: 0
            },
    },
    search: {
        marginTop: theme.spacing(1)
    },
    item: {
        padding: theme.spacing(0.5),
        cursor: 'pointer'
    },
    paper: {
        padding: theme.spacing(0.5),
        backgroundColor: theme.palette.secondary.light
    },
    close: {fontSize: '1rem'},
    list: {
        maxHeight: '50vh',
        overflowY: 'hidden',
        overflowX: 'hidden',
        textOverflow: 'ellipsis',
    },
    multiselect: {
        marginTop: theme.spacing(0.5),
        marginBottom: theme.spacing(0.5)
    }
}))();

//elemento della multiselect
function Item({onClick, children}) {
    const classes = useStyles();
    return (
        <Grid item className={classes.item}>
            <Paper
                onClick={(e) => {
                    e.stopPropagation();
                    onClick()
                }} className={classes.paper}
            >
                <Grid container direction={'row'} alignItems={'center'} alignContent={'center'}>
                    <Close className={classes.close}/>
                    {children}
                </Grid>
            </Paper>
        </Grid>
    );
}

/**
 * select con opzione di ricerca
 * @param label
 * @param value {string | Array.<string>} impostato come array viene considerata come selezione multipla
 * @param items {Array.<{value:string, text:string}>} array di elementi
 * @param onChange
 * @param onDelete callback se è multiselect e si elimina una selezione
 * @param maxItemsSize dimesione massima degli elementi della select mostrati
 * @param required
 * @param error
 * @param color
 * @param disabled
 * @param alwaysOpen
 * @returns {JSX.Element}
 * @constructor
 */
export default function NTMSelect({
                                      label,
                                      value,
                                      items,
                                      onChange,
                                      onDelete,
                                      maxItemsSize = 20,
                                      required,
                                      error,
                                      color = "primary",
                                      disabled,
                                      alwaysOpen = false
                                  }) {
    //value può essere un array nel caso di selezione multipla
    let isArray = value instanceof Array;

    const classes = useStyles(isArray);
    const [open, setOpen] = useState(alwaysOpen);

    const [searched, setSearched] = useState([]);
    useEffect(() => {
        setSearched(() => items.slice(0, maxItemsSize));
    }, [items]);

    const anchorRef = React.useRef(null);

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(!alwaysOpen && false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(!alwaysOpen && false);
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    //se è singola allora value sarà una stringa altrimenti sarà un array di items
    let values = [];
    let text = '';
    if (isArray)
        values = items.filter(i => !!value.find(v => v === i.value));
    else
        text = (items.slice().find(i => i.value === value) || {}).text

    const compareFn = (a,b) => {
        if(a.text.length < b.text.length) return -1;
        if(a.text.length > b.text.length) return 1;
        return 0;
    }

    return (
        <FormControl variant="outlined" className={classes.root}>
            <TextInput
                label={label}
                value={text || ''}
                readOnly
                required={required}
                error={error}
                InputProps={{
                    endAdornment:
                        <InputAdornment position="end" style={{cursor: 'pointer'}}> {open ? <ArrowDropUp/> :
                            <ArrowDropDown/>} </InputAdornment>,
                    startAdornment:
                        isArray &&
                        <Grid container direction={'row'} className={classes.multiselect}>
                            {
                                values.map((item) => <Item key={item.value}
                                                           onClick={() => onDelete(item.value)}>{item.text}</Item>)
                            }
                        </Grid>
                }}
                color={color}
                onClick={() => {
                    if (!disabled && !alwaysOpen)
                        setOpen((open) => !open);
                }}
                className={classes.input}
                disabled={disabled}
            />
            {/* usato solo per collegare il menù a tendina */}
            <Button
                ref={anchorRef}
                style={{visibility: 'hidden', height: 0, margin: 0, padding: 0}}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
            />
            <Popper open={alwaysOpen || open} anchorEl={anchorRef.current} role={undefined} disablePortal
                    style={{width: '100%', zIndex: 100, marginTop: -7}}>
                {({TransitionProps, placement}) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                    >
                        <ClickAwayListener onClickAway={handleClose}>
                            <Paper style={{marginTop: placement !== 'bottom' && '5rem'}}>
                                <MenuItem>
                                    <TextInput
                                        className={classes.search}
                                        label='Search'
                                        type="text"
                                        color={'primary'}
                                        onTextChange={(text) => {
                                            if(text !== "") {
                                                setSearched(() => items.filter(s => !text || s.text.toLowerCase().includes(text.toLowerCase()))
                                                    .sort(compareFn).slice(0,maxItemsSize))
                                            } else setSearched(() => items.slice(0,maxItemsSize))
                                        }}
                                        autoFocus={open}
                                    />
                                </MenuItem>
                                <MenuList id="menu-list-grow" onKeyDown={handleListKeyDown} className={classes.list} style={{maxHeight:'35vh', overflowY:'auto'}}>
                                    {
                                        searched.map(i =>
                                            <MenuItem
                                                className={(isArray ? values.find(v => v.value === i.value) : value === i.value) ? classes.selected : ''}
                                                key={i.value}
                                                onClick={(e) => {
                                                    onChange(i.value);
                                                    !isArray && handleClose(e);
                                                }}
                                            >
                                                {i.text}
                                            </MenuItem>
                                        )
                                    }
                                </MenuList>
                            </Paper>
                        </ClickAwayListener>
                    </Grow>
                )}
            </Popper>

        </FormControl>
    );
}