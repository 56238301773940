import React from "react";
import NTMXGrid from "../../components/NTMXGrid";
import Paper from "@mui/material/Paper";
import {useGetGateways,useGetMaps} from "../../services/ContentManager";
import Link from "@mui/material/Link";
import {resources} from "../../services/ability";
import {useHistory} from "react-router-dom";

export default function Gateways() {

	let {maps = []} = useGetMaps();
	let {gateways} = useGetGateways();
	let history = useHistory();

	function handleClick(gateway) {
		history.push(`maps/${gateway.map}/${resources.GATEWAYS}/${gateway.id}`);
	}

	let lookup = {};
	maps.map(m => {
		lookup [m.id] = m.name;
	})

	return (
		<Paper>
			<NTMXGrid
				columns={[
					{headerName: "Serial", field: 'serial', flex: 1},
					{headerName: "Label", field: 'label', flex: 1},
					{headerName: "Timezone", field: 'timezone', flex: 1},
					{
						headerName: "Position", field: 'map', flex: 1,
						renderCell: params =>
							<Link onClick={() => handleClick(params.row)} underline="hover">
								{lookup[params.value]}
							</Link>,
					},
				]}
				rows={gateways}
			/>
		</Paper>
	);

}
