import BasePopup from "./BasePopup";
import {Grid} from "@mui/material";
import TextInput from "../forms/TextInput";
import React from "react";

export default function AngleCoordinatesPopup({x, y, onChange}) {
    return <BasePopup popupPosition={"left"} header={
        <Grid container spacing={4} style={{padding: "1rem"}}>
            <Grid item xs={12}>
                <TextInput required label={"X"} type="number"
                           value={x} onTextChange={value => onChange("x", value.trim())}/>
            </Grid>
            <Grid item xs={12}>
                <TextInput required label={"Y"} type="number" step={1}
                           value={y} onTextChange={value => onChange("y", value.trim())}/>
            </Grid>
        </Grid>
    }/>
}