import React from "react";
import BasePopup from "./BasePopup";
import {Grid} from "@mui/material";
import HoverableIconButton from "../hoverables/HoverableIconButton";
import ConfirmIconButton from "../buttons/ConfirmIconButton";
import pathsIcon from "../../images/icons/on-map-paths-popup.svg";
import move from "../../images/icons/pop-up-sposta.svg";
import moveHover from "../../images/icons/pop-up-sposta-over.svg";
import linkNode from "../../images/icons/pop-up-link-node-path.svg";
import linkNodeHover from "../../images/icons/pop-up-link-node-path-over.svg";
import linkNodeFloor from "../../images/icons/pop-up-link-node-path-floor.svg";
import linkNodeFloorHover from "../../images/icons/pop-up-link-node-path-floor-over.svg";
import deleteIcon from "../../images/icons/pop-up-delete.svg";
import deleteIconHover from "../../images/icons/pop-up-delete-over.svg";
import {useSelector} from "react-redux";
import Can from "../../services/Can";

export default function GatewayPopup ({elem, title, popupPosition, mapsNumber, onMove, onLink, onLinkFloor, onDelete}) {

    let venue = useSelector(store => store.session.venue);

    let deleteText = "Are you sure you want to permanently remove the selected ";
    if(title === "Node") deleteText += "node and associated links?";
    if(title === "Link") deleteText += "link?";

    return(
        <BasePopup popupPosition={popupPosition} header={
    <>
        <Grid item>
            <HoverableIconButton src={pathsIcon} hoverSrc={pathsIcon}/>
            {title} {elem.id}
        </Grid>
        <Grid item>
            <Can I="write" a={venue.id + ""} field="paths">
                {onLink && <HoverableIconButton src={linkNode} hoverSrc={linkNodeHover} onClick={onLink}/>}
                {onLinkFloor && mapsNumber > 1 && <HoverableIconButton src={linkNodeFloor} hoverSrc={linkNodeFloorHover} onClick={onLinkFloor}/>}
                {onMove && <HoverableIconButton src={move} hoverSrc={moveHover} onClick={onMove}/>}
                {onDelete && <ConfirmIconButton item={{icon : deleteIcon,iconHover : deleteIconHover}}
                                                text={deleteText}
                                                title="Confirm?" onConfirm={onDelete}/>}
            </Can>
        </Grid>
    </>
}/>
    );
}