import {useParams} from "react-router-dom";
import {useGetUsersApps} from "../../services/ContentManager";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import {Card,CardActionArea,CardContent,IconButton} from "@mui/material";
import React,{useState} from "react";
import {makeStyles} from "@mui/styles";
import {Visibility,VisibilityOff} from "@mui/icons-material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {useSnackbar} from "notistack";
import ConfirmIconButton from "../../components/buttons/ConfirmIconButton";
import deleteIcon from "../../images/icons/pop-up-delete.svg";
import deleteIconHover from "../../images/icons/pop-up-delete-over.svg";
import {APPS,deleteElem,getErrorMessage,post} from "../../services/Client";
import {useQueryClient} from "react-query";
import TextInput from "../../components/forms/TextInput";
import BaseModal from "../../components/modals/BaseModal";

const useStyles = makeStyles(theme => ({
    card: {
        backgroundColor : theme.palette.primary.light,
        margin: "0.7rem"
    }
}));

export default function ProfileApps() {

    const { id } = useParams();
    let { apps } = useGetUsersApps(id);

    let sdkApp = apps.find(a => a.type.toLowerCase() === "sdk")
    let webApp = apps.find(a => a.type.toLowerCase() === "api")

    return <>
        <Typography variant="h5" sx={{padding: "0.5rem 0rem"}}>
            Apps
        </Typography>
        <Grid container>
            {
                sdkApp ?
                    <AppCard app={sdkApp} type="sdk"/>
                    : <AddCard type="sdk"/>
            }
            {
                webApp ?
                    <AppCard app={webApp} type="api"/>
                    : <AddCard type="api"/>
            }
        </Grid>
    </>
}

function AppCard({app, type}) {

    const { id } = useParams();
    let classes = useStyles();
    let [showKey, setShowKey] = useState(false);
    const {enqueueSnackbar} = useSnackbar();
    let queryClient = useQueryClient();

    const deleteApp = () => {
        enqueueSnackbar("Deleting...", {variant: "info"});
        deleteElem(`${APPS}/${app.id}`)
            .then(() => enqueueSnackbar("Deleted",{variant : "success"}))
            .catch(e => enqueueSnackbar(getErrorMessage(e), {variant: "error"}))
            .finally(() => queryClient.invalidateQueries([APPS, {"user": id}]));
    }

    return <Grid item xs={6}>
        <Card className={classes.card}>
            <CardContent>
                <Grid container justifyContent="space-between">
                    <Grid item>
                        <Typography gutterBottom variant="h5" component="h2">
                            {type.toUpperCase()}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <ConfirmIconButton item={{icon : deleteIcon, iconHover : deleteIconHover}}
                                           text="Do you really want to delete the app? You can recreate it later."
                                           title="Confirm" onConfirm={deleteApp}/>
                    </Grid>
                </Grid>
                <Grid container spacing={1} alignItems="center">
                    <Grid item xs={12}>
                        <Typography variant="body2" fontWeight="bold" display="inline" children="Name: "/>
                        {app.name}
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body2" fontWeight="bold" display="inline" children="Client: "/>
                        {app.clientId}
                    </Grid>
                    <Grid item xs={10}>
                        <Typography variant="body2" fontWeight="bold" display="inline" children="Secret key: "/>
                        {showKey ?
                            app.clientSecret
                            : "**************"
                        }
                    </Grid>
                    <Grid container xs={2} justifyContent="flex-end">
                        <Grid item>
                            <IconButton>
                                <ContentCopyIcon onClick={() => {
                                    navigator.clipboard.writeText(app.clientSecret)
                                    enqueueSnackbar("Copied!", {variant: "success"});
                                }}/>
                            </IconButton>
                            <IconButton>
                                {showKey ?
                                    <VisibilityOff onClick={() => setShowKey(false)}/> :
                                    <Visibility onClick={() => setShowKey(true)}/>}
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    </Grid>
}

function AddCard({type}) {

    const { id } = useParams();
    let classes = useStyles();
    let [isCreatingApp, setIsCreatingApp] = useState(false);

    return <Grid item xs={6}>
        <Card className={classes.card}>
            <div onClick={() => setIsCreatingApp(true)}>
                <CardActionArea sx={{height: "11rem"}}>
                    <CardContent>
                        <Grid>
                            <Typography gutterBottom variant="h5" component="h2" align="center">
                                + ADD {type.toUpperCase()} APP
                            </Typography>
                        </Grid>
                    </CardContent>
                </CardActionArea>
            </div>
        </Card>
        <AddCardModal open={!!isCreatingApp}
                      onClose={() => setIsCreatingApp(false)}
                      userId={id} type={type}/>
    </Grid>
}

function AddCardModal ({open, onClose, type, userId}){

    let [name, setName] = useState("");
    let [nameError, setNameError] = useState("");
    const {enqueueSnackbar} = useSnackbar();
    let queryClient = useQueryClient();

    const close = () => {
        setName("")
        setNameError("")
        onClose()
    }

    const save = () => {
        if(name === "") {
            setNameError("Mandatory field")
            return;
        }
        enqueueSnackbar("Saving...", {variant: "info"});
        post(APPS, {body: {name, type, developer: userId}})
            .then(() => enqueueSnackbar("Saved", {variant: "success"}))
            .catch(e => enqueueSnackbar(getErrorMessage(e), {variant: "error"}))
            .finally(() => {
                queryClient.invalidateQueries([APPS, {"user": userId}])
                close()
            })
    }

    return <BaseModal open={open} onClose={close} onSave={save}>
        <Grid container spacing={4} style={{padding: "0.5rem 1rem", marginBottom: "0.5rem", minWidth: "25rem"}}>
            <Grid item xs={12}>
                <TextInput required label="Name" value={name}
                           onTextChange={(value) => {
                               setName(value);
                               setNameError("");
                           }} error={nameError}/>
            </Grid>
        </Grid>
    </BaseModal>
}