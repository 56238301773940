import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import {makeStyles} from "@mui/styles";
import clsx from "clsx";
import Icon from "@mui/material/Icon";

const useStyles = makeStyles(theme => ({
	box: {
		padding: theme.spacing(1),
		margin: theme.spacing(1),
		backgroundColor: theme.palette.primary.main,
		color: "white",
		fontWeight: "bold",
		flexGrow: 1,
		display: "flex",
		justifyContent: "space-between"
	},
}));

const read = require("../../images/icons/pop-up-autorizzazione-visualizzare-over.svg").default;
const write = require("../../images/icons/pop-up-autorizzazione-modificare-over.svg").default;
const none = require("../../images/icons/pop-up-autorizzazione-divieto-over.svg").default;

export function AuthorizationItem({value, authorization, className}) {

	let innerClasses = useStyles();
	let icon;

	if (!authorization) icon = none;
	else if (authorization.readonly) icon = read;
	else icon = write;

	return <Grid item xs={6} alignItems={"flex-end"} style={{display: "flex"}}>
		<Box xs={6} className={clsx(innerClasses.box, className)}>
			<span>{value}</span>
			<Icon><img src={icon}/></Icon>
		</Box>
	</Grid>;
}
