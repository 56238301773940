import React, {useState} from 'react';
import Grid from "@mui/material/Grid";
import HoverableButton from "./layout/HoverableButton";
import makeStyles from '@mui/styles/makeStyles';
import LabelModal from "./modals/LabelModal";

export default function EditableLabel({label, onChange}) {
	let classes = useStyles();
	let [modal, setModal] = useState(false);

	return <Grid direction={"row"} container alignItems={"center"}>
		<HoverableButton
			item={{
				icon: require("../images/icons/pop-up-edit.svg").default,
				iconHover: require("../images/icons/pop-up-edit-over.svg").default,
				name: label || ""
			}}
			onClick={() => setModal(true)}
			classes={{root: classes.addButton, text: classes.addText}}
		/>
		<LabelModal open={modal} onClose={() => setModal(false)} defaultLabel={label} onSubmit={onChange}/>
	</Grid>;
}

const useStyles = makeStyles(theme => ({
	addButton: {
		padding: theme.spacing(1),
		marginRight: theme.spacing(2),
		color: theme.palette.primary.main,
		'&:hover': {
			color: theme.palette.secondary.main
		}
	},
	addText: {
		// display: "none",
		color: theme.palette.primary.main,
	},
	label: {
		flexGrow: 1,
		justifyItems: "center"
	}
}));
