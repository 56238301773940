import {useGetBeacons,useGetMaps} from "../../services/ContentManager";
import {useSnackbar} from "notistack";
import React from "react";
import Battery from "../../components/Popup/Battery";
import Link from "@mui/material/Link";
import {useHistory} from "react-router-dom";
import {Paper,useTheme} from "@mui/material";
import NTMXGrid from "../../components/NTMXGrid";
import {resources} from "../../services/ability";


export default function Beacons({selectedBeacon}){

    let {beacons, error} = useGetBeacons();
    let {maps = []} = useGetMaps();
    const {enqueueSnackbar} = useSnackbar();
    let history = useHistory();
    const theme = useTheme();

    if (error) enqueueSnackbar(error, {variant: "error"});

    function handleClick(beacon) {
        history.push(`maps/${beacon.map}/${resources.BEACONS}/${beacon.id}`);
    }

    if(selectedBeacon) beacons.splice(0, 0, beacons.splice(beacons.findIndex(b => b.id === selectedBeacon), 1)[0]);

    let lookup = {};
    maps.map(m => {
        lookup [m.id] = m.name;
    })

    return (
        <Paper>
            <NTMXGrid
                columns={[
                    {headerName: "UUID", field: 'uuid', flex: 4},
                    {headerName: "Major", field: 'major', flex: 2},
                    {headerName: "Minor", field: 'minor', flex: 2},
                    {
                        headerName: "Position", field: 'map', flex: 2,
                        renderCell: params =>
                            <Link onClick={() => handleClick(params.row)} underline="hover">
                                {lookup[params.value]}
                            </Link>,
                    },
                    {
                        headerName: "Battery Level", field: 'battery', flex: 2,
                        renderCell: params =>
                            <div> {params.value || "-"}%
                                <Battery batteryLevel={params.value}/>
                            </div>
                    }
                ]}
                rows={beacons || []}
                options={{
                    rowStyle: rowData => ({
                        backgroundColor: (rowData.id === selectedBeacon) && theme.palette.primary.light
                    })
                }}
            />
        </Paper>
    );
}