import React, {useEffect, useState} from 'react';
import {get} from "../../services/Client"
import {TRACKING_TYPES, useGetAssets, useGetPositions, useGetVisits} from "../../services/ContentManager";
import {makeStyles} from "@mui/styles";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import {useHistory} from "react-router-dom";
import NTMXGrid, {sortComparator, timestampFormatter} from "../../components/NTMXGrid";
import dayjs from "dayjs";

const useStyles = makeStyles(theme => ({
	page: {
		padding: theme.spacing(2)
	},
	box: {
		padding: theme.spacing(1),
		margin: theme.spacing(1),
		border: `${theme.palette.primary.main} 1px solid`,
		borderRadius: "1rem",
		color: theme.palette.primary.dark,
		textAlign:"center",
		flexGrow: 1,
		'&:hover': {
			color:theme.palette.primary.contrastText,
			backgroundColor: theme.palette.primary.main
		}
	},
	textField: {
		padding: theme.spacing(1),
		margin: theme.spacing(1),
		border: `${theme.palette.secondary.main} 1px solid`,
		color: theme.palette.primary.dark,
		flexGrow: 1
	}
}));


export default function Overview() {
	let history = useHistory()
	const classes = useStyles()
	let {assets} = useGetPositions(703378, TRACKING_TYPES.ASSETS, 60*1000, 60*24*60);
	let {visits} = useGetVisits(703378,TRACKING_TYPES.ASSETS, 60*1000, 60*24*60);
	let {assets:total} = useGetAssets(1562)

	let [rooms, setRooms] = useState([])
	let [room, setRoom] = useState()
	let occupancy = {}
	let rows = []

	if(assets != null) {
		assets.forEach(a => {
			occupancy[a.room] = (occupancy[a.room] || 0) + 1;
		})
		let notAssigned = total.filter(t => !assets.find(a => a.uuid===t.uuid)) || []
		occupancy[-3]=notAssigned.length
		if(room?.id > 0) {
			rows = assets.filter(a => a.room === room.id)
		} else if(room?.id === -1 ) {
			rows = assets.filter(a => a.room === -1 || a.room === -2)
		} else if(room?.id === -3 ) {
			rows = notAssigned
		}
	}
	useEffect(() => {
		get(`maps/${703378}/rooms`).then(r => {
			r.sort((a,b) => a.name.localeCompare(b.name))
			setRooms(r)
		})
	}, [])

	return (
		<>
			<Typography variant="h4" gutterBottom>{room ? room.name : "Carrelli per area"}</Typography>

			{
				room ?
					<NTMXGrid
						disableToolbar
						columns={[
							{
								headerName: "ID", field: 'uuid', renderCell: (params) => <div onClick={() => room.id === -3 ? null : history.push("gpsmaps", params.row)}><a>{params.value}</a></div>
							},
							{
								headerName: "Batteria", field: 'battery', renderCell: ({value}) => `${value||"-"}%`
							},
							{
								headerName: "Ultima rilevazione GPS", field: 'timestamp', minWidth:250, ...timestampFormatter
							},
							{
								headerName: "Ultimo movimento", minWidth:200,
								valueGetter: ({row}) => visits.find(v => v.assetBeacon===row.id) && dayjs.tz(new Date(visits.find(v => v.assetBeacon===row.id).instantStart), 'Europe/Rome').format("DD/MM/YYYY HH:mm:ss"),
								sortComparator: (v1, v2) => sortComparator(v1, v2)
							}
						]}
						rows={rows}
					/> :
					<>
						<Grid container spacing={1}>
							{rooms.map((r, i) => <Grid item xs={6}>
								<Box className={classes.box} onClick={() => setRoom(r)}>
									<Typography variant="h6" gutterBottom>{r.name}</Typography>
									<Typography variant="h4" gutterBottom>{occupancy[r.id] || 0}</Typography>
									<Typography variant="h6" gutterBottom>{"CARRELLI"}</Typography>
								</Box>
							</Grid>)}
							<Grid item xs={6}>
								<Box className={classes.box} onClick={() => setRoom({id: -1 , name: "Fuori area"})}>
									<Typography variant="h6" gutterBottom>{"Fuori area"}</Typography>
									<Typography variant="h4"
									            gutterBottom>{(occupancy[-1] || 0) + (occupancy[-2] || 0)}</Typography>
									<Typography variant="h6" gutterBottom>{"CARRELLI"}</Typography>
								</Box>
							</Grid>
							<Grid item xs={6}>
								<Box className={classes.box} onClick={() => setRoom({id: -3 , name: "Non rilevati"})}>
									<Typography variant="h6" gutterBottom>{"Non rilevati"}</Typography>
									<Typography variant="h4"
									            gutterBottom>{occupancy[-3] || 0}</Typography>
									<Typography variant="h6" gutterBottom>{"CARRELLI"}</Typography>
								</Box>
							</Grid>
						</Grid>
						<Grid container justifyContent={"center"}>
							<Grid item style={{margin:"2 rem"}}><a href={"https://drive.google.com/file/d/17ecXxForTRiRvpfUyODvlHq3AXCk3Kin/view?usp=sharing"} target={"_blank"}>Visualizza Aree</a></Grid>
						</Grid>
						<Grid container justifyContent={"center"} alignItems={"center"}>
							<Grid item xs={4} ><img src={require("../../images/logo-blu.png")} style={{width:"100%", maxWidth:'200px', display: 'block', marginLeft: 'auto', marginRight: 'auto'}}/></Grid>
							<Grid item xs={4} ><img src={require("../../images/partitalia.png")}  style={{width:"100%", maxWidth:'200px', display: 'block', marginLeft: 'auto', marginRight: 'auto'}} /></Grid>
							<Grid item xs={4} ><img src={require("../../images/W3_RGB.jpg")}  style={{width:"75%", maxWidth:'150px', display: 'block', marginLeft: 'auto', marginRight: 'auto'}} /></Grid>
						</Grid>
					</>
			}

		</>
	);
}
