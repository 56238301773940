import {
	SAVE,
	SET_VENUES
} from "./actions";

import {API_ERROR, API_START, API_END} from "../middleware/actions";
import {LOGOUT} from "../users/reducers";

const defaultState = {
	list: [],
	error: null,
	isGetting:false,
	isSaving:false
};

export default function (state = defaultState, action) {
	switch (action.type) {
		case SET_VENUES: {
			return {
				...state,
				list:action.data,
				error:null
			}
		}
		case SAVE: {
			let venues = state.list.slice();
			venues.unshift(action.data);
			return {
				...state,
				list: venues,
				error:null
			}
		}
		case API_START: {
			return {
				...state,
				isGetting: action.data === SET_VENUES,
				isSaving: action.data === SAVE,
			}
		}
		case API_END: {
			return {
				...state,
				isGetting: action.data === SET_VENUES ? false : state.isGetting,
				isSaving: action.data === SAVE ? false : state.isSaving,
			}
		}
		case API_ERROR: {
			return {
				...state,
				error: action.data
			};
		}
		case LOGOUT: {
			return defaultState;
		}
		default: return state;
	}
}
