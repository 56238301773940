import React,{useContext,useState} from "react";
import MapsOverview from "./Maps/MapsOverview";
import {resources} from "../services/ability";
import {AbilityContext} from "../services/Can";
import FilterAndSearchBar from "../components/layout/FilterAndSearchBar";
import {useSelector} from "react-redux";
import EditRooms from "./Maps/EditRooms";


export default function Maps(){

    let [selected, setSelected] = useState(0);
    const ability = useContext(AbilityContext);
    let venue = useSelector(store => store.session.venue);

    const getMapsFilters = () => {
        let filters = [];
        let id = 0;
        if(ability.can('read', venue.id + "", resources.MAPS)) {
            filters.push({
                id,
                name : "Overview",
                component : <MapsOverview/>
            });
            id++;
        }
        if(ability.can('write', venue.id + "", resources.MAPS)) {
            filters.push({
                id,
                name : "Edit Rooms",
                component : <EditRooms/>
            });
            id++;
        }
        return filters;
    }
    let filters = getMapsFilters();

    return (
        <div>
            <FilterAndSearchBar
                filters={filters}
                onFilter={setSelected}
                selected={selected}
            />
            {filters.find(f => f.id === selected)?.component}
        </div>
    );
}