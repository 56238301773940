import React from "react";
import HoverableIconButton from "../hoverables/HoverableIconButton";
import {Grid} from "@mui/material";
import {Link,useHistory} from "react-router-dom";

import beaconIcon from "../../images/icons/on-map-beacon.svg";
import move from "../../images/icons/pop-up-sposta.svg";
import moveHover from "../../images/icons/pop-up-sposta-over.svg";
import edit from "../../images/icons/pop-up-edit.svg";
import editHover from "../../images/icons/pop-up-edit-over.svg";
import deleteIcon from "../../images/icons/pop-up-delete.svg";
import deleteIconHover from "../../images/icons/pop-up-delete-over.svg";
import list from "../../images/icons/pop-up-vedi-in-tabella.svg";
import listHover from "../../images/icons/pop-up-vedi-in-tabella-over.svg";
import ConfirmIconButton from "../buttons/ConfirmIconButton";
import TextBoxForPopup from "./TextBoxForPopup";
import BatteryForPopup from "./BatteryForPopup";
import BasePopup from "./BasePopup";
import Can from "../../services/Can";
import {useSelector} from "react-redux";

export default function BeaconPopup ({beacon, popupPosition, onMove, onEdit, onDelete}) {

    let venue = useSelector(store => store.session.venue);
    let history = useHistory();
    function handleClick() {
        history.push("content/" + beacon.id);
    }

    return(
        <BasePopup popupPosition={popupPosition} header={
            <>
                <Grid item>
                    <HoverableIconButton src={beaconIcon} hoverSrc={beaconIcon}/>
                </Grid>
                <Grid item>
                    <Can I="write" a={venue.id + ""} field="beacons">
                        <HoverableIconButton src={move} hoverSrc={moveHover} onClick={onMove}/>
                        <HoverableIconButton src={edit} hoverSrc={editHover} onClick={onEdit}/>
                        <ConfirmIconButton item={{icon: deleteIcon, iconHover: deleteIconHover}}
                        text="Are you sure you want to permanently remove the selected beacon?"
                        title="Confirm?" onConfirm={onDelete}/>
                    </Can>
                    <Link onClick={() => handleClick()}><HoverableIconButton src={list} hoverSrc={listHover}/></Link>
                </Grid>
            </>
        }>
            <TextBoxForPopup element={beacon.uuid} label="Beacon UUID"/>
            <TextBoxForPopup element={beacon.major} label="Major"/>
            <TextBoxForPopup element={beacon.minor} label="Minor"/>
            <BatteryForPopup batteryLevel={beacon.battery}/>
        </BasePopup>
    );
}