import React, {useEffect, useState} from "react";
import BaseModal from "./BaseModal";
import TextInput from "../forms/TextInput";
import Grid from "@mui/material/Grid";

export default function GatewayModal({open, onClose, onSubmit, defaultValue}) {

	let [gateway, setGateway] = useState(defaultValue || {});
	let [errors, setErrors] = useState({});
	useEffect(() => setGateway(defaultValue || {}), [defaultValue]);

	function onChange(name, value) {
		let newGateway = Object.assign({}, gateway, {[name]: value});
		setGateway(newGateway);
		setErrors({});
	}

	function submit() {
		setErrors({});

		if(!gateway.serial){
			setErrors({serial : true});
			return;
		}

		onSubmit(gateway);
		onClose();
	}

	return (

		<BaseModal open={open} onClose={onClose} onSave={submit}>
			<Grid container spacing={4} style={{padding: "0.5rem 1rem", marginBottom: "1.5rem"}}>
				<Grid item xs={12}>

					<TextInput required label={"Serial"}
							   value={gateway.serial}
							   onTextChange={value => onChange("serial", value)}
							   error={errors.serial} noHelperText={true}/>

				</Grid>
				<Grid item xs={12}>

					<TextInput label={"Label"} value={gateway.label}
							   onTextChange={value => onChange("label", value)}
								noHelperText={true}/>

				</Grid>
				<div style={{height: "1rem"}}/>
			</Grid>
		</BaseModal>
	);
}
