import BaseModal from "./BaseModal";
import TextInput from "../forms/TextInput";
import React,{useContext,useState} from "react";
import Grid from "@mui/material/Grid";
import {useSnackbar} from "notistack";
import {login} from "../../services/UserManager";
import {UserContext} from "../../containers/App";
import {getErrorMessage,PASSWORD,put} from "../../services/Client";

export default function ChangePasswordModal({open, onClose}) {

    let user = useContext(UserContext).data;
    const {enqueueSnackbar} = useSnackbar();

    let [currentPassword, setCurrentPassword] = useState("");
    let [newPassword, setNewPassword] = useState("");
    let [repeatPassword, setRepeatPassword] = useState("");

    let [error, setError] = useState({});
    let [showPasswords, setShowPasswords] = useState(false);


    const save = () => {
        if (currentPassword === "") {
            setError({old: "Mandatory field"});
            return;
        }
        if (newPassword === "") {
            setError({new: "Mandatory field"});
            return;
        }
        if (repeatPassword === "") {
            setError({repeat: "Mandatory field"});
            return;
        }
        if (newPassword !== repeatPassword) {
            setError({repeat: "Passwords do not match", new: "Passwords do not match"});
            return;
        }
        login(user.email, currentPassword).then(() => {
            enqueueSnackbar("Saving...", {variant: "info"});
            put(PASSWORD, {body: {newPassword, oldPassword: currentPassword}})
                .then(() => {
                    enqueueSnackbar("Saved",{variant : "success"})
                    close()
                })
                .catch(e => enqueueSnackbar(getErrorMessage(e), {variant: "error"}))
        }).catch(() => {
            setError({old: "Wrong password"});
        })
        //todo controlla che il login sia fatto su server e eliminalo da qui
        //vedi pero' la risposta del server per mostrare l'errore password errata
    }

    const close = () => {
        setCurrentPassword("");
        setNewPassword("");
        setRepeatPassword("");
        setError({});
        onClose();
    }

    return (
        <BaseModal open={open} onClose={close} onSave={save}>
            <Grid container spacing={4} style={{padding: "0.5rem 1rem", marginBottom: "1.5rem"}}>

                <Grid item xs={12}>
                    <TextInput required label="Current password" value={currentPassword}
                               type="password" showPasswordIcon
                               onTextChange={(value) => {
                                   setCurrentPassword(value.trim());
                                   setError({});
                               }} error={error.old}/>
                </Grid>
                <Grid item xs={12}>
                    <TextInput required label="New password" value={newPassword}
                               type="password" showPasswordIcon
                               onTextChange={(value) => {
                                   setNewPassword(value.trim());
                                   setError({});
                               }} error={error.new}/>
                </Grid>
                <Grid item xs={12}>
                    <TextInput required label="Repeat password" value={repeatPassword}
                               type="password" showPasswordIcon
                               onTextChange={(value) => {
                                   setRepeatPassword(value.trim());
                                   setError({});
                               }} error={error.repeat}/>
                </Grid>

            </Grid>
        </BaseModal>
    )

}