import {apiAction} from "../middleware/actions";

export const SET_VENUES = "SET_VENUES";
export const SAVE = "SAVE_VENUES";

const VENUES_URL = "venues";

export function getVenues() {
	return apiAction({
		url:VENUES_URL,
		onSuccess: venues => {
			venues.sort((a,b) => b.id-a.id);
			return setVenues(venues);
		},
		label:SET_VENUES
	})
}

export function saveVenue(venue) {
	return apiAction({
		url:VENUES_URL,
		method:"POST",
		data:venue,
		onSuccess: venue => {
			return save(venue);
		},
		label:SAVE
	})
}

function setVenues(venues) {
	return {
		type: SET_VENUES,
		data: venues
	}
}

function save(venue) {
	return {
		type:SAVE,
		data:venue
	}
}
