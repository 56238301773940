import React from "react";
import DialogTitle from "@mui/material/DialogTitle";
import HoverableIconButton from "../hoverables/HoverableIconButton";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import makeStyles from '@mui/styles/makeStyles';
import {Grid,IconButton} from "@mui/material";

const useStyles = makeStyles((theme) => ({
	paper: {
		backgroundColor: theme.palette.primary.dark,
		borderRadius: 0
	},
	root: {
		"&::-webkit-scrollbar": {
			width: 5,
		},
		"&::-webkit-scrollbar-thumb": {
			backgroundColor: theme.palette.secondary.main,
			borderRadius: "10px"
		},
	},
}));

export default function BaseModal({open, onClose, onSave, iconButton, onClickIconButton, submitButton, children, error}) {
	const classes = useStyles();
	return (
		<Dialog open={open} onClose={onClose} classes={{paper: classes.paper}}>
			<DialogTitle style={{textAlign: "right"}}>
				{
					iconButton &&
					<IconButton onClick={onClickIconButton} color="secondary">
						{iconButton}
					</IconButton>
				}
				<HoverableIconButton
					src={require("../../images/icons/pop-up-chiudi-over.svg").default}
					hoverSrc={require("../../images/icons/pop-up-chiudi.svg").default}
					onClick={onClose}
				/>
				{
					submitButton || <HoverableIconButton
						src={require("../../images/icons/pop-up-salva-over.svg").default}
						hoverSrc={require("../../images/icons/pop-up-salva.svg").default}
						onClick={onSave}
					/>
				}
			</DialogTitle>
			<DialogContent className={classes.root}>
				{error && <div>{error}</div>}
				{children}
			</DialogContent>
		</Dialog>
	);
}
