import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getUsers} from "../redux/users/actions";

import {roles, types} from "../services/PermissionManager";
import {makeStyles} from "@mui/styles";
import clsx from "clsx";
import NTMXGrid from "../components/NTMXGrid";
import {IconButton,Paper,Grid} from "@mui/material";
import {Link} from "react-router-dom";
import {Delete, Visibility} from "@mui/icons-material";
import ConfirmIconButton from "../components/buttons/ConfirmIconButton";
import deleteIcon from "../images/icons/pop-up-delete.svg";
import deleteIconHover from "../images/icons/pop-up-delete-over.svg";
import {deleteElem,getErrorMessage,USERS} from "../services/Client";
import {useSnackbar} from "notistack";

const useStyles = makeStyles({
	root: {
		display: "flex",
		flexWrap: "wrap",
		justifyContent: "center",
		color: "#0a698a",
		fontWeight: "700",
		padding:"1rem",
		"& div": {
			paddingLeft:"1rem",
			paddingRight:"1rem",
			"&:hover":{
				color:"#5bc5f2",
				cursor:"pointer"
			}
		},
	},
	selected: {
		color:"#5bc5f2"
	},
});

export default function Administration() {

	const classes = useStyles();
	let [selected, setSelected] = useState(null);
	let users = useSelector(store => store.users.list);
	let venues = useSelector(store => store.venues.list);
	let dispatch = useDispatch();
	const {enqueueSnackbar} = useSnackbar();

	useEffect(() => {
		dispatch(getUsers())
	}, []);

	let data;
	if(selected) {
		let selRole = types.find(r => r.code === selected);
		data = users.filter(u => u.role === selRole.code);
	} else {
		data = users;
	}
	data.forEach(u => u.venues = [...new Set((u.authorizations||[]).map(a => a.venue))].map(vid => venues.find(v => v.id===vid)));

	const deleteProfile = (id) => {
		enqueueSnackbar("Saving...", {variant: "info"});
		deleteElem(`${USERS}/${id}`)
			.then(() => {
				enqueueSnackbar("Saved",{variant : "success"})
				dispatch(getUsers())
			})
			.catch(e => enqueueSnackbar(getErrorMessage(e), {variant: "error"}));
	}

	const columns = [
		{
			headerName: "First Name",
			field: 'firstName',
			flex: 1
		},
		{
			headerName: "Last Name",
			field: 'lastName',
			flex: 1
		},
		{
			headerName: "Email",
			field: 'email',
			flex: 2
		},
		{
			headerName: "Phone",
			field: 'phoneNumber',
			flex: 1
		},
		{
			headerName: "Role",
			field: 'role',
			flex: 1,
			renderCell: params => <div>{types.find(r => params.value === r.code).name}</div>
		},
		{
			field: 'Actions',
			type: 'actions',
			width: 130,
			renderCell: (params) => <Grid container direction={"row"} justifyContent="center">
				<Grid item>
					<Link to={"/profile/" + params.row.id}>
						<IconButton>
							<Visibility/>{/*todo fix icona*/}
						</IconButton>
					</Link>
				</Grid>
				<Grid item>
					<ConfirmIconButton item={{icon : deleteIcon, iconHover : deleteIconHover}}
									   text="Do you really want to delete the profile?"
									   title="Confirm" onConfirm={() => deleteProfile(params.row.id)}/>
				</Grid>
			</Grid>
		}
	]

	return <Paper>
		<NTMXGrid
			columns={columns}
			rows={data}
			centerButton={<div className={classes.root}>|
				<div onClick={() => setSelected(null)} className={clsx({[classes.selected]:selected===null})}>ALL</div>|
				<div onClick={() => setSelected(roles.USER)} className={clsx({[classes.selected]:selected===roles.USER})}>USER</div>|
				<div onClick={() => setSelected(roles.ADMIN)} className={clsx({[classes.selected]:selected===roles.ADMIN})}>ADMIN</div>|
			</div>}
			initialState={{pinnedColumns: {right: ['details']}}}
		/>
	</Paper>
}
