import React,{useEffect,useState} from "react";
import MapView from "../../components/mapview/MapView";
import {useGetBeacons,useGetEvents,useGetGateways,useGetMaps,useGetPaths,useGetPOI} from "../../services/ContentManager";
import FilterAndSearchBar from "../../components/layout/FilterAndSearchBar";
import legendBeacon from "../../images/icons/legend-beacon.svg";
import legendBeaconHover from "../../images/icons/legend-beacon-over.svg";
import legendBeaconOff from "../../images/icons/legend-beacon-off.svg";
import legendGateways from "../../images/icons/legend-cloud-beacon.svg";
import legendGatewaysHover from "../../images/icons/legend-cloud-beacon-over.svg";
import legendGatewaysOff from "../../images/icons/legend-cloud-beacon-off.svg";
import legendPoi from "../../images/icons/legend-poi.svg";
import legendPoiHover from "../../images/icons/legend-poi-over.svg";
import legendPoiOff from "../../images/icons/legend-poi-off.svg"
import legendPaths from "../../images/icons/legend-paths.svg";
import legendPathsHover from "../../images/icons/legend-paths-over.svg";
import legendPathsOff from "../../images/icons/legend-paths-off.svg";
import legendEvent from "../../images/icons/legend-event.svg";
import legendEventHover from "../../images/icons/legend-event-over.svg";
import legendEventOff from "../../images/icons/legend-event-off.svg";
import makeStyles from '@mui/styles/makeStyles';
import Paper from "@mui/material/Paper";
import BeaconModal from "../../components/modals/BeaconModal";
import BeaconPopup from "../../components/Popup/BeaconPopup";
import {BEACONS,getErrorMessage,put,deleteElem,GATEWAYS,post,POI,NODES,LINKS,EVENTS} from "../../services/Client";
import {useQueryClient} from "react-query";
import {useSnackbar} from "notistack";
import GatewayModal from "../../components/modals/GatewayModal";
import GatewayPopup from "../../components/Popup/GatewayPopup";
import LayerController from "../../components/mapview/LayerController";
import PoiModal from "../../components/modals/PoiModal";
import PoiPopup from "../../components/Popup/PoiPopup";
import PathPopup from "../../components/Popup/PathPopup";
import PathsModal from "../../components/modals/PathsModal";
import {useParams} from "react-router-dom";
import EventPopup from "../../components/Popup/EventPopup";
import EventModal from "../../components/modals/EventModal";
import {resources} from "../../services/ability";

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: "0.3rem"
    },
    page: {
        padding: theme.spacing(2)
    }
}));

export default function MapsOverview() {
    let {map, resourceType, id} = useParams();
    let {maps = []} = useGetMaps();
    let [selectedMap, setSelectedMap] = useState();

    let {beacons = []} = useGetBeacons();
    let [beaconsLayer, setBeaconsLayer] = useState(true);
    let [addBeacon, setAddBeacon] = useState(false);
    let [beaconModal, setBeaconModal] = useState(false);
    let [newBeacon, setNewBeacon] = useState({});
    let [dragBeacon, setDragBeacon] = useState();

    let {gateways = []} = useGetGateways();
    let [gatewaysLayer, setGatewaysLayer] = useState(true);
    let [addGateway, setAddGateway] = useState(false);
    let [gatewayModal, setGatewayModal] = useState(false);
    let [newGateway, setNewGateway] = useState({});
    let [dragGateway, setDragGateway] = useState();

    let {poi = []} = useGetPOI();
    let [poiLayer, setPoiLayer] = useState(true);
    let [addPoi, setAddPoi] = useState(false);
    let [poiModal, setPoiModal] = useState(false);
    let [newPoi, setNewPoi] = useState({});
    let [dragPoi, setDragPoi] = useState();

    let {paths = []} = useGetPaths();
    let [pathsLayer, setPathsLayer] = useState(true);
    let [addNode, setAddNode] = useState(false);
    let [dragNode, setDragNode] = useState();
    let [originNode, setOriginNode] = useState();
    let [linkFloorOriginNode, setLinkFloorOriginNode] = useState();
    let [pathModal, setPathModal] = useState(false);

    let {events = []} = useGetEvents();
    let [eventsLayer, setEventsLayer] = useState(true);
    let [addEvent, setAddEvent] = useState(false);
    let [dragEvent, setDragEvent] = useState();
    let [resizeEvent, setResizeEvent] = useState();
    let [eventModal, setEventModal] = useState(false);
    let [newEvent, setNewEvent] = useState({});

    let [popup, setPopup] = useState({});
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();
    let queryClient = useQueryClient();

    if (maps) maps = maps.filter(m => m.floor !== -1);
    if (maps.length > 0 && !selectedMap) setSelectedMap(parseInt(map) || maps[0].id);

    useEffect(() => {
        id && resourceType === resources.BEACONS && beacons[0] && onBeaconClick({id: parseInt(id)},"left");
        id && resourceType === resources.GATEWAYS && gateways[0] && onGatewayClick({id: parseInt(id)},"left");
    }, []);

    function onBeaconClick(e, popupPosition){
        let beacon = beacons.find((beacon) => beacon.id === e.id);
        if(popup.id && popup.id  === e.id) {
            setPopup({});
            return;
        }
        let newPopup = <BeaconPopup
            beacon={beacon}
            popupPosition = {popupPosition}
            onEdit = {()=> {
                setPopup({});
                setNewBeacon(beacon);
                setBeaconModal(true);
            }}
            onMove = {() => {
                setPopup({});
                setDragBeacon(beacon.id);
            }}
            onDelete = {() => {
                deleteElem(BEACONS, {elem: beacon.id})
                    .then(() => {
                        enqueueSnackbar("Deleted!",{variant : "success"});
                        setPopup({});
                    })
                    .catch(e => enqueueSnackbar(getErrorMessage(e), {variant: "error"}))
                    .finally(() => queryClient.invalidateQueries("beacons"));
            }}
        />
        setPopup({container: newPopup, id: e.id});
    }

    function onGatewayClick(e, popupPosition){
        let gateway = gateways.find((g) => g.id === e.id);
        if(popup.id && popup.id  === e.id) {
            setPopup({});
            return;
        }
        let newPopup = <GatewayPopup
            gateway={gateway}
            popupPosition = {popupPosition}
            onEdit = {()=> {
                setPopup({});
                setNewGateway(gateway);
                setGatewayModal(true);
            }}
            onMove = {() => {
                setPopup({});
                setDragGateway(gateway.id);
            }}
            onDelete = {() => {
                deleteElem(GATEWAYS, {elem: gateway.id})
                    .then(() => {
                        enqueueSnackbar("Deleted!",{variant : "success"});
                        setPopup({});
                    })
                    .catch(e => enqueueSnackbar(getErrorMessage(e), {variant: "error"}))
                    .finally(() => queryClient.invalidateQueries("gateways"));
            }}
        />
        setPopup({container: newPopup, id: e.id});
    }

    function onPoiClick(e, popupPosition){
        let poiElement = poi.find(p => p.id === e.id);
        if(popup.id && popup.id  === e.id) {
            setPopup({});
            return;
        }
        let newPopup = <PoiPopup
            poi={poiElement}
            popupPosition={popupPosition}
            onMove={() => {
                setPopup({});
                setDragPoi(poiElement.id);
            }}
            onEdit={() => {
                setPopup({});
                setNewPoi(poiElement);
                setPoiModal(true);
            }}
            onDelete = {() => {
                deleteElem(POI, {elem: poiElement.id})
                    .then(() => {
                        enqueueSnackbar("Deleted!",{variant : "success"});
                        setPopup({});
                    })
                    .catch(e => enqueueSnackbar(getErrorMessage(e), {variant: "error"}))
                    .finally(() => queryClient.invalidateQueries("poi"));
            }}/>
        setPopup({container: newPopup, id: e.id});
    }

    function onEventClick(e, popupPosition){
        let event = events.find((ev) => ev.id === e.id);
        if(popup.id && popup.id  === e.id) {
            setPopup({});
            return;
        }
        let newPopup = <EventPopup
            event={event}
            popupPosition = {popupPosition}
            onResize={() => {
                setPopup({});
                setResizeEvent(event.id);
            }}
            onMove={() => {
                setPopup({});
                setDragEvent(event.id);
            }}
            onEdit={() => {
                setPopup({});
                setNewEvent(event);
                setEventModal(true);
            }}
            onDelete = {() => {
                deleteElem(EVENTS, {elem: e.id})
                    .then(() => {
                        enqueueSnackbar("Deleted!",{variant : "success"});
                        setPopup({});
                    })
                    .catch(e => enqueueSnackbar(getErrorMessage(e), {variant: "error"}))
                    .finally(() => queryClient.invalidateQueries("events"));
            }}
        />
        setPopup({container: newPopup, id: e.id});
    }

    function onNodeClick(e, popupPosition){
        if(originNode){
            let newLink = {
                origin: originNode,
                destination: e.id,
                bidirectional: true
            }
            setOriginNode(null);
            enqueueSnackbar("Saving...", {variant: "info"});
            post(LINKS, {body: newLink})
                .then(() => enqueueSnackbar("Saved", {variant: "success"}))
                .catch(e => enqueueSnackbar(getErrorMessage(e), {variant: "error"}))
                .finally(() => queryClient.invalidateQueries("paths"));
            return;
        }
        if(popup.id && popup.id  === e.id) {
            setPopup({});
            return;
        }
        let newPopup = <PathPopup
            title="Node"
            elem={e}
            popupPosition={popupPosition}
            mapsNumber={maps.length}
            onMove={()=> {
                setPopup({});
                setDragNode(e.id)
            }}
            onLink={() => {
                setPopup({});
                setOriginNode(e.id);
            }}
            onLinkFloor = {() => {
                setPopup({});
                setPathModal(true);
                setLinkFloorOriginNode(e);
            }}
            onDelete = {() => {
                deleteElem(NODES, {elem: e.id})
                    .then(() => {
                        enqueueSnackbar("Deleted!",{variant : "success"});
                        setPopup({});
                    })
                    .catch(e => enqueueSnackbar(getErrorMessage(e), {variant: "error"}))
                    .finally(() => queryClient.invalidateQueries("paths"));
            }}/>
        setPopup({container: newPopup, id: e.id});
    }

    function onLinkClick(e, popupPosition){
        if(popup.id && popup.id  === e.id) {
            setPopup({});
            return;
        }
        let newPopup = <PathPopup
            title="Link"
            elem={e}
            popupPosition={popupPosition}
            onDelete = {() => {
                deleteElem(LINKS, {elem: e.id})
                    .then(() => {
                        enqueueSnackbar("Deleted!",{variant : "success"});
                        setPopup({});
                    })
                    .catch(e => enqueueSnackbar(getErrorMessage(e), {variant: "error"}))
                    .finally(() => queryClient.invalidateQueries("paths"));
            }}/>
        setPopup({container: newPopup, id: e.id});
    }

    function onDragPoi(position, poi){
        poi.x = position.x;
        poi.y = position.y;
        put(POI, {body: poi, elem: poi.id})
            .then(() => enqueueSnackbar("Saved",{variant : "success"}))
            .catch(e => enqueueSnackbar(getErrorMessage(e), {variant: "error"}))
            .finally(() => queryClient.invalidateQueries("poi"));
    }

    function onDragBeacon (position, beacon){
        beacon.x = position.x;
        beacon.y = position.y;
        put(BEACONS, {body: beacon, elem: beacon.id})
            .then(() => enqueueSnackbar("Saved",{variant : "success"}))
            .catch(e => enqueueSnackbar(getErrorMessage(e), {variant: "error"}))
            .finally(() => queryClient.invalidateQueries("beacons"));
    }

    function onDragEvent (position, event){
        setDragEvent(null)
        event.x = position.x;
        event.y = position.y;
        saveEvent(event);
    }

    function onResizeEvent(newEvent){
        events[events.findIndex(e => e.id === newEvent.id)] = newEvent;
        saveEvent(newEvent);
        setResizeEvent(null);
    }

    function onDragNode(position, node){
        setDragNode(null);
        node.x = position.x;
        node.y = position.y;
        put(NODES, {body: node, elem: node.id})
            .then(() => enqueueSnackbar("Saved",{variant : "success"}))
            .catch(e => enqueueSnackbar(getErrorMessage(e), {variant: "error"}))
            .finally(() => queryClient.invalidateQueries("paths"));
    }

    function onDragGateway (position, gateway){
        gateway.x = position.x;
        gateway.y = position.y;
        putGateway(gateway);
    }

    function putGateway (gateway){
        enqueueSnackbar("Saving...", {variant: "info"});
        put(GATEWAYS, {body: gateway, elem: gateway.id})
            .then(() => enqueueSnackbar("Saved", {variant: "success"}))
            .catch(e => enqueueSnackbar(getErrorMessage(e), {variant: "error"}))
            .finally(() => queryClient.invalidateQueries("gateways"));
    }

    function saveGateway (gateway){
        if(gateway.id){
            putGateway(gateway);
        }else{
            enqueueSnackbar("Saving...", {variant: "info"});
            post(GATEWAYS, {body: gateway})
                .then(() => enqueueSnackbar("Saved", {variant: "success"}))
                .catch(e => enqueueSnackbar(getErrorMessage(e), {variant: "error"}))
                .finally(() => queryClient.invalidateQueries("gateways"));
        }
    }

    function saveEvent (event){
        if(event.id){
            put(EVENTS, {body: event, elem: event.id})
                .then(() => enqueueSnackbar("Saved", {variant: "success"}))
                .catch(e => enqueueSnackbar(getErrorMessage(e), {variant: "error"}))
                .finally(() => queryClient.invalidateQueries("events"));
        }else{
            post(EVENTS, {body: event})
                .then(() => enqueueSnackbar("Saved", {variant: "success"}))
                .catch(e => enqueueSnackbar(getErrorMessage(e), {variant: "error"}))
                .finally(() => queryClient.invalidateQueries("events"));
        }
    }

    function postNode(newNode) {
        enqueueSnackbar("Saving...", {variant: "info"});
        post(NODES, {body: newNode})
            .then(() => enqueueSnackbar("Saved", {variant: "success"}))
            .catch(e => enqueueSnackbar(getErrorMessage(e), {variant: "error"}))
            .finally(() => queryClient.invalidateQueries("paths"));
        setAddNode(false);
    }

    let customCursor;
    if(addBeacon) customCursor = require("../../images/icons/on-map-beacon-add.svg").default;
    if(addGateway) customCursor = require("../../images/icons/on-map-cloud-beacon-add.svg").default;
    if(addPoi) customCursor = require("../../images/icons/on-map-poi-add.svg").default;
    if(addNode) customCursor = require("../../images/icons/on-map-paths.svg").default;
    if(addEvent) customCursor = require("../../images/icons/on-map-event.svg").default;

    return (
        <div className={classes.root}>
            <div style={{marginBottom: "0.3rem"}}>
                {
                    maps.length > 1 &&
                    <FilterAndSearchBar
                        selected={selectedMap}
                        filters={maps}
                        onFilter={(id) => {
                            setSelectedMap(id);
                            setPopup({});
                        }}
                    />
                }
            </div>
            <Paper className={classes.page}>
                <MapView map={selectedMap}
                         mapWidth={selectedMap && maps.find((m) => m.id === selectedMap).width}
                         mapHeight={selectedMap && maps.find((m) => m.id === selectedMap).height}
                         beacons={beaconsLayer && beacons.filter(b => b.map === selectedMap)}
                         gateways={gatewaysLayer && gateways.filter(g => g.map === selectedMap)}
                         poi={poiLayer && poi.filter(p => p.map === selectedMap)}
                         paths={pathsLayer && paths}
                         events={eventsLayer && events.filter(e => e.map === selectedMap)}
                         customCursor ={customCursor}
                         onBackgroundClick = {(newElement) => {
                             setPopup({});
                             setOriginNode(null);
                             if(addBeacon){
                                 setNewBeacon(newElement);
                                 setBeaconModal(true);
                             }
                             if(addGateway){
                                 setNewGateway(newElement);
                                 setGatewayModal(true);
                             }
                             if(addPoi){
                                 setNewPoi(newElement);
                                 setPoiModal(true);
                             }
                             if(addEvent){
                                 setNewEvent(newElement);
                                 setEventModal(true);
                             }
                             if(addNode) postNode(newElement);
                         }}
                         dragBeacon = {dragBeacon}
                         onDragBeacon = {onDragBeacon}
                         onBeaconClick = {onBeaconClick}
                         onGatewayClick={onGatewayClick}
                         dragGateway={dragGateway}
                         onDragGateway={onDragGateway}
                         onPoiClick = {onPoiClick}
                         dragPoi={dragPoi}
                         onDragPoi={onDragPoi}
                         onEventClick={onEventClick}
                         dragEvent={dragEvent}
                         onDragEvent={onDragEvent}
                         resizeEvent={resizeEvent}
                         onResizeEvent={onResizeEvent}
                         dragNode={dragNode}
                         onDragNode={onDragNode}
                         onNodeClick={onNodeClick}
                         onLinkClick={onLinkClick}
                         originNode={originNode}
                         popup={popup.container}>
                    <LayerController
                        title="BEACONS"
                        src={legendBeacon}
                        hover={legendBeaconHover}
                        off={legendBeaconOff}
                        color={"#FF0D00"}
                        onClick={() => setBeaconsLayer(!beaconsLayer)}
                        addElement={() => setAddBeacon(!addBeacon)}
                        enabled={beaconsLayer}/>
                    <LayerController
                        title="GATEWAYS"
                        src={legendGateways}
                        hover={legendGatewaysHover}
                        off={legendGatewaysOff}
                        color={"#FF7700"}
                        onClick={() => setGatewaysLayer(!gatewaysLayer)}
                        addElement={() => setAddGateway(!addGateway)}
                        enabled={gatewaysLayer}/>
                    <LayerController
                        title="POI"
                        src={legendPoi}
                        hover={legendPoiHover}
                        off={legendPoiOff}
                        color={"#FFE401"}
                        onClick={() => setPoiLayer(!poiLayer)}
                        addElement={() => setAddPoi(!addPoi)}
                        enabled={poiLayer}/>
                    <LayerController
                        title="EVENTS"
                        src={legendEvent}
                        hover={legendEventHover}
                        off={legendEventOff}
                        color={"#ACFF01"}
                        onClick={() => setEventsLayer(!eventsLayer)}
                        addElement={() => setAddEvent(!addEvent)}
                        enabled={eventsLayer}/>
                    <LayerController
                        title="PATHS"
                        src={legendPaths}
                        hover={legendPathsHover}
                        off={legendPathsOff}
                        color={"#01FFDE"}
                        onClick={() => setPathsLayer(!pathsLayer)}
                        addElement={() => setAddNode(!addNode)}
                        enabled={pathsLayer}/>
                </MapView>
            </Paper>
            <BeaconModal beacon={newBeacon} open={beaconModal} onClose={() => {
                setBeaconModal(false);
                setAddBeacon(false);
            }}/>
            <GatewayModal defaultValue={newGateway} open={!!gatewayModal} onClose={() => {
                setGatewayModal(false);
                setAddGateway(false);
            }}  onSubmit={saveGateway}/>
            <PoiModal defaultValue={newPoi} open={!!poiModal} onClose={() =>{
                setPoiModal(false);
                setAddPoi(false);
            }}/>
            <PathsModal originNode={linkFloorOriginNode} open={!!pathModal} maps={maps} paths={paths} onClose={() => {
                setPathModal(false);
            }}/>
            <EventModal event={newEvent} open={!!eventModal} onClose={() =>{
                setEventModal(false);
                setAddEvent(false);
            }} onSubmit={saveEvent}/>
        </div>
    );
}