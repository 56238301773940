import {
	SET_MAPS,
	SET_VENUE,
	SET_IMAGES
} from "./actions";
import {API_END, API_ERROR, API_START} from "../middleware/actions";
import {SAVE, SET_VENUES} from "../venues/actions";
import {LOGOUT} from "../users/reducers";

const defaultState = {
	venue: null,
	maps: null,
	beacons: null,
	images: null
};

export default function (state = defaultState, action) {
	switch (action.type) {
		case SET_VENUE: {
			if (action.data)
				return {
					...state,
					venue: action.data
				};
			else return defaultState;
		}
		case SET_MAPS: {
			return {
				...state,
				maps: action.data
			};
		}
		case SET_IMAGES: {
			let images = state.images ? {...state.images} : {};
			images[action.data.map] = action.data.src;
			return {
				...state,
				images
			};
		}
		case API_START: {
			return {
				...state,
				isGettingImages: action.data === SET_IMAGES,
				isGettingGateways: action.data === SAVE,
			};
		}
		case API_END: {
			return {
				...state,
				isGetting: action.data === SET_VENUES ? false : state.isGetting,
				isSaving: action.data === SAVE ? false : state.isSaving,
			};
		}
		case API_ERROR: {
			return {
				...state,
				error: action.data
			};
		}
		case LOGOUT: {
			return defaultState;
		}
		default:
			return state;
	}
}
