import React, {useContext} from 'react';

import makeStyles from '@mui/styles/makeStyles';
import CssBaseline from '@mui/material/CssBaseline';
import Header from "../components/layout/Header";
import Sidebar from "../components/layout/Sidebar";
import {Redirect, Route, Switch} from "react-router-dom";
import Page404 from "../components/pages/Page404";
import VenueList from "./VenueList";
import {useDispatch,useSelector} from "react-redux";

import {setMenu} from "../services/MenuManager";
import Administration from "./Administration";
import Content from "./Content";
import Dashboard from "./Dashboard";
import {UserManagement} from "./UserManagement";
import ProfilePage from "./Profile/ProfilePage";
import Overview from "./Capodichino/Overview";
import {AbilityContext} from "../services/Can";
import {resources} from "../services/ability";
import {isAdmin, isSuperUser} from "../services/PermissionManager";
import PowerBI from "./PowerBI";
import {UserContext} from "./App";
import Map from "./Capodichino/Map";
import {getUsers} from "../redux/users/actions";
import {getVenues} from "../redux/venues/actions";
import Maps from "./Maps";
import Settings from "./Settings/Settings";

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		minHeight: '100vh'
	},
	toolbar: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		padding: theme.spacing(0, 1),
		// necessary for content to be below app bar
		...theme.mixins.toolbar,
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
	},
}));

export default function Main() {
	const [open, setOpen] = React.useState(false);
	const ability = useContext(AbilityContext);
	const classes = useStyles();

	let dispatch = useDispatch();
	dispatch(getUsers())
	dispatch(getVenues())

	const user = useContext(UserContext).data;
	let venue = useSelector(store => store.session.venue);
	let menu = setMenu(user, venue, ability);

	return (
		<div className={classes.root}>
			<CssBaseline/>
			<Header menuOpen={open} toggleMenu={setOpen}/>
			<Sidebar open={open} setOpen={setOpen} items={menu}/>

			<main className={classes.content}>
				<div className={classes.toolbar}/>
				<Switch>
					<Route path={"/capodichino"}>
						<Overview/>
					</Route>
					<Route path="/dashboard">
						{venue ?
							<Dashboard/> :
							<VenueList/>
						}
					</Route>
					<Route path={"/content/:beacon"}>
						{venue && ability.can('read', venue.id + "", resources.BEACONS) ?
							<Content type={"content"}/> :
							<Redirect exact from="/" to="/dashboard"/>
						}
					</Route>
					<Route path={"/content"}>
						{venue && (ability.can('read', venue.id + "", resources.MAPS) || ability.can('read', venue.id + "", resources.GATEWAYS) || ability.can('read', venue.id + "", resources.BEACONS)) ?
							<Content type={"content"}/> :
							<Redirect exact from="/" to="/dashboard"/>
						}
					</Route>
					<Route exact path={"/monitoring"}>
						{venue && ability.can('read', venue.id + "", resources.GATEWAYS) ?
							<Content type={"monitoring"}/> :
							<Redirect exact from="/" to="/dashboard"/>
						}
					</Route>
					<Route path={"/administration"}>
						{
							isSuperUser(user) ?
								<Administration/> :
								<Redirect exact from="/" to="/dashboard"/>
						}
					</Route>
					<Route path={["/maps/:map/:resourceType/:id", "/maps"]}>
						{venue && ability.can('read', venue.id + "", resources.MAPS) ?
							<Maps/> :
							<Redirect exact from="/" to="/dashboard"/>
						}
					</Route>
					<Route path={"/gpsmaps"}>
						<Map/>
					</Route>
					<Route path={"/users"}>
						{venue && isAdmin(user) ?
							<UserManagement/> :
							<Redirect exact from="/" to="/dashboard"/>}
					</Route>
					<Route path={"/profile/:id"}>
						<ProfilePage/>
					</Route>
					<Redirect from="/profile" to={"/profile/" + user.id}/>
					<Route path={"/stats/industrial"}>
						<PowerBI reportId={"f424203c-fc92-45e6-b7b1-0e70f72b32d7"}/>
					</Route>
					<Route path={"/stats/offices"}>
						<PowerBI reportId={"06ebc06f-96b4-419f-aa8a-b82cdf03aba8"}/>
					</Route>
					<Route path={"/stats/healthcare"}>
						<PowerBI reportId={"410ea9ed-4f44-41f4-bb22-e0c52ba58715"}/>
					</Route>
					<Route path={"/stats/museums"}>
						<PowerBI reportId={"4c33121c-5745-475c-b7f8-c6643f3e0ede"}/>
					</Route>
					{
						process.env.REACT_APP_ENVIRONMENT === "capodichino" ?
							<Redirect exact from="/" to="/capodichino"/> :
							<Redirect exact from="/" to="/dashboard"/>
					}
					<Route path={"/settings"}>
						<Settings/>
					</Route>
					<Route path={"/"}>
						<Page404/>
					</Route>
				</Switch>
			</main>
		</div>
	);
}
