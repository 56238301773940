import {useSelector} from "react-redux";
import BasePopup from "./BasePopup";
import {Grid} from "@mui/material";
import HoverableIconButton from "../hoverables/HoverableIconButton";
import eventIcon from "../../images/icons/on-map-event.svg";
import Can from "../../services/Can";
import move from "../../images/icons/pop-up-sposta.svg";
import moveHover from "../../images/icons/pop-up-sposta-over.svg";
import edit from "../../images/icons/pop-up-edit.svg";
import editHover from "../../images/icons/pop-up-edit-over.svg";
import ConfirmIconButton from "../buttons/ConfirmIconButton";
import deleteIcon from "../../images/icons/pop-up-delete.svg";
import deleteIconHover from "../../images/icons/pop-up-delete-over.svg";
import TextBoxForPopup from "./TextBoxForPopup";
import React from "react";


export default function EventPopup ({event, popupPosition, onResize, onMove, onEdit, onDelete}) {
    let venue = useSelector(store => store.session.venue);

    return(
        <BasePopup popupPosition={popupPosition} header={
            <>
                <Grid item>
                    <HoverableIconButton src={eventIcon} hoverSrc={eventIcon}/>
                </Grid>
                <Grid item>
                    <Can I="write" a={venue.id + ""} field="events">
                        {onResize && <HoverableIconButton src={move} hoverSrc={moveHover} onClick={onResize}/>}
                        {onMove && <HoverableIconButton src={move} hoverSrc={moveHover} onClick={onMove}/>}
                        {onEdit && <HoverableIconButton src={edit} hoverSrc={editHover} onClick={onEdit}/>}
                        {onDelete && <ConfirmIconButton item={{icon : deleteIcon,iconHover : deleteIconHover}}
                                                        text="Are you sure you want to permanently remove the selected event?"
                                                        title="Confirm?" onConfirm={onDelete}/>}
                    </Can>
                </Grid>
            </>
        }>
            <TextBoxForPopup element={event.data} label="Message"/>
        </BasePopup>
    );
}