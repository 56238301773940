import BaseModal from "./BaseModal";
import Grid from "@mui/material/Grid";
import TextInput from "../forms/TextInput";
import React,{useEffect,useState} from "react";


export default function EventModal({open, onClose, onSubmit, event}) {

    let [message, setMessage] = useState("");
    let [errors, setErrors] = useState({});

    useEffect(() => {
        setMessage(event.data);
    }, [event]);

    function close() {
        setMessage(undefined);
        setErrors({});
        onClose();
    }

    function submit() {
        setErrors({});

        let newEvent = {
            ...event,
            radius: event.radius || 50,
            data: message
        }

        onSubmit(newEvent);
        onClose();
    }

    return (
        <BaseModal open={open} onClose={close} onSave={submit}>
            <Grid container spacing={4} style={{padding: "0.5rem 1rem", marginBottom: "1.5rem"}}>
                <Grid item xs={12}>

                    <TextInput label="Message" value={message || ''} type="text"
                               onTextChange={(value) => {
                                   setMessage(value);
                                   setErrors({});
                               }} error={errors["Message"]} noHelperText={true}/>

                </Grid>
            </Grid>
        </BaseModal>
    );
}