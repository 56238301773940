import { Ability, AbilityBuilder } from "@casl/ability"

const roles = {
    USER: "DEVELOPER",
    ADMIN: "ADMIN",
    SUPER: "SUPERUSER"
};

const abilities = {
    WRITE: "write",
    READ: "read"
}

const resources = {
    BEACONS: "beacons",
    EVENTS: "events",
    OUTDOOR: "outdoor",
    POI: "poi",
    PATH: "paths",
    GATEWAYS: "gateways",
    ASSET: "assets",
    DEVELOPER: "developers",
    MAPS: "maps",
    REALTIME: "realtime",
    ANALYTICS: "stats",
    TOURS: "tours",
    AOA: "angle_of_arrival",
};

function subjectName(item) {
    if (!item || typeof item === "string") {
        return item
    }
    return item.__type
}

function defineRulesFor(user) {

    const { can, cannot, rules } = new AbilityBuilder()
    if (user.role === roles.SUPER) {
        can(abilities.WRITE, "all")
        can(abilities.READ, "all");
        //cannot(abilities.READ, "Profile");
    }
    if (user.role === roles.ADMIN || user.role === roles.USER) {
        user.authorizations.map(a => {
            can(a.readonly ? abilities.READ : [abilities.READ, abilities.WRITE], a.venue + "", a.resource);
        });
    }
    return rules
}
function ability(user) {
    return new Ability(defineRulesFor(user || {}), { subjectName });
}

export default ability;

export {
    resources
}
