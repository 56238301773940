import React,{useContext,useEffect,useState} from "react";
import BaseModal from "./BaseModal";
import TextInput from "../forms/TextInput";
import Grid from "@mui/material/Grid";
import {getRoleName,isSuperUser,roles} from "../../services/PermissionManager";
import {UserPermissionTable} from "../../containers/UserManagement/UserPermissionTable";
import {useDispatch,useSelector} from "react-redux";
import MenuItem from "@mui/material/MenuItem";
import HoverableIconButton from "../hoverables/HoverableIconButton";
import {isValidEmail} from "../../services/helper";
import {deleteElem,get,getErrorMessage,USERS} from "../../services/Client";
import {saveUser} from "../../redux/users/actions";
import {useQueryClient} from "react-query";
import {UserContext} from "../../containers/App";

export default function CreateUserModal({open, onClose, defaultValue}) {

	let dispatch = useDispatch();
	let queryClient = useQueryClient();
	let venue = useSelector(store => store.session.venue);

	let [email, setEmail] = useState("");
	let [user, setUser] = useState({});
	let [errors, setErrors] = useState({});
	let [step, setStep] = useState(1);
	let [isExistingUser, setIsExistingUser] = useState(false);
	let [isEditingUser, setIsEditingUser] = useState(false);

	useEffect(() => {
		if(defaultValue) {
			setIsEditingUser(true)
			setUser(defaultValue)
			setStep(2)
		}
	}, [open]);

	function close() {
		setIsExistingUser(false)
		setIsEditingUser(false)
		setEmail("")
		setUser({})
		setErrors({})
		setStep(1)
		onClose()
	}

	function submit() {
		dispatch(saveUser(user));
		queryClient.invalidateQueries(["users", {venue: venue.id}]);
		onClose();
		close()
	}

	function revokeAll() {
		deleteElem(`${USERS}/${user.id}/authorizations/${venue.id}`)
			.then(() => {
				queryClient.invalidateQueries(["users", {venue: venue.id}]);
				close();
			})
			.catch(e => console.log(getErrorMessage(e)));
	}

	const goToStep2 = () => {
		if (!email || email === "") {
			setErrors({email: "Missing email"});
			return;
		}
		if(!isValidEmail(email)) {
			setErrors({email: "Invalid email format"});
			return;
		}
		setUser({email})
		setIsExistingUser(false)
		get(`${USERS}/${email}`).then(u => {
			if(Object.keys(u).length !== 0) {
				setUser(u)
				setIsExistingUser(true)
			}
		}).finally(() => setStep(2))
	}

	return (
		<BaseModal open={open} onClose={close} onSave={submit}
				   iconButton={step === 2 && !isEditingUser && <HoverableIconButton
					   src={require("../../images/icons/chevron-left.svg").default}
					   hoverSrc={require("../../images/icons/chevron-left-over.svg").default}
					   onClick={() => setStep(1)}
				   />}
					submitButton={step === 1 && <HoverableIconButton
						src={require("../../images/icons/chevron-right.svg").default}
						hoverSrc={require("../../images/icons/chevron-right-over.svg").default}
						onClick={goToStep2}
					/>}
		>
			{step === 1 &&
				<Grid container style={{padding: "0.5rem 1rem", marginBottom: "0.5rem", minWidth: "25rem"}}>
					<TextInput label={"Email"} value={email || ''}
							   error={errors["email"]} required
							   onTextChange={value => {
								   setEmail(value.trim())
								   setErrors({})
							   }}/>
				</Grid>
			}
			{step === 2 &&
				<CreateUserSecondStep user={user} setUser={setUser}
									  isExistingUser={isExistingUser}
									  isEditingUser={isEditingUser}
									  revokeAll={revokeAll}
				/>
			}
		</BaseModal>
	);
}

function CreateUserSecondStep({isExistingUser, isEditingUser, user, setUser, revokeAll}) {

	let venue = useSelector(store => store.session.venue);
	let loggedUser = useContext(UserContext).data;

	let getTextInput = (label, field, disabled) => <Grid item xs={6} alignItems={"flex-end"} style={{display: "flex"}}>
		<TextInput label={label} value={user[field] || ''} disabled={disabled}
				   onTextChange={value => onChange(field, value)}/>
	</Grid>;

	function onChange(name, value) {
		let newUser = {...user}
		newUser[name] = value;
		setUser(newUser)
	}

	return <Grid container alignItems={"flex-end"} spacing={4} style={{padding: "0.5rem 1rem", marginBottom: "1.5rem"}}>
		{getTextInput("Email", "email", !(isSuperUser(loggedUser) && isEditingUser))}

		<Grid item xs={6} alignItems={"flex-end"} style={{display: "flex"}}>
			<TextInput label={"Role"} value={user.role || ''} type={"select"} select disabled={isExistingUser}
					   onTextChange={value => onChange("role", value)}>
				<MenuItem key={roles.USER} value={roles.USER}>{getRoleName(roles.USER)}</MenuItem>
				<MenuItem key={roles.ADMIN} value={roles.ADMIN}>{getRoleName(roles.ADMIN)}</MenuItem>
			</TextInput>
		</Grid>

		{getTextInput("First Name", "firstName", isExistingUser)}
		{getTextInput("Last Name", "lastName", isExistingUser)}

		<Grid item xs={12}>
			<UserPermissionTable
				user={user}
				venue={venue}
				onChange={value => onChange("authorizations", value)}
				onRevokeAll={isEditingUser ? revokeAll : null}
			/>
		</Grid>
	</Grid>
}
