import React, {useEffect, useState} from "react";
import BaseModal from "./BaseModal";
import TextInput from "../forms/TextInput";
import Grid from "@mui/material/Grid";

export default function LabelModal({open, onClose, onSubmit, defaultLabel}) {

	let [label, setLabel] = useState(defaultLabel);
	let [error, setError] = useState(null);

	useEffect(() => {
		// if (!open) setLabel(undefined);
	}, [open]);

	function onChange(newLabel) {
		setLabel(newLabel);
		setError(null);
	}

	function submit() {
		if (!label || label === "") {
			setError("Missing label");
			return;
		}
		onSubmit(label);
		onClose();
	}

	return (
		<BaseModal open={open} onClose={onClose} onSave={submit}>
			<Grid container spacing={4} style={{padding: "0.5rem 1rem", width: "25rem",  marginBottom: "1.5rem"}}>
				<Grid item xs={12}>
					<TextInput required label={"Label"} value={label} onTextChange={onChange}
							   error={error}/>
				</Grid>
			</Grid>
		</BaseModal>
	);
}
