import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import makeStyles from '@mui/styles/makeStyles';
import Icon from "@mui/material/Icon";
import {useSelector} from "react-redux";
import Checkbox from "@mui/material/Checkbox";
import {getUserResources} from "../../services/PermissionManager";
import ConfirmButton from "../../components/buttons/ConfirmButton";
import {useContext} from "react";
import {UserContext} from "../App";

const useStyles = makeStyles((theme) => ({
	container: {
		padding: "2rem",
		border: `1px solid ${theme.palette.secondary.main}`
	},
	row: {
		marginBottom: theme.spacing(2)
	},
	title: {
		fontWeight: "bold"
	}
}));
const read = require("../../images/icons/pop-up-autorizzazione-visualizzare-over.svg").default;
const write = require("../../images/icons/pop-up-autorizzazione-modificare-over.svg").default;
const none = require("../../images/icons/pop-up-autorizzazione-divieto-over.svg").default;

export function UserPermissionTable({user, venue, onChange, onRevokeAll}) {
	const classes = useStyles();
	let admin = useContext(UserContext).data;
	let resources = getUserResources(admin, venue);
	let authorizations = user.authorizations ? user.authorizations.slice() : [];

	const setAuthorization = (resource, readonly) => {
		authorizations = authorizations.filter(a => a.resource !== resource);
		if (readonly !== null)
			authorizations.push({
				venue: venue.id,
				dev: user.id,
				readonly,
				resource
			});
		onChange(authorizations);
	};

	return (
        <Grid item container xs={12} className={classes.container}>
			<Grid item xs={4} className={classes.row}>
				<Typography variant={"h6"} color={"secondary"} className={classes.title}>RESOURCE</Typography>
			</Grid>
			<Grid item xs={8}>
				<Typography variant={"h6"} color={"secondary"} align={"center"}
				            className={classes.title}>PERMISSION</Typography>
			</Grid>
			<Grid item xs={4} className={classes.row}/>
			<Grid container item xs={8}>
				<Grid item container xs={4} alignContent={"center"} justifyContent={"space-evenly"}>
					<Icon><img src={write} alt={"write"}/></Icon>
					<Typography color={"secondary"}>EDIT</Typography>
				</Grid>
				<Grid item container xs={4} alignContent={"center"} justifyContent={"space-evenly"}>
					<Icon><img src={read} alt={"read"}/></Icon>
					<Typography color={"secondary"}>VIEW</Typography>
				</Grid>
				<Grid item container xs={4} alignContent={"center"} justifyContent={"space-evenly"}>
					<Icon><img src={none} alt={"none"}/></Icon>
					<Typography color={"secondary"}>NONE</Typography>
				</Grid>
			</Grid>
			{resources.map(r => {
				let auth = authorizations && authorizations.find(a => a.venue === venue.id && a.resource === r);
				let permission;
				if (!auth) permission = "none";
				else if (auth.readonly) permission = "read";
				else permission = "write";

				return (
                    <React.Fragment key={r}>
                        <Grid item xs={4} className={classes.row}>
                            <Typography color={"secondary"} className={classes.title}>{r}</Typography>
                        </Grid>
                        <Grid container item xs={8}>
                            <Grid item container xs={4} alignContent={"center"} justifyContent={"space-evenly"}>
                                <Checkbox
                                    checked={permission === "write"}
                                    color="secondary"
                                    inputProps={{'aria-label': 'secondary checkbox'}}
                                    onChange={({target}) => target.checked ? setAuthorization(r, false) : setAuthorization(r, true)}
                                />
                            </Grid>
                            <Grid item container xs={4} alignContent={"center"} justifyContent={"space-evenly"}>
                                <Checkbox
                                    checked={permission === "write" || permission === "read"}
                                    color="secondary"
                                    inputProps={{'aria-label': 'secondary checkbox'}}
                                    onChange={({target}) => target.checked ? setAuthorization(r, true) : setAuthorization(r, null)}
                                />
                            </Grid>
                            <Grid item container xs={4} alignContent={"center"} justifyContent={"space-evenly"}>
                                <Checkbox
                                    checked={permission === "none"}
                                    color="secondary"
                                    inputProps={{'aria-label': 'secondary checkbox'}}
                                    onChange={({target}) => setAuthorization(r, null)}
                                />
                            </Grid>
                        </Grid>
                    </React.Fragment>
                );
			})}
			{
				onRevokeAll &&
				<>
					<Grid item xs={9}/>
					<Grid item xs={3}>
						<ConfirmButton
							title={"Confirm?"}
							text={"Revoke all permissions of the user on this Venue?"}
							onConfirm={onRevokeAll}
							buttonProps={{
								variant: "outlined",
								color: "secondary"
							}}
						>Revoke all</ConfirmButton>
					</Grid>
				</>
			}
		</Grid>
    );
}
