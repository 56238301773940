import React, {useState} from 'react';
import {TRACKING_TYPES,useGetPositions} from "../../services/ContentManager";
import GoogleMapContainer from "../../components/mapview/GoogleMapContainer";
import Grid from "@mui/material/Grid";
import {InfoWindow, Marker} from "@react-google-maps/api";
import Typography from "@mui/material/Typography";
import {useLocation} from "react-router-dom";
import dayjs from "dayjs";

export default function Map() {

	let location = useLocation()
	let asset = location.state
	let [infoWindow, setInfoWindow] = useState(null)
	let [center, setCenter] = useState({lat: asset?.x || 40.884895, lng: asset?.y || 14.289254})
	let {assets} = useGetPositions(703378, TRACKING_TYPES.ASSETS, 60*1000, 60*24*2)
	let markers = []

	if(asset) {
		markers.push({
			lat: asset.x,
			lng: asset.y,
			label:asset.label,
			ts: dayjs.tz(new Date(asset.time), "Europe/Rome").format("DD/MM/YYYY HH:mm:ss")
		})
	} else if(assets != null) assets.forEach(a => {
		markers.push({
			lat: a.x,
			lng: a.y,
			label:a.label,
			ts: dayjs.tz(new Date(a.time), "Europe/Rome").format("DD/MM/YYYY HH:mm:ss")
		})
	})

	return <Grid container>

		<GoogleMapContainer zoom={15} center={center} mapContainerStyle={{height: "80vh",width: "90vw"}} mapTypeId={"hybrid"}>
			{markers.map((p, index) => <Marker key={index} position={{lat : p.lat,lng : p.lng}} onClick={() => {
				setInfoWindow(index)
				setCenter({lat: p.lat,lng: p.lng})
			}}>
					{(infoWindow || infoWindow===0) && infoWindow === index &&
						<InfoWindow onCloseClick={() => setInfoWindow(null)}>
							<Grid container direction={"column"}>
								<Grid item><Typography>id: {p.label}</Typography></Grid>
								<Grid item><Typography>Ultima rilevazione GPS: {p.ts}</Typography></Grid>
							</Grid>
						</InfoWindow>
					}
				</Marker>)}
		</GoogleMapContainer>
	</Grid>
}
