import React, {useEffect, useState} from "react";
import BaseModal from "./BaseModal";
import TextInput from "../forms/TextInput";
import Grid from "@mui/material/Grid";
import {useSelector} from "react-redux";
import {useSnackbar} from "notistack";
import {BEACONS, getErrorMessage, post, put} from "../../services/Client";
import {useQueryClient} from "react-query";

export default function BeaconModal({open, onClose, beacon}) {

	let [UUID, setUUID] = useState();
	let [major, setMajor] = useState();
	let [minor, setMinor] = useState();
	let [errors, setErrors] = useState({});
	let venue = useSelector(store => store.session.venue);
	const {enqueueSnackbar} = useSnackbar();
	let queryClient = useQueryClient();

	useEffect(() => {
		setUUID(beacon.uuid);
		setMajor(beacon.major);
		setMinor(beacon.minor);
	}, [beacon]);

	function submit() {
		setErrors({});

		if (!UUID) {
			setErrors({"UUID": true});
			return;
		}

		if (!major || major < 0 || major > 65535) {
			setErrors({"major": true});
			return;
		}
		if (!minor || minor < 0 || minor > 65535) {
			setErrors({"minor": true});
			return;
		}

		let newBeacon = {
			...beacon,
			uuid: UUID,
			major,
			minor,
			venueId: venue.id
		};

		enqueueSnackbar("Saving...", {variant: "info"});
		if (beacon.id) {
			put(BEACONS, {body: newBeacon, elem: beacon.id})
				.then(() => {
					enqueueSnackbar("Saved", {variant: "success"});
					close();
				})
				.catch(e => enqueueSnackbar(getErrorMessage(e), {variant: "error"}))
				.finally(() => queryClient.invalidateQueries("beacons"));
		} else {
			post(BEACONS, {body: newBeacon})
				.then(() => {
					enqueueSnackbar("Saved", {variant: "success"});
					close();
				})
				.catch(e => enqueueSnackbar(getErrorMessage(e), {variant: "error"}))
				.finally(() => queryClient.invalidateQueries("beacons"));
		}


	}

	function close() {
		if (!beacon.uuid) {
			setUUID(undefined);
			setMajor(undefined);
			setMinor(undefined);
		}
		setErrors({});
		onClose();
	}

	return (
		<BaseModal open={open} onClose={close} onSave={submit}>
			<Grid container spacing={4} style={{padding: "0.5rem 1rem", marginBottom: "1.5rem"}}>
				<Grid item xs={12}>

					<TextInput label="UUID Beacon*" value={UUID || ''} type="text"
					           onTextChange={(value) => {
						           setUUID(value.trim());
						           setErrors({});
					           }} error={errors["UUID"]} noHelperText={true}/>

				</Grid>
				<Grid item xs={12}>

					<TextInput label="Major Beacon*" value={major || ''} type="number"
					           onTextChange={(value) => {
						           setMajor(value.trim());
						           setErrors({});
					           }} noHelperText={true}
					           step={1} min={0} max={65535} error={errors["major"]}/>

				</Grid>
				<Grid item xs={12}>

					<TextInput label="Minor Beacon*" value={minor || ''} type="number"
					           onTextChange={(value) => {
						           setMinor(value.trim());
						           setErrors({});
					           }} noHelperText={true}
					           step={1} min={0} max={65535} error={errors["minor"]}/>

				</Grid>

			</Grid>
		</BaseModal>
	);
}
