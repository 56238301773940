import axios from "axios";
import {API, apiError, apiStart, apiEnd } from "./actions";
import {baseUrl} from "../../constants/network";
import {TokenManager} from "../../services/TokenManager";
let tokenManager = new TokenManager();

const apiMiddleware = ({ dispatch }) => next => action => {
	next(action);

	if (action.type !== API) return;

	const {
		url,
		method,
		data,
		onSuccess,
		onFailure,
		label,
		headers
	} = action.payload;

	const dataOrParams = ["GET", "DELETE"].includes(method) ? "params" : "data";

	// axios default configs
	headers["Content-Type"]="application/json";

	let token = tokenManager.get();
	if(token) headers['Authorization'] = `Bearer ${token}`;

	if (label) {
		dispatch(apiStart(label));
	}

	axios
		.request({
			url: `${baseUrl}${url}`,
			method,
			headers,
			[dataOrParams]: data
		})
		.then(({ data , headers}) => {
			if(headers.authorization) {
				let token = headers.authorization.replace("Bearer ", "");
				tokenManager.save(token);
			}
			dispatch(onSuccess(data));
		})
		.catch(error => {

			if(onFailure){
				dispatch(onFailure(error));
			} else{
				let message;
				if (error.response) {
					// The request was made and the server responded with a status code
					// that falls out of the range of 2xx
					message = error.response.data.error;
				} else {
					// Something happened in setting up the request that triggered an Error
					message = error.message || error.error;
				}
				dispatch(apiError(message));
			}
		})
		.finally(() => {
			if (label) {
				dispatch(apiEnd(label));
			}
		});
};

export default apiMiddleware;
