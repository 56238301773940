import React,{useEffect,useState} from "react";
import Paper from "@mui/material/Paper";
import {makeStyles} from "@mui/styles";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import saveImage from "../../images/icons/pop-up-salva.svg";
import discard from "../../images/icons/pop-up-chiudi.svg";
import edit from "../../images/icons/pop-up-edit.svg";
import userImage from "../../images/icons/top_bar-profilo-over.svg";
import LockIcon from '@mui/icons-material/Lock';
import {useSnackbar} from "notistack";
import {getErrorMessage,put} from "../../services/Client";
import {useContext} from "react";
import {UserContext} from "../App";
import {TextItem} from "../../components/TextItem";
import TextInput from "../../components/forms/TextInput";
import {useParams} from "react-router-dom";
import {Button} from "@mui/material";
import ChangePasswordModal from "../../components/modals/ChangePasswordModal";
import {useDispatch,useSelector} from "react-redux";
import {getUsers} from "../../redux/users/actions";
import {AbilityContext} from "../../services/Can";
import {resources} from "../../services/ability";
import {getUserResources,isSuperUser} from "../../services/PermissionManager";
import {getVenues} from "../../redux/venues/actions";
import {VenuePill} from "../UserManagement/VenuePill";
import Typography from "@mui/material/Typography";
import ProfileApps from "./ProfileApps";


const useStyles = makeStyles(theme => ({
    page: {
        padding: theme.spacing(2)
    },
    container: {
        marginBottom: theme.spacing(2)
    }
}));

export default function ProfilePage (){

    let classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();
    let dispatch = useDispatch();
    const ability = useContext(AbilityContext);

    const { id } = useParams();
    let users = useSelector(store => store.users.list);
    let venues = useSelector(store => store.venues.list);
    let loggedUser = useContext(UserContext);
    const isUserLogged = id == loggedUser.data.id;

    let [isEditing, setIsEditing] = useState(false);
    let [isEditingCredentials, setIsEditingCredentials] = useState(false);
    let [localUser, setLocalUser] = useState({});

    useEffect(() => {
        dispatch(getUsers())
        dispatch(getVenues())
        if(ability.cannot('read', resources.DEVELOPER)) setLocalUser(Object.assign({}, loggedUser.data));
    }, [id]);

    useEffect(() => {
        if(users.length > 0) {
            setLocalUser(Object.assign({}, users.find(u => u.id == id)));
        }
    }, [users])

    const onChange = (newValue, field) => {
        setLocalUser({ ...localUser, [field]: newValue });
    }

    const form = [
        {
            name: "firstName",
            label: "Nome"
        },
        {
            name: "lastName",
            label: "Cognome"
        },
        {
            name: "email",
            label: "E-Mail"
        },
        {
            name: "phoneNumber",
            label: "Numero di telefono"
        },
        {
            name: "address",
            label: "Indirizzo"
        },
        {
            name: "city",
            label: "Città"
        },
        {
            name: "role",
            label: "Ruolo",
            notEditable: true
        },
    ]

    const saveEdit = () => {
        enqueueSnackbar("Saving...", {variant: "info"});
        put("developers/"+localUser.id,{body: localUser}).then(() => {
            if(isUserLogged) loggedUser.onLogin();
            dispatch(getUsers())
            enqueueSnackbar("Saved", {variant: "success"});
            setIsEditing(false);
        }).catch(e => enqueueSnackbar(getErrorMessage(e), {variant: "error"}));
    }

    const discardEdit = () => {
        setLocalUser(Object.assign({}, users.find(u => u.id == id)));
        setIsEditing(false);
    }

    let distinctVenues = [...new Set(localUser.authorizations?.map(a => a.venue))]

    if(isEditing) return <Paper className={classes.page}>

        <Grid container className={classes.container}>
            <Grid item xs={12} style={{ display: "flex", justifyContent: "flex-end" }}>
                <IconButton onClick={saveEdit} size="large">
                    <img src={saveImage} alt="save"/>
                </IconButton>
                <IconButton onClick={discardEdit} size="large">
                    <img src={discard} alt="discard"/>
                </IconButton>
            </Grid>
            <Grid item xs={12} style={{display: "flex", "justify-content": "center"}}>
                <img src={userImage} style={{height: "6rem"}} alt="userImage"/>
            </Grid>
        </Grid>

        <Grid container spacing={3} style={{padding: "0.5rem 1rem"}}>
            {
                form.map(f => !f.notEditable && <Grid item xs={12} sm={6} alignItems={"flex-end"} style={{display: "flex"}}>
                    <TextInput value={localUser[f.name]} onTextChange={(value) => onChange(value, f.name)}
                               label={f.label} type={f.type} color="dark"/>
                </Grid>)
            }
        </Grid>
    </Paper>

    return (
        <Paper className={classes.page}>
            <Grid container justifyContent="space-between" className={classes.container}>
                <Grid item>
                    {isUserLogged && !isEditing &&
                        <Button
                            color="primary"
                            startIcon={<LockIcon />}
                            onClick={() => setIsEditingCredentials(true)}
                        >
                            Change password
                        </Button>
                    }
                </Grid>
                <Grid item alignItems={"flex-end"}>
                    <IconButton onClick={() => setIsEditing(true)} size="large">
                        <img src={edit} alt="edit"/>
                    </IconButton>
                </Grid>
                <Grid item xs={12} style={{display: "flex", "justify-content": "center"}}>
                    <img src={userImage} style={{height: "6rem"}} alt="userImage"/>
                </Grid>
            </Grid>

            <Grid container alignItems={"flex-end"} className={classes.container}>
                {
                    form.map(f => <Grid item xs={12} sm={6} alignItems={"flex-end"} style={{display: "flex"}}>
                            <TextItem label={f.label}>
                                {localUser[f.name] || ""}
                            </TextItem>
                        </Grid>)
                }
            </Grid>
            <ProfileApps/>
            {!isSuperUser(localUser) && venues.length > 0 &&
                <>
                    <Typography variant="h5" sx={{padding: "0.5rem 0rem"}}>
                        {distinctVenues.length === 0 && "No "}
                        {distinctVenues.length === 1 ? "Venue" : "Venues"}
                    </Typography>
                    <Grid container xs={12}>
                        {distinctVenues?.map(a => <Grid item sm={6}>
                            <VenuePill user={localUser} venue={venues.find(v => v.id === a)}
                                       resources={getUserResources(loggedUser.data,{id: a})}/>
                        </Grid>)}
                    </Grid>
                </>
            }

            <ChangePasswordModal open={isEditingCredentials} onClose={() => setIsEditingCredentials(false)} />
        </Paper>
    );
}
