import React from 'react';
import {Box} from "@mui/material";

import {FilePond, registerPlugin} from "react-filepond";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

// And import the necessary css
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import makeStyles from "@mui/styles/makeStyles";

// register the filepond plugins for additional functionality
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileValidateType);

const useStyles = makeStyles(theme => ({
	error: {
		paddingLeft: "16px",
		color: theme.palette.primary.error,
		fontSize: "0.75rem",
		position: "relative",
		top: "-0.85rem"
	}
}));

export default function FileInput({acceptedTypes, label, onChange, files, errorMessage}) {

	const classes = useStyles();

	return (
		<Box>
			<FilePond
				files={files}
				labelIdle={label}
				acceptedFileTypes={acceptedTypes}
				onupdatefiles={fileItems => {
					onChange(fileItems.map(fileItem => fileItem.file));
				}}
			/>
			<Box className={classes.error}>
				{errorMessage}
			</Box>
		</Box>

	);
}
