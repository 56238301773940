import {getErrorMessage,post} from "./Client";
import {TokenManager} from "./TokenManager";
let tokenManager = new TokenManager();

export function login(email, password) {
    return new Promise((resolve, reject) => {
        post(`developers/login`, {body: {email, password}})
            .then(({data}) => {
                const { developer, ...token } = data;
                tokenManager.save(token)
                resolve(data)
            })
            .catch(e => {
                let message = getErrorMessage(e)
                reject(message)
            });
    })
}

export function refreshToken() {
    return new Promise((resolve, reject) => {
        let token = tokenManager.getAllTokens()
        if(token && token.refreshToken) {
            return post(`developers/refresh`, {body: token.refreshToken}).then(({data}) => {
                token.accessToken=data.accessToken
                tokenManager.save(token)
                resolve(data)
            }).catch(e => reject(getErrorMessage(e)))
        } else {
            reject()
        }
    })

}